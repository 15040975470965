<template>
  <modal
    name="dataStreamModal"
    class="datastrem--modal"
    @closed="hideWelcomePage"
    :clickToClose="false"
    :scrollable="true"
    :height="'auto'"
  >
    <div class="">
      <div class="w-100">
        <div class="row pb-5 m-0">
          <div class="col p-0 welcome mt-4 align-start">
            <div class="d-grid">
              <span class="mb-4 font-medium" v-if="channelData.showMessage"
                >Your connection <i>{{ channelData.ConnectionName }}</i> has
                been created successfully</span
              >
              <div class="d-flex" style="justify-content: center;">
                <img
                  src="../../assets/group-21.png"
                  alt
                  class="welcome--image"
                />
              </div>
            </div>
          </div>
          <div class="col p-0 intro__content">
            <p class="Welcome-to-Data-Poem ProximaNovaRegular">
              {{ this.modalTitle }}
            </p>
            <div>
              <w-tab
                :tabs="tabList"
                :tabName="selectedTab"
                class="mb-3 d-flex"
                style="align-items: start;"
                @activeTab="changeTab($event)"
              >
              </w-tab>
            </div>
            <div
              class="Together-let-us-hel ProximaNovaRegular mt-0 "
              style="height: auto; width: 80% !important;"
              v-if="selectedTab === 'Talk to us'"
            >
              <div class="w-100 mb-5">
                <h3 class="ProximaNovaBold">Schedule a call with us</h3>
              </div>
              <div class="w-100 mb-5">
                <w-date-picker
                  v-model="selectedDate"
                  :placeholder="'Pick a time'"
                  format="DD:MM:YYYY  hh:mm A"
                  type="datetime"
                  :show-second="false"
                ></w-date-picker>
              </div>
              <div class="d-flex my-5 py-4">
                <w-button
                  class="cancel mr-4 px-5"
                  :buttonText="'Cancel'"
                  :isDisable="false"
                  :buttonLoader="'none'"
                  @buttonClicked="hideWelcomePage"
                >
                </w-button>

                <w-button
                  :buttonText="'Schedule Call'"
                  @buttonClicked="scheduleACall"
                >
                </w-button>
              </div>
            </div>
            <div
              v-if="
                selectedTab === 'Create Datastream' &&
                  channelData.channel !== undefined
              "
              class="w-100"
            >
              <f-google
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-if="
                  channelData.channel.toLowerCase() ===
                    'Google Ads'.toLowerCase()
                "
              ></f-google>
              <f-facebook
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-else-if="
                  channelData.channel.toLowerCase() ===
                    'FaceBook'.toLowerCase() ||
                    channelData.channel.toLowerCase() ===
                      'FaceBook Ads'.toLowerCase()
                "
              >
              </f-facebook>
              <f-dv360
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-else-if="
                  channelData.channel.toLowerCase() ===
                    'Google Display & Video 360'.toLowerCase() ||
                    channelData.channel.toLowerCase() ===
                      'DV 360'.toLowerCase() ||
                    channelData.channel.toLowerCase() ===
                      'Display and Video 360'.toLowerCase()
                "
              >
              </f-dv360>
              <f-pinterest
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-else-if="
                  channelData.channel.toLowerCase() ===
                    'Pinterest'.toLowerCase()
                "
              >
              </f-pinterest>
              <f-microsoft
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-else-if="
                  channelData.channel.toLowerCase() ===
                    'Microsoft Ads'.toLowerCase()
                "
              >
              </f-microsoft>
              <f-snapchat
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-else-if="
                  channelData.channel.toLowerCase() === 'Snapchat'.toLowerCase()
                "
              >
              </f-snapchat>
              <f-linkedin
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-else-if="
                  channelData.channel.toLowerCase() ===
                    'LinkedIn Ads'.toLowerCase()
                "
              >
              </f-linkedin>
              <f-twitter
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-else-if="
                  channelData.channel.toLowerCase() ===
                    'Twitter Ads'.toLowerCase()
                "
              >
              </f-twitter>
              <f-google-analytics
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-else-if="
                  channelData.channel.toLowerCase() ===
                    'Google Analytics'.toLowerCase()
                "
              >
              </f-google-analytics>
              <f-youtube-insights
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-else-if="
                  channelData.channel.toLowerCase() ===
                    'YouTube Insights'.toLowerCase()
                "
              >
              </f-youtube-insights>
              <f-linkedin-insights
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-else-if="
                  channelData.channel.toLowerCase() ===
                    'LinkedIn Insights'.toLowerCase()
                "
              >
              </f-linkedin-insights>
              <f-facebook-insights
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-else-if="
                  channelData.channel.toLowerCase() ===
                    'Facebook Insights'.toLowerCase()
                "
              >
              </f-facebook-insights>
              <f-instagram-insights
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-else-if="
                  channelData.channel.toLowerCase() ===
                    'Instagram Insights'.toLowerCase()
                "
              >
              </f-instagram-insights>
              <f-twitter-insights
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-else-if="
                  channelData.channel.toLowerCase() ===
                    'Twitter Insights'.toLowerCase()
                "
              >
              </f-twitter-insights>
              <f-keyword-planner
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-else-if="
                  channelData.channel.toLowerCase() ===
                    'Google Ads Keyword Planner'.toLowerCase()
                "
              >
              </f-keyword-planner>
              <f-google-sheets
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-else-if="
                  channelData.channel.toLowerCase() ===
                    'Google_Sheets'.toLowerCase() ||
                    channelData.channel.toLowerCase() ===
                      'Google Sheets'.toLowerCase()
                "
              >
              </f-google-sheets>
              <f-google-trends
                :channelData="channelData"
                @hideWelcomePage="hideWelcomePage"
                @createdataStream="createdataStream($event)"
                v-else-if="
                  channelData.channel.toLowerCase() ===
                    'Google Trends'.toLowerCase()
                "
              >
              </f-google-trends>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import GoogleDetails from "@/pages/Forms/GoogleDetails.vue";
import FacebookDetails from "@/pages/Forms/FacebookDetails.vue";
import Dv360Detail from "@/pages/Forms/DV360Details.vue";
import PinterestDetail from "@/pages/Forms/PinterestDetails.vue";
import MicrosoftDetail from "@/pages/Forms/MicrosoftDetails.vue";
import SnapchatDetail from "@/pages/Forms/SnapchatDetails.vue";
import LinkedInDetail from "@/pages/Forms/LinkedInDetails.vue";
import TwitterDetail from "@/pages/Forms/TwitterDetails.vue";
import GoogleAnalytics from "@/pages/Forms/GoogleAnalyticsDetails.vue";
import YoutubeInsights from "@/pages/Forms/YoutubeInsightsDetails.vue";
import LinkedInInsights from "@/pages/Forms/LinkedinInsightsDetails.vue";
import FacebookInsights from "@/pages/Forms/FacebookInsightsDetails.vue";
import InstagramInsights from "@/pages/Forms/InstagramInsightsDetails.vue";
import TwitterInsights from "@/pages/Forms/TwitterInsightsDetails.vue";
import KeywordPlannerInsights from "@/pages/Forms/GoogleKeywordPlannerDetails.vue";
import GoogleSheets from "@/pages/Forms/GoogleSheetsDetails.vue";
import GoogleTrendsDetails from "@/pages/Forms/GoogleTrendsDetails.vue";
import Tab from "../../components/Solutions/Tab.vue";
import DatePicker from "vue2-datepicker";
import Button from "@/components/Profile/Button.vue";
export default {
  name: "DataStreamModal",
  props: {},
  data() {
    return {
      isActive: 0,
      modalTitle: "",
      modalShow: false,
      selectedDate: "",
      channelData: "",
      tabList: [{ category: "Create Datastream" }, { category: "Talk to us" }],
      selectedTab: "Create Datastream",
    };
  },
  components: {
    "w-tab": Tab,
    "w-button": Button,
    "w-date-picker": DatePicker,
    "f-google-trends": GoogleTrendsDetails,
    "f-facebook": FacebookDetails,
    "f-google": GoogleDetails,
    "f-dv360": Dv360Detail,
    "f-pinterest": PinterestDetail,
    "f-microsoft": MicrosoftDetail,
    "f-snapchat": SnapchatDetail,
    "f-linkedin": LinkedInDetail,
    "f-twitter": TwitterDetail,
    "f-google-analytics": GoogleAnalytics,
    "f-youtube-insights": YoutubeInsights,
    "f-linkedin-insights": LinkedInInsights,
    "f-facebook-insights": FacebookInsights,
    "f-instagram-insights": InstagramInsights,
    "f-twitter-insights": TwitterInsights,
    "f-keyword-planner": KeywordPlannerInsights,
    "f-google-sheets": GoogleSheets,
  },
  computed: {},
  methods: {
    changeTab(e) {
      this.selectedTab = e;
    },
    scheduleACall() {
      console.log("scheduleACall");
      this.hideWelcomePage();
    },
    open(data) {
      this.modalTitle = data.title;
      this.channelData = data.details;
      this.$modal.show("dataStreamModal");
    },
    hideWelcomePage() {
      this.$modal.hide("dataStreamModal");
    },
    createdataStream(data) {
      this.$emit("createStreamDetails", data);
    },
  },
};
</script>
<style>
.datastrem--modal > .vm--modal {
  width: 75% !important;
  box-shadow: rgb(201 206 213) 0px 0px 8px 0px;
  margin: auto;
  display: block;
  top: 0 !important;
  left: auto !important;
}
.datastrem--modal .vm--overlay {
  background: rgba(0, 0, 0, 0.9) !important;
}
</style>
<style lang="css" scoped>
.cancel.button.update {
  background-color: transparent;
  color: #050505;
}
.cancel.button.update:hover {
  color: #fff !important;
}
span.start-from-fresh.mt-4 {
  width: 100%;
  height: 22px;
  font-size: 18px;
  font-weight: 600;
  float: left;
  cursor: pointer;
}

.btn-effect-intro {
  width: 78% !important;
  margin-top: 0 !important;
}
.start-from-fresh {
  width: 22% !important;
}
</style>
