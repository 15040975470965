<template>
  <modal
    name="overviewPage"
    class="overview-modal"
    @closed="hideOverviewPage"
    :scrollable="true"
    :height="'auto'"
    :clickToClose="false"
  >
    <div class="">
      <div class="w-100 modal-scroll">
        <div class="row" style="">
          <div class="col p-0 welcome ml-4 mt-4 align-start content-center">
            <img
              src="../../assets/Icons/group-21.png"
              alt
              class="welcome--image"
            />
          </div>
          <div class="col p-0 intro__content">
            <p class="Welcome-to-Data-Poem ProximaNovaRegular">{{ title }}</p>
            <div class="input-group">
              <div class="form-outline">
                <input
                  type="search"
                  id="form1"
                  class="form-control search-box ProximaNovaBold"
                  placeholder="Search"
                  v-model="searchTerm"
                />
              </div>
              <button type="button" class="btn btn-primary search-button">
                <i class="fas fa-search fa-2x"></i>
              </button>
            </div>
            <div class="modal-body">
              <div
                class="container-fluid"
                style="margin-top: 36px; padding: 0px"
              >
                <div class="card1 w-100">
                  <div class="card-body1">
                    <div class="modal-connectors">
                      <w-connector
                        :addActiveClass="false"
                        :connectors="serachedItem"
                        :selectedConnectorsList="selectedConnectors"
                        @addPlatform="addPlatform($event)"
                      >
                      </w-connector>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row w-100">
              <div
                class="
                  col-sm-6 col-md-6
                "
              >
                <w-button
                  class="cancel mr-4 px-5"
                  :buttonText="'Cancel'"
                  :isDisable="false"
                  :buttonLoader="'none'"
                  @buttonClicked="hideOverviewPage"
                >
                </w-button>
              </div>
              <div
                class="col-sm-6 col-md-6 d-flex justify-content-start align-item-start"
              >
                <w-button
                  :buttonText="'Done'"
                  @buttonClicked="proceedToNext"
                  :buttonLoader="'normal'"
                >
                </w-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <connection-modal ref="connectionModal" 
    @createConnection="createConnection($event)" />
    <authorized-modal ref="authorizedModal" @authorizedDone="authoriseConnection" />
  </modal>
</template>

<script>
import Connector from "@/components/AIMO-AI/Connector.vue";
import ConnectionModal from "@/components/Modal/ConnectionModal.vue";
import AuthorizedModal from "@/components/Modal/AuthorizedModal.vue";
import Button from "@/components/Profile/Button.vue";
import { DataConnection } from "@/services/DataConnectionService.js";
const dataConnection = new DataConnection();
import { ApiHelper } from "@/services/helper/ApiHelper.js";
let apiHelper = new ApiHelper();
export default {
  name: "OverviewModal",
  data() {
    return {
      modalTitle: "",
      modalShow: false,
      searchTerm: "",
      allItems: [],
      tabsResult: this.tabs,
      connectors: [],
      itemName: [],
      allBrandsList: [],
      authorize: { url: "", id: "" },
      currentConnection: {},
    };
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
    selectedConnectors: {
      type: Array,
      required: true,
    },
    selectedConnectorsForViewModal: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "Default",
    },
  },
  components: {
    "w-connector": Connector,
    "connection-modal": ConnectionModal,
    "authorized-modal": AuthorizedModal,
    "w-button": Button,
  },
  mounted() {
    this.getConnections();
  },
  computed: {
    serachedItem() {
      if (this.searchTerm !== "") {
        return this.selectedConnectorsForViewModal.filter(
          (obj) =>
            obj.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1
        );
      } else {
        return this.selectedConnectorsForViewModal;
      }
    },
  },
  methods: {
    createConnection(data) {
      this.currentConnection = data;
      dataConnection.createConnection(data).then((res) => {
        this.$refs.connectionModal.hideWelcomePage();
        this.authorize.url = res.response[0];
        this.authorize.id = res.response[1];
        this.$refs.authorizedModal.open();
      });
    },
    authoriseConnection() {
      var winobj = window.open(
        this.authorize.url,
        "Snopzer",
        "left=500,top=150,width=500,height=500,toolbar=1,resizable=0"
      );
      var self = this;
      var loop = setInterval(
        function() {
          if (winobj.closed) {
            const uri = `health-monitor-module/connection_isauthorized?connectionId=${
              self.authorize.id
            }&connectionType=${
              self.currentConnection.connectionId
            }&orgNumber=${sessionStorage.getItem("subId")}&channelName=${
              self.currentConnection.channelName
            }`;
            apiHelper.get(uri).then((res) => {
              console.log(res);
              self.$refs.authorizedModal.hideAuthorizedPage();
            });
            clearInterval(loop);
          }
        }.bind(this),
        100
      );
    },
    doneAuthorized() {
      console.log("doneAuthorized");
    },
    getConnections() {
      this.connectors = [];
      this.tabs.map((post) => {
        post.tabContent.map((content) => {
          content.connectors.map((connect) => {
            this.connectors.push(connect);
          });
        });
      });
    },
    // resultQuery() {
    //   if (this.searchTerm !== "") {
    //     this.serachedItem = this.selectedConnectorsForViewModal.filter(
    //       (obj) =>
    //         obj.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) !== -1
    //     );
    //   } else {
    //     this.serachedItem = this.selectedConnectorsForViewModal;
    //   }
    // },
    open() {
      this.$modal.show("overviewPage");
    },
    hideOverviewPage() {
      this.$modal.hide("overviewPage");
    },
    proceedToNext() {
      this.$emit("overviewDone");
      this.$modal.hide("overviewPage");
    },
    addPlatform(obj) {
      this.allBrandsList = [];
      dataConnection
        .getAllBrands(
          sessionStorage.getItem("userId"),
          sessionStorage.getItem("subId")
        )
        .then((res) => {
          for (var i = 0; i < res.response.length; i++) {
            this.allBrandsList.push({
              text: res.response[i].brandName,
              id: res.response[i].id,
            });
            if (i === res.response.length - 1) {
              this.$refs.connectionModal.open(obj.obj, this.allBrandsList);
            }
          }
        });
    },
  },
};
</script>
<style>
.overview-modal .vm--modal{
  width: 75% !important;
  box-shadow: rgb(201 206 213) 0px 0px 8px 0px;
  margin: auto;
  display: block;
  top: 0 !important;
  left: auto !important;
}
</style>
<style lang="css" scoped>
.authorized-cancel-btn {
  font-size: 16px;
  font-weight: normal;
  text-decoration: underline;
}
.cancel-btn {
  font-weight: 600;
  font-stretch: normal;
  font-size: 18px;
  cursor: pointer;
}
.cancel.button.update {
  background-color: transparent;
  color: #050505;
}
.cancel.button.update:hover {
  color: #fff !important;
}
.changeColor .disableButton {
  color: #fff !important;
}
.image {
  background-color: #fff;
  height: 48px;
  width: 48px;
  align-items: center;
}
.action {
  color: #000;
}
.container {
  height: 100%;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}
.step-indicator .stepactive::after {
  background: #050505;
}
.tab-panel.showConnectors {
  display: block;
}
.tab-panel {
  display: none;
}
.card {
  display: block;
}
span.start-from-fresh.mt-4 {
  width: 100%;
  height: 22px;
  font-size: 18px;
  font-weight: 600;
  float: left;
  cursor: pointer;
}
.search-box {
  width: 390px;
  height: 60px;
  font-size: 16px;
}
.search-button {
  width: 50px;
  background-color: #050505;
}
/deep/ .modal-connectors .connector {
  height: 80px;
  width: 243px;
  margin: 11px 11px 11px 0;
}
.welcome {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  align-items: center;
}
.modal-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #f5f5f5;
}

.modal-body::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.modal-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e8edf5;
}
span.start-from-fresh.mt-4 {
  width: 100%;
  height: 22px;
  font-size: 18px;
  font-weight: 600;
  float: left;
  cursor: pointer;
}
.btn-effect-intro-modal {
  background-color: #050505;
  color: #f3f3f3;
  border: none;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
  width: 175px !important;
  height: 60px !important;
}
.img-section {
  padding: 3rem;
}
.modal-body {
  overflow-y: scroll;
  width: 525px;
  height: 450px;
  padding: 0;
}
.input-group {
  margin-bottom: 15px;
}
.col.p-0.intro__content {
  padding: 25px !important;
}
.mobile-cancel-button {
  display: none;
}
::placeholder {
  color: #9aafd4;
  opacity: 1;
}
input#form1 {
  color: #222a37;
}
@media screen and (max-width: 640px) {
  input#form1 {
    width: 100%;
  }
  >>> .modal-connectors .connector {
    width: 300px !important;
  }
  .form-outline {
    margin-left: 15%;
  }
  >>> .mobile-cancel-button {
    display: block;
  }
  .cancel-button {
    display: none;
  }
  >>> .cancel-btn-section {
    text-align: center;
  }
}
</style>
