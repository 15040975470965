<template>
  <div class="w-100">
    <div class="row justify-center">
      <p class="d-none">{{ tabName }}</p>
      <div
        class="tab pr-5 pl-4 "
        v-for="(tab, index) in tabList"
        :key="index"
        @click="changeTab(tab.category)"
      >
        <div
          class="mb-2 "
          style="font-size: 20px"
          :style="{ color: getColor(tab.category, tab.required) }"
          :class="[
            activeTab === tab.category
              ? 'ProximaNovaBold'
              : 'ProximaNovaSemiBold',
            getColor(tab.category, tab.required) === 'darkred' ? 'tooltip' : '',
          ]"
        >
          {{ tab.category }}
          <hr
            style="
                      width: 50px;
                      opacity: 1;
                      color: #050505;
                      margin: 4px auto;
                      height: 2px;
                    "
            v-if="activeTab === tab.category"
          />
          <span
            v-if="getColor(tab.category, tab.required) === 'darkred'"
            class="tooltiptext"
            >We require at least one connection to run modal</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabName: {
      type: String,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    connectorList: {
      type: Array,
      default: () => [],
    },
    extraTab: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeTab: "",
      digitalMediaList: [
        "Google Ads",
        "Facebook Ads",
        "LinkedIn Ads",
        "Twitter Ads",
        "Display and Video 360",
        "Micosoft Ads",
        "Pinterest",
        "SnapchatF",
      ],
      analyticsList: [
        "Adobe Analytics",
        "Google Analytics",
        "Adobe Analytics (Service)",
        "Adobe Analytics(WSSE)",
        "Convert",
        "Etracker",
        "Google Analytics(Oauth)",
        "Google Analytics(Service)",
        "IBM Analytics",
        "Magnite Performance",
        "Mixpanel",
        "Optimizely",
        "Piwik Pro",
        "Similar Web",
        "Site Impact",
        "Webtrekk",
        "Webtrends",
      ],
      gTrendsList: ["Google Trends"],
      KPITab: [
        "Google Analytics",
        "Adobe Analytics (Service)",
        "Adobe Analytics",
        "Adobe Analytics(WSSE)",
        "Convert",
        "Etracker",
        "Google Analytics(Oauth)",
        "Google Analytics(Service)",
        "IBM Analytics",
        "Magnite Performance",
        "Mixpanel",
        "Optimizely",
        "Piwik Pro",
        "Similar Web",
        "Site Impact",
        "Webtrekk",
        "Webtrends",
        "Active Campaign",
        "Braze",
        "Campaign Monitor",
        "Cordial",
        "Emarsys",
        "Evalanche",
        "Hubspot Destination",
        "Hubspot",
        "Iterable",
        "Klaviyo",
        "Mailchimp",
        "Mailing Work",
        "Mailworx",
        "Marketo",
        "Microsoft Dynamics",
        "Piano",
        "Salesforce Marketing Cloud",
        "Salesforce",
        "Sendinblue",
        "Sharpspring",
        "Teamleader",
        "Zendesk Sell",
        "Shopify",
      ],
      tabList: [],
    };
  },
  computed: {
    getColor() {
      return (tab, require) => {
        if (require && tab === "Digital Media*") {
          var value = this.connectorList.some((item) =>
            this.digitalMediaList.includes(item.connectorName)
          );
          if (value) {
            return "darkgreen";
          } else {
            return "darkred";
          }
        } else if (require && tab === "Analytics*") {
          value = this.connectorList.some((item) =>
            this.analyticsList.includes(item.connectorName)
          );
          if (value) {
            return "darkgreen";
          } else {
            return "darkred";
          }
        } else if (require && tab === "Search Trends*") {
          value = this.connectorList.some((item) =>
            this.gTrendsList.includes(item.connectorName)
          );
          if (value) {
            return "darkgreen";
          } else {
            return "darkred";
          }
        } else if (require && tab === "KPI*") {
          value = this.connectorList.some((item) =>
            this.KPITab.includes(item.connectorName)
          );
          if (value) {
            return "darkgreen";
          } else {
            return "darkred";
          }
        } else if (!require) {
          if (this.activeTab === tab) {
            return "#050505";
          } else {
            return "#050505";
          }
        }
      };
    },
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
      this.$emit("activeTab", tab);
    },
  },
  beforeUpdate() {
    this.activeTab = this.tabName;
    this.tabList = this.tabs.concat(this.extraTab);
  },
  created() {
    this.activeTab = this.tabName;
    this.tabList = this.tabs.concat(this.extraTab);
  },
};
</script>

<style scoped>
.tooltip {
  position: relative;
  opacity: 1;
  z-index: auto;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #fff;
  color: #050505;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  box-shadow: rgb(201 206 213) 0px 0px 8px 0px;
  display: flex;
  font-size: small;
  font-family: ProximaNovaRegular;

  /* Position the tooltip */
  position: absolute;
  z-index: 99999;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tab {
  cursor: pointer;
  display: contents;
}
.tab div {
  padding: 0px 10px;
}
</style>
