<template>
  <modal
    name="authorizedModal"
    :width="388"
    class="welcome--modal"
    @closed="hideAuthorizedPage"
    :clickToClose="false"
  >
    <div class="">
      <div class="w-100">
        <div class="row modal-padding">
          <div class="col p-0 welcome ml-4 mt-4 align-start">
            <img src="../../assets/group-21.png" alt class="welcome--image" />
          </div>
          <div class="col p-0 intro__content">
            <p class="Welcome-to-Data-Poem ProximaNovaRegular">Authorize</p>
            <p
              class="Together-let-us-hel ProximaNovaRegular mt-0 w-75"
              style="height: auto"
            >
              Hey <b>{{ username }}</b
              >, click below to authorize.
            </p>
            <div class="my-5 py-4 d-grid">
              <w-button
                :buttonText="'Authorize'"
                :buttonLoader="buttonLoader"
                @buttonClicked="authorizedBtn"
                :isDisable="buttonLoader !== 'normal'"
                :class="{ resetPasswordLink: buttonLoader !== 'normal' }"
              >
              </w-button>
              <div class="row justify-center mt-4" style="font-size: 16px" v-if="buttonLoader === 'normal'">
                <div
                  class="editProfile ProximaNovaRegular content-center"
                  @click="hideAuthorizedPage"
                >
                  <span>No, I change my mind</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Button from "@/components/Profile/Button.vue";
export default {
  name: "AuthorizedModal",
  components: {
    "w-button": Button,
  },
  data() {
    return {
      modalTitle: "",
      modalShow: false,
      buttonLoader: "normal",
      username: "",
    };
  },
  methods: {
    open() {
      this.username = sessionStorage.getItem("userName");
      this.buttonLoader = "normal";
      this.$modal.show("authorizedModal");
    },
    hideAuthorizedPage() {
      this.$modal.hide("authorizedModal");
    },
    authorizedBtn() {
      this.buttonLoader = "loader";
      this.$emit("authorizedDone");
    },
  },
};
</script>

<style lang="css" scoped>
span.start-from-fresh.mt-4 {
  width: 100%;
  height: 22px;
  font-size: 18px;
  font-weight: 600;
  float: left;
  cursor: pointer;
}
@media screen and (max-width: 900px) {
  p.Welcome-to-Data-Poem.ProximaNovaRegular {
    text-align: center;
  }
  p.Together-let-us-hel.ProximaNovaRegular.mt-0.w-75 {
    margin-left: 50px;
  }
  button.btn-effect-intro,
  span.start-from-fresh.mt-4.ProximaNovaRegular,
  .authorized-cancel-btn {
    margin-left: 50px;
  }
}
</style>
