<template>
  <div>
    <div class="page-wrapper chiller-theme">
      <main class="page-content">
        <Toast
          v-if="toastData.show"
          :type="toastData.type"
          :message="toastData.message"
          :show="toastData.show"
          :time="10000"
          @clearToast="clearToast"
        />
        <!-- Datastream Summary Page -->
        <div class="row">
          <div class="col-md-12">
            <div class="Datastream-main-rect">
              <b-row class="">
                <b-col
                  ><h5 class="Datastream-title">
                    Connection Summary
                  </h5></b-col
                >
                <b-col cols="8" class="filter-section">
                  <div class="d-flex justify-content-end filter-icons">
                    <!--<div
                      class="h-100 d-flex align-items-center"
                      :class="{
                        'image-wrapper-active': overDelete || isFilterShow,
                      }"
                      @mouseover="overDelete = true"
                      @mouseleave="overDelete = false"
                      @click="isFilterShow = !isFilterShow"
                    >
                      <div v-if="overDelete" class="tooltip-wrapper">
                        Filter
                      </div>
                      <img
                        v-if="overDelete || isFilterShow"
                        src="../../assets/filter-line.svg"
                        class="image"
                      />
                      <img
                        v-else
                        src="../../assets/filter-fill.svg"
                        class="image"
                      />
                    </div>-->
                    <!-- filter dropdowns -->
                   <!-- <div
                      class="col-md-4 colo-lg-6"
                      style="height: 40%"
                      v-show="isFilterShow"
                    >
                      <div class="w-100">
                        <w-dropdown
                          :options="platforms"
                          v-model="dropdownplatforms"
                          :placeHolder="'Select'"
                          :labelText="'Platforms'"
                          @input="selectFilter($event)"
                        ></w-dropdown>
                      </div>
                    </div>
                    <div
                      class="col-md-4 colo-lg-6"
                      style="height: 40%"
                      v-show="isFilterShow"
                    >
                      <div class="w-100">
                        <w-dropdown
                          :options="channels"
                          v-model="dropdownchannel"
                          :placeHolder="'Select'"
                          :labelText="'Channels'"
                          @input="selectFilter($event)"
                        ></w-dropdown>
                      </div>
                    </div>-->
                    <!-- filter dropdowns -->
                    <div
                      class="h-100 d-flex align-items-center"
                      :class="{ 'image-wrapper-active': overShare }"
                      @mouseover="overShare = true"
                      @mouseleave="overShare = false"
                      @click="isShow = !isShow"
                      v-if="!isShow"
                    >
                      <div v-if="overShare" class="tooltip-wrapper">Search</div>
                      <img
                        v-if="overShare"
                        src="../../assets/Icons/searchWhite.svg"
                        class="image"
                      />
                      <img
                        v-else
                        src="../../assets/Icons/searchDark.svg"
                        class="image"
                      />
                    </div>
                    <div v-show="isShow">
                      <b-input-group class="">
                        <b-input-group-prepend @click="isShow = !isShow">
                          <img
                            src="@/assets/Icons/searchDark.svg"
                            class="image"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          placeholder="Search by Name"
                          @keyup="clickedSearch"
                          v-model="searchedTextValue"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
        <!-- Datastream sub page -->
        <div class="row">
          <div class="col-md-12" style="max-width: 100% !important;">
            <b-table
              id="spaceTable"
              :items="tableData"
              :fields="fields"
              class="mb-0"
              :total-rows="rows"
              :per-page="perPage"
              @sort-changed="sortingChanged"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  style="width: 20rem"
                />
              </template>
              <template #head()="data">
                <div class="column-heading">{{ data.label }}</div>
              </template>
              <template #cell(ConnectionName)="data">
                <div class="name-wrapper w-100">
                  <div class="campagin-name">
                    {{ data.item.ConnectionName }}
                  </div>
                  <!-- <div class="badge-wrapper">
                    <b-badge
                      v-for="(item, index) in data.item.products"
                      :key="index"
                      class="mb-2 mr-2 column-data"
                      >{{ item }}</b-badge
                    >
                  </div> -->
                  <h5 class="created-on">
                    Created on:
                    <span style="color: #222a37">{{
                      data.item.createdOn
                    }}</span>
                  </h5>
                </div>
              </template>
              <template #cell(ConnectionDetails)="data">
                <div class="overview-wrapper w-100">
                  <div class="d-flex slider-wrapper w-100">
                    <div
                      :ref="`slider-${data.index}`"
                      class="d-flex statistics-wrapper w-100"
                    >
                      <div
                        v-for="(stat, index) in data.item.stats"
                        :key="index"
                        style="position: relative; width: 33%"
                      >
                        <Statistics
                          :data="{
                            label: stat.name,
                            value: stat.value,
                            icon: '',
                          }"
                          :index="index"
                          :img="getIcon(stat)"
                          :specialChar="false"
                          :specialCharText="''"
                        />
                        <div
                          v-if="data.item.stats.length - 1 > index"
                          class="item-right-line"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                      <span class="channels">
                        <span
                          class="active-oval"
                          v-if="data.item.status"
                        ></span>
                        <span class="inactive-oval" v-else></span>
                        <span v-if="data.item.status">Active</span>
                        <span v-else>Inactive</span></span
                      >
                    </div>
                    <div class="d-flex justify-content-end">
                      <w-button
                        :buttonText="'Create data stream'"
                        class="mt-1"
                        :buttonLoader="'normal'"
                        :isDisable="!data.item.status"
                        :class="{ resetPasswordLink: !data.item.status }"
                        @buttonClicked="createDataStream(data.item)"
                      >
                      </w-button>
                    </div>
                  </div>
                </div>
              </template>
            </b-table>
          </div>
        </div>
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          pills
          align="right"
          hide-goto-end-buttons
          hide-ellipsis
          :total-rows="totalRows"
          class="mb-5"
          aria-controls="spaceTable"
          @change="getSelectedPage($event)"
        >
          <template #prev-text
            ><span
              ><img
                src="@/assets/Icons/arrow-left-line.svg"
                class="arrow-image"/></span
          ></template>
          <template #next-text
            ><span
              ><img
                src="@/assets/Icons/arrow-right-line.svg"
                class="arrow-image"/></span
          ></template>
          <template #page="{ page, active }">
            <span v-if="active">{{ page }}</span>
            <i v-else>{{ page }}</i>
          </template>
        </b-pagination>
        <div v-if="tableData.length === 0" class="no-items-wrapper">
          <div class="text-center">
            <img src="@/assets/tech-life-media2.png" alt="" />
            <div v-if="searchedTextValue === ''" class="no-items-description">
              No plans have been added yet in your shared space
            </div>
            <div
              v-else-if="searchedTextValue !== ''"
              class="no-items-description"
            >
              Oops!<br />
              Sorry to say, it seems like no plan has been found
            </div>
          </div>
        </div>
      </main>
      <dataStream-modal
        ref="dataStreamModal"
        @createStreamDetails="createStreamDetails($event)"
      />
      <dataFetch-modal ref="dataFetchModal" @fetchStatus="processDone" />
    </div>
  </div>
</template>
<script>
import Statistics from "@/components/Statistics.vue";
// import Dropdown from "@/widgets/Dropdown.vue";
import "@/assets/style/datepicker/index.css";
import DataStreamModal from "@/components/Modal/DataStreamModal.vue";
import DataFetchModal from "@/components/Modal/DataFetchModal.vue";
import Toast from "@/components/Toast/Toast.vue";
import { DataConnection } from "../../services/DataConnectionService.js";
import Button from "@/components/Profile/Button.vue";
import moment from "moment";
const dataConnection = new DataConnection();
export default {
  name: "OmaniChannel",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    filterBy: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: null,
    },
    showConnectionSummary:{
      type: Boolean,
      default: false
    }
  },
  components: {
    Statistics,
    // "w-dropdown": Dropdown,
    "dataStream-modal": DataStreamModal,
    "dataFetch-modal": DataFetchModal,
    Toast,
    "w-button": Button,
  },
  watch: {
    filterBy(newVal) {
      this.filterOn = [];
      this.filterOn.push(newVal.id);
    },
  },
  data() {
    return {
      tableData: this.items,
      iconList: [
        { channel: "Google Ads", icon: "google" },
        { channel: "FaceBook", icon: "facebook" },
        { channel: "Instagram", icon: "instagram" },
        { channel: "LinkedIn Ads", icon: "linkedIn" },
        { channel: "Twitter", icon: "twitter" },
        { channel: "Google Display & Video 360", icon: "dv360" },
        { channel: "Microsoft Ads", icon: "microsoft" },
        { channel: "Pinterest", icon: "Pinterest" },
        { channel: "Snapchat", icon: "Snapchat" },
        { channel: "Google Analytics", icon: "google-anal" },
        { channel: "Facebook Insights", icon: "facebook" },
        { channel: "Instagram Insights", icon: "instagram" },
        { channel: "YouTube Insights", icon: "youtube" },
        { channel: "LinkedIn Insights", icon: "linkedin" },
        { channel: "Twitter Insights", icon: "twitter" },
        { channel: "Google Ads Keyword Planner", icon: "google" },
        { channel: "Google Trends", icon: "Google Trends" },
      ],
      totalRows: 0,
      newTableRows: [],
      platforms: [
        { text: "CRMs", id: "CRMs" }, //, disable: true
        { text: "Digital", id: "Digital" },
        { text: "Sponsorship", id: "Sponsorship" }, //, disable: true
        { text: "Billboard", id: "Billboard" }, //, disable: true
        { text: "Outdoor", id: "Outdoor" }, //, disable: true
        { text: "Public relation", id: "Public relation" }, //, disable: true
      ],
      channels: [
        { text: "Preload", id: "Preload" }, //, disable: true
        { text: "Digital media", id: "Digital media" },
        { text: "Analytics", id: "Analytics" }, //, disable: true
        { text: "App Analytics", id: "App Analytics" }, //, disable: true
      ],
      isShow: false,
      isFilterShow: false,
      fields: [
        { key: "ConnectionName", sortable: false },
        { key: "ConnectionDetails", sortable: false },
      ],
      rows: null,
      perPage: 5,
      currentPage: 1,
      overDelete: false,
      overView: false,
      overShare: false,
      searchedTextValue: "",
      dropdownchannel: "",
      dropdownplatforms: "",
      filterOn: [],
      selectedFilter: "Select",
      options: [
        { text: "Yesterday", id: "yesterday" },
        { text: "Today", id: "today" },
        { text: "Last Week", id: "lastWeek" },
        { text: "This Month", id: "thisMonth" },
        { text: "Last Year", id: "lastYear" },
        { text: "Custom Select", id: "customSelect" },
      ],
      startDate: "",
      endDate: "",
      selectedPageNo: 0,
      toastData: {
        show: false,
        type: "",
        message: "",
      },
    };
  },
  computed: {
    getIcon() {
      return (data) => {
        var icon = this.iconList.find((obj) => obj.channel === data.value);
        if (data.name === "Channel") {
          if (icon !== undefined) {
            return this.findImage(icon.icon);
          } else {
            return "google-black.png";
          }
        } else if (data.name === "Brand") {
          return "flag-black.png";
        }
        return "function-fill.png";
      };
    },
  },
  methods: {
    findImage(image) {
      try {
        require("@/assets/connectors/" + image + ".svg");
        return "connectors/" + image + ".svg";
      } catch (error) {
        if (error.code === "MODULE_NOT_FOUND") {
          try {
            require("@/assets/connectors/" + image + ".jpg");
            return "connectors/" + image + ".jpg";
          } catch (error) {
            if (error.code === "MODULE_NOT_FOUND") {
              try {
                require("@/assets/connectors/" + image + ".png");
                return "connectors/" + image + ".png";
              } catch {
                return "google-black.png";
              }
            }
            return "google-black.png";
          }
        }
        return "google-black.png";
      }
    },
    createDataStream(e) {
      this.$emit("createDataStreamWithout",e)
      // this.$refs.dataStreamModal.open({ title: "Add data stream", details: e });
    },
    fetchGoogleTrendsData(detail){
      var data = {
        dataStreamId: detail,
        duration: 0,
        clientId: sessionStorage.getItem("clientId"),
        // fetchType: this.selectedPattern,
        historicStartDate: '',
        historicEndDate: '',
        start: moment(new Date()).format("YYYY-MM-DD"),
        end: moment(new Date()).format("YYYY-MM-DD"),
      };
      dataConnection.fetchData(data).then((res)=> {
        console.log(res)
        this.processDone()
      })
    },
    createStreamDetails(data) {
      dataConnection.createDataStream(data.details, data.typeId).then((res) => {
        this.$refs.dataStreamModal.hideWelcomePage();
        if (res.status === "FAILED") {
          this.toastData = {
            show: true,
            type: "failed",
            message: "Something went wrong! Please try after some time.",
          };
          return;
        }
        if(data.details.auth === 663){
          this.fetchGoogleTrendsData(res)
          return
        }
        if (Object.keys(data.details).includes("file_id")) {
          this.$refs.dataFetchModal.open({
            title: "Initial Data Fetch",
            id: res,
            file_id: data.details.file_id,
          });
        } else {
          this.$refs.dataFetchModal.open({
            title: "Initial Data Fetch",
            id: res,
          });
        }
      });
    },
    processDone() {
      this.toastData = {
        show: true,
        type: "success",
        message: "Yay, your datastream has been created successfully",
      };
      this.getConnectionTable();
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    selectFilter(e) {
      const search = e.text;
      if (!search) {
        this.tableData = this.items;
      } else {
        if (
          this.dropdownplatforms != undefined &&
          this.dropdownplatforms &&
          this.dropdownplatforms.id &&
          this.dropdownchannel != undefined &&
          this.dropdownchannel &&
          this.dropdownchannel.id
        ) {
          var searchvalue = this.dropdownplatforms.id;
          var platschannel = this.dropdownchannel.id;
          let result = this.items.filter(function(e) {
            return e.plateForm == searchvalue && e.channel == platschannel;
          });
          this.rows = result.length;
          this.currentPage = 1;
          this.tableData = result;
        } else if (
          this.dropdownplatforms != undefined &&
          this.dropdownplatforms &&
          this.dropdownplatforms.id
        ) {
          var searchvaluedata = this.dropdownplatforms.id;
          let result = this.items.filter(function(e) {
            return e.plateForm == searchvaluedata;
          });
          this.rows = result.length;
          this.currentPage = 1;
          this.tableData = result;
        } else if (
          this.dropdownchannel != undefined &&
          this.dropdownchannel &&
          this.dropdownchannel.id
        ) {
          var searchvaluetwo = this.dropdownchannel.id;
          let result = this.items.filter(function(e) {
            return e.channel == searchvaluetwo;
          });
          this.rows = result.length;
          this.currentPage = 1;
          this.tableData = result;
        }
      }
    },
    getSelectedPage(event) {
      if (this.searchedTextValue === "") {
        if (event != this.selectedPageNo) {
          this.selectedPageNo = event;
          this.currentPage = event;
          this.perPage = 5;
          this.getConnectionTable();
        }
      } else {
        this.selectedPageNo = event;
        this.currentPage = event;
        this.perPage = 20;
        this.getSearchedTableData(this.searchedTextValue);
      }
    },
    clickedSearch(event) {
      this.searchedTextValue = event.target.value.toLowerCase();
      if (this.searchedTextValue.length > 0) {
        this.perPage = 20;
        this.currentPage = 1;
        this.getSearchedTableData(this.searchedTextValue);
      } else {
        this.currentPage = 1;
        this.perPage = 5;
        this.getConnectionTable();
      }
    },
    getSearchedTableData(data) {
      dataConnection
        .getsearchConnectionName(data, this.currentPage, this.perPage)
        .then((res) => {
          this.tableData = [];
          this.totalRows = res.totalElements;
          for (var i = 0; i < res.content.length; i++) {
            this.tableData.push({
              ConnectionName: res.content[i].name,
              channel: res.content[i].channelName,
              createdOn: res.content[i].createdOn,
              plateForm: "",
              status: res.content[i].isauthorized,
              connectionId: res.content[i].adverityConnectionId,
              brandId: res.content[i].brandId,
              stats: [
                { name: "Channel", value: res.content[i].channelName },
                { name: "Brand", value: res.content[i].brandName },
                {
                  name: "No. of streams",
                  value: res.content[i].numberOfDatastreams,
                },
              ],
            });
          }
        });
    },
    sortingChanged(e) {
      console.log(e);
    },
    getConnectionTableWithout() {
      dataConnection
        .getConnectionWithoutDataStream(sessionStorage.getItem("subId"),this.currentPage, this.perPage)
        .then((res) => {
          this.$emit("totalConnection",res.totalElements)
          this.tableData = [];
          this.totalRows = res.totalElements;
          for (var i = 0; i < res.content.length; i++) {
            this.tableData.push({
              ConnectionName: res.content[i].name,
              channel: res.content[i].channelName,
              createdOn: res.content[i].createdOn,
              plateForm: "",
              status: res.content[i].isauthorized,
              connectionId: res.content[i].adverityConnectionId,
              brandId: res.content[i].brandId,
              stats: [
                { name: "Channel", value: res.content[i].channelName },
                { name: "Brand", value: res.content[i].brandName },
                {
                  name: "No. of streams",
                  value: res.content[i].numberOfDatastreams,
                },
              ],
            });
          }
        });
    },
    getConnectionTable() {
      dataConnection
        .getConnectionTableData(this.currentPage, this.perPage)
        .then((res) => {
          this.tableData = [];
          this.totalRows = res.totalElements;
          for (var i = 0; i < res.content.length; i++) {
            this.tableData.push({
              ConnectionName: res.content[i].name,
              channel: res.content[i].channelName,
              createdOn: res.content[i].createdOn,
              plateForm: "",
              status: res.content[i].isauthorized,
              connectionId: res.content[i].adverityConnectionId,
              brandId: res.content[i].brandId,
              stats: [
                { name: "Channel", value: res.content[i].channelName },
                { name: "Brand", value: res.content[i].brandName },
                {
                  name: "No. of streams",
                  value: res.content[i].numberOfDatastreams,
                },
              ],
            });
          }
        });
    },
  },
  created() {
    if(this.showConnectionSummary){
      this.getConnectionTable();
    }else{
    this.getConnectionTableWithout();
    }
  },
};
</script>
<style scoped>
button.btn-effect-outline:hover {
  color: #fff;
  background-color: #000;
}
.summary-titles {
  margin-top: 60px;
}
/* Datastream main page */
.Datastream-main-rect {
  /* width: 1514px; */
  height: 126px;
  object-fit: contain;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #ffffff;
  position: relative;
  top: 70px;
}
.tooltip-wrapper {
  width: 80px;
  height: 56px;
  background-color: #050505;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  clip-path: polygon(100% 0, 100% 53%, 50% 86%, 0 53%, 0 0);
  padding-top: 4px;
}
.Datastream-title {
  font-family: ProximaNovaBold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
  position: relative;
  left: 20px;
  top: 20px;
}

.filter-section {
  top: 20px;
}

.filter-icons {
  margin-right: 15px;
}

.filter-fill {
  width: 24px;
  height: 24px;
  object-fit: contain;
  position: relative;
  top: -3px;
  float: right;
  left: -80px;
}

.searchDark {
  float: right;
  width: 24px;
  height: 24px;
  margin-left: 100px;
  object-fit: contain;
}

.Datastream-subtitle {
  font-family: ProximaNovaBold;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
  position: relative;
  left: 20px;
}

.Datastream-subtitle2 {
  position: relative;
  font-family: ProximaNovaBold;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222a37;
  position: relative;
}

>>> .item-text__subtitle {
  font-size: 20px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.active-oval {
  width: 10px;
  height: 10px;
  margin: 6px 8px 4px 0;
  background-color: #55ea76;
  border-radius: 50%;
  display: inline-block;
}
.inactive-oval {
  width: 10px;
  height: 10px;
  margin: 6px 8px 4px 0;
  background-color: #d8d8d8;
  border-radius: 50%;
  display: inline-block;
}

.item {
  /* width: 420px; */
}

>>> .table > tbody > tr {
  background-color: #ffffff;
}

.created-on {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c9ced5;
  position: absolute;
  bottom: 0;
}

.end-date {
  width: 173px;
  height: 20px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  margin: 46px 122px 0px -10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #c9ced5;
  margin-top: 15px;
}

.column-heading {
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
}
.column-data {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #222a37;
  font-weight: normal;
}
.table-top {
  padding: 12px;
}
.name-wrapper {
  padding: 24px;
  min-height: 180px;
  position: relative;
}
.campagin-name {
  font-family: ProximaNovaBold;
  font-size: 20px;
  color: #222a37;
}
.badge-wrapper {
  margin-top: 14px;
}

.overview-wrapper {
  border-left: 1px solid #dbdee4;
}
.slider-wrapper {
  border-bottom: 1px solid #dbdee4;
}
.statistics-wrapper {
  padding: 24px 15px;
  width: 100% !important;
  overflow-x: hidden;
}
.slider-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 31px;
  z-index: 9;
  margin: 8px 0px;
  font-size: 20px;
}
.slider-left {
  box-shadow: 22px 0px 14px #fff;
}
.slider-right {
  box-shadow: -22px 0px 14px #fff;
}
.channels {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #222a37;
  margin-left: 31px;
  display: flex;
  border: 1px solid #ccd2db;
  padding: 10px;
  border-radius: 50px;
}

.item-right-line {
  position: absolute;
  height: 40px;
  width: 1px;
  background: #e8edf5;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
>>> .table > thead > tr > th {
  padding: 27px 26px;
}
>>> .table > tbody > tr {
  outline: none;
  box-shadow: 0 0 8px 0#c9ced5;
  border-top-width: 8px;
}
>>> .table > tbody > tr > td {
  padding: 0px;
}
>>> .table > tbody > .table-active > td {
  background-color: #eff5ff;
}
>>> .table > thead > tr > th {
  outline: none;
}
>>> .table > :not(:last-child) > :last-child > * {
  border: none;
}
>>> .table.b-table > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table > tfoot > tr > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right 1.5rem center;
}
>>> .table > tbody > tr > td:first-child {
  width: 20%;
}
>>> .table > tbody > tr > td:nth-child(2) {
  width: 80%;
}
input[type="text"] {
  border: none;
  border-color: #c6ccd6;
  margin-left: 0;
  background-color: #eff5ff;
  box-shadow: none;
  height: 60px;
  font-size: 16px;
}
input::placeholder {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  color: #9aafd4;
}
input:focus {
  background-color: #eff5ff;
  outline: none;
  box-shadow: none;
}
.image {
  padding: 16px 16px;
}

>>> .page-item.disabled .page-link {
  background-color: transparent;
}
>>> .page-link {
  height: 36px;
  width: 36px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  color: #9aafd4;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
}

>>> .page-link i {
  font-style: normal;
}

>>> .active > .page-link {
  background-color: transparent;
  border: 2px solid #050505;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  color: #222a37;
  box-shadow: none;
}
.no-items-wrapper {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.no-items-description {
  opacity: 0.5;
  font-family: ProximaNovaBold;
  font-size: 16px;
  color: #222a37;
  margin-top: 42px;
}
.image-wrapper-active {
  position: relative;
  background-color: #050505;
}
ul.pagination.mb-5.b-pagination.justify-content-end.b-pagination-pills {
  justify-content: flex-start !important;
}
.input-group-prepend {
  background-color: #eff5ff;
}
>>> .welcome--modal .vm--modal {
  /* width: 50% !important; */
  /* margin-top: 100px; */
}
.badge {
  padding: 8px;
  background-color: #deffe6;
  border: 1px solid #81f89e;
}
@media screen and (max-width: 640px) {
  >>> .welcome--modal .vm--modal {
    width: 75% !important;
  }
  >>> p.Welcome-to-Data-Poem.ProximaNovaRegular {
    text-align: center;
  }
  >>> p.Together-let-us-hel.ProximaNovaRegular.mt-0 {
    padding: 0px 15px;
    margin-left: 20px;
  }
  >>> .modal-padding {
    padding: 100px 0;
    flex-direction: column !important;
  }
  >>> .row.mt-3.add-fetching-detail-btn {
    margin-left: 6rem !important;
  }
}
@media screen and (max-width: 768px) and (min-width: 640px) {
  >>> .welcome--modal .vm--modal {
    width: 75% !important;
  }
  >>> p.Welcome-to-Data-Poem.ProximaNovaRegular {
    text-align: center;
  }
  >>> .modal-padding {
    padding: 100px 0;
    flex-direction: column !important;
  }
  >>> p.Together-let-us-hel.ProximaNovaRegular.mt-0 {
    padding: 0px 15px;
    margin-left: 20px;
  }
  >>> .row.mt-3.add-fetching-detail-btn {
    margin-left: 6rem !important;
  }
}
@media screen and (max-width: 900px) and (min-width: 768px) {
  >>> .welcome--modal .vm--modal {
    width: 75% !important;
  }
  >>> p.Welcome-to-Data-Poem.ProximaNovaRegular {
    text-align: center;
  }
  .modal-padding {
    padding: 100px 0;
    flex-direction: column !important;
  }
  >>> p.Together-let-us-hel.ProximaNovaRegular.mt-0 {
    padding: 0px 15px;
    margin-left: 20px;
  }
  >>> .row.mt-3.add-fetching-detail-btn {
    margin-left: 6rem !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 900px) {
  >>> .welcome--modal .vm--modal {
    width: 90% !important;
  }
}
@media screen and (max-width: 1200px) {
  >>> .welcome--modal .vm--modal {
    width: 90% !important;
  }
}
</style>
