<template>
  <div class="Together-let-us-hel ProximaNovaRegular mt-0" style="height: auto">
    <b-form-input
      type="email"
      class="form-control input-control c-form-input m-0 mb-5 w-100"
      aria-describedby="emailHelp"
      placeholder="Data stream name"
      name="example-input-1"
      v-model="dataStreamName"
    ></b-form-input>
    <w-dropdown
      :options="channels"
      :labelText="'Channels'"
      class="mb-5 w-100"
      :selectedOption="selectedChannel"
    ></w-dropdown>
    <w-dropdown
      :options="connectionDropdown"
      :labelText="'Connection'"
      class="mb-5 w-100"
      :selectedOption="selectedConnection"
    ></w-dropdown>
    <w-tree-dropdown
      :placeHolder="'Select Account'"
      :rootOptions="accountList"
      :selectedValue="selectedAccounts"
      @selectedField="selectAccounts($event)"
    ></w-tree-dropdown>
    <div class="my-5 w-100 content-center">
      <div>
        <w-button
          class="cancel mr-4 px-5"
          :buttonText="'Cancel'"
          :isDisable="false"
          :buttonLoader="'none'"
          style="font-size: medium;"
          @buttonClicked="hideWelcomePage"
        >
        </w-button>
      </div>
      <div>
        <w-button
          :buttonLoader="buttonLoader"
          :buttonText="'Add Fetching Details'"
          :isDisable="disableConnectors"
          style="font-size: medium;"
          :class="{ resetPasswordLink: disableConnectors }"
          @buttonClicked="createDataStream"
        >
        </w-button>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "@/widgets/Dropdown.vue";
import Treeselect from "@/widgets/MultiSelectDropdown.vue";
import { DataConnection } from "../../services/DataConnectionService.js";
import Button from "@/components/Profile/Button.vue";
const dataConnection = new DataConnection();
export default {
  props: {
    channelData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataStreamName: "",
      buttonLoader: "normal",
      connectionDropdown: [],
      selectedConnection: {},
      selectedChannel: {},
      channels: [],
      accountList: [],
      selectedAccounts: [],
      metrics: [
        "app_clicks",
        "card_engagements",
        "clicks",
        "conversion_custom",
        "conversion_custom.metric",
        "conversion_custom.order_quantity",
        "conversion_custom.sale_amount",
        "conversion_downloads",
        "conversion_purchases",
        "conversion_sign_ups",
        "follows",
        "engagements",
        "impressions",
        "likes",
        "mobile_conversion_installs",
        "mobile_conversion_invites",
        "replies",
        "retweets",
        "url_clicks",
        "video_3s100pct_views",
        "video_6s_views",
        "video_cta_clicks",
        "video_total_views",
        "video_views_100",
        "video_views_25",
        "video_views_50",
        "video_views_75",
      ],
      dimensions: [
        "account",
        "account_id",
        "timestamp",
        "placement",
        "date",
        "type",
        "account_id_ui",
        "account_name",
        "account_timezone",
        "campaign",
        "tweet_id",
        "tweet",
        "campaign_id",
        "line_item_id",
        "line_item_name",
        "campaign_name",
        "line_item_advertiser_user_name",
        "objective",
        "card_name",
      ],
      entities: ["PROMOTED_TWEET"],
      placement: "ALL_ON_TWITTER",
    };
  },
  created() {
    this.dataStreamName = "";
    this.buttonLoader = "normal";
    this.connectionDropdown = [
      {
        text: this.channelData.ConnectionName,
        id: this.channelData.connectionId,
      },
    ];
    this.selectedConnection = this.connectionDropdown[0];
    this.channels = [
      { text: this.channelData.channel, id: this.channelData.channel },
    ];
    this.selectedChannel = this.channels[0];
    this.getAccounts();
  },
  computed: {
    disableConnectors() {
      if (
        this.dataStreamName !== "" &&
        this.selectedAccounts.length !== 0 &&
        this.buttonLoader === "normal"
      ) {
        return false;
      }
      return true;
    },
  },
  components: {
    "w-dropdown": Dropdown,
    "w-button": Button,
    "w-tree-dropdown": Treeselect,
  },
  methods: {
    createDataStream() {
      this.buttonLoader = "loader";
      var data = {
        name: this.dataStreamName,
        auth: this.selectedConnection.id,
        orgNumber: sessionStorage.getItem("subId"),
        createdBy: sessionStorage.getItem("clientId"),
        accounts: this.selectedAccounts,
        countries: ["b850c1bfd38f30e0"],
        metrics: this.metrics,
        dimensions: this.dimensions,
        granularity: "DAY",
        entities: this.entities,
        placement: this.placement,
        segmentation: "CITIES",
        report_type: 1,
        level: "TWEET",
      };
      this.$emit("createdataStream", { details: data, typeId: 463 });
    },
    hideWelcomePage() {
      this.$emit("hideWelcomePage");
    },
    selectAccounts(e) {
      this.selectedAccounts = e;
    },
    getAccounts() {
      dataConnection.getMccs(463, this.selectedConnection.id).then((res) => {
        for (var i = 0; i < res.results.length; i++) {
          if (
            this.accountList.find((obj) => obj.id === res.results[i].id) ===
            undefined
          ) {
            this.accountList.push({
              id: res.results[i].id,
              label: res.results[i].text,
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
::placeholder {
  color: #9aafd4;
  opacity: 1;
}
.cancel.button.update {
  background-color: transparent;
  color: #050505;
}
.cancel.button.update:hover {
  color: #fff !important;
}
:-ms-input-placeholder {
  color: #9aafd4;
}
::-ms-input-placeholder {
  color: #9aafd4;
}
input:focus {
  box-shadow: none;
  border: 1px solid #80bdff;
}
input {
  width: 378px;
  height: 60px;
  margin: 53px 1px 40px 30px;
  object-fit: contain;
  color: #9aafd4;
  background-color: #f2f5fa;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  box-shadow: none;
  border: 0;
}
</style>
