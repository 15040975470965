<template>
  <div class="Together-let-us-hel ProximaNovaRegular mt-0" style="height: auto">
    <b-form-input
      type="email"
      class="form-control input-control c-form-input m-0 mb-5 w-100"
      aria-describedby="emailHelp"
      placeholder="Data stream name"
      name="example-input-1"
      v-model="dataStreamName"
    ></b-form-input>
    <w-dropdown
      :options="channels"
      :labelText="'Channels'"
      class="mb-5 w-100"
      :selectedOption="selectedChannel"
    ></w-dropdown>
    <w-dropdown
      :options="connectionDropdown"
      :labelText="'Connection'"
      class="mb-5 w-100"
      :selectedOption="selectedConnection"
    ></w-dropdown>
    <SearchDropdown
      :labelText="'Account'"
      :optionList="accountList"
      :selectedOption="selectedAccounts"
      @getOption="selectAccounts"
    />
    <div class="w-100">
      <div class="row w-100 m-0 p-0">
        <div class="d-flex" style="justify-content: space-between;">
          <div class="ProximaNovaBold">Scheduling</div>
          <ToggleButton
            :value="scheduling"
            :sync="true"
            :color="{ checked: 'green', unchecked: 'darkred' }"
            @change="schedulingEnable($event)"
            :width="61"
            :height="33"
            :margin="2.5"
          />
        </div>
      </div>
    </div>
    <div class="my-5 w-100 content-center">
      <div>
        <w-button
          class="cancel mr-4 px-5"
          :buttonText="'Cancel'"
          :isDisable="false"
          :buttonLoader="'none'"
          style="font-size: medium;"
          @buttonClicked="hideWelcomePage"
        >
        </w-button>
      </div>
      <div>
        <w-button
          :buttonLoader="buttonLoader"
          :buttonText="'Add Fetching Details'"
          :isDisable="disableConnectors"
          style="font-size: medium;"
          :class="{ resetPasswordLink: disableConnectors }"
          @buttonClicked="createDataStream"
        >
        </w-button>
      </div>
    </div>
    <ConfirmationModal
      ref="confirmBox"
      @continue="confirm"
      @notConfirm="notConfirm"
    />
  </div>
</template>

<script>
import Dropdown from "@/widgets/Dropdown.vue";
import { DataConnection } from "../../services/DataConnectionService.js";
import Button from "@/components/Profile/Button.vue";
import SearchDropdown from "@/widgets/SearchDropdown.vue";
import ConfirmationModal from "@/components/Modal/ConfirmSchedulingModal.vue";
const dataConnection = new DataConnection();
export default {
  props: {
    channelData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataStreamName: "",
      buttonLoader: "normal",
      connectionDropdown: [],
      selectedConnection: {},
      selectedChannel: {},
      channels: [],
      accountList: [],
      selectedAccounts: {},
      fields: [
        "account_currency",
        "account_id",
        "account_name",
        "actions",
        "ad_click_actions",
        // "ad_id",
        "ad_impression_actions",
        // "adset_id",
        // "adset_name",
        "campaign_id",
        "campaign_name",
        "clicks",
        "conversions",
        "date_start",
        "impressions",
        "inline_link_clicks",
        "inline_post_engagement",
        "objective",
        "spend",
        "results_by_adverity",
        "account_business_id",
        "account_business_name",
      ],
      actionBreakdown: ["action_type", "action_video_type"],
      breakdown: [
        "country",
        // "region"
      ],
      selectedAccountName: [],
      scheduling: true,
    };
  },
  created() {
    this.dataStreamName = "";
    this.buttonLoader = "normal";
    this.connectionDropdown = [
      {
        text: this.channelData.ConnectionName,
        id: this.channelData.connectionId,
      },
    ];
    this.selectedConnection = this.connectionDropdown[0];
    this.channels = [
      { text: this.channelData.channel, id: this.channelData.channel },
    ];
    this.selectedChannel = this.channels[0];
    this.getAccounts();
  },
  computed: {
    disableConnectors() {
      if (
        this.dataStreamName !== "" &&
        Object.keys(this.selectedAccounts).length !== 0 &&
        this.buttonLoader === "normal"
      ) {
        return false;
      }
      return true;
    },
  },
  components: {
    ConfirmationModal,
    "w-dropdown": Dropdown,
    "w-button": Button,
    SearchDropdown,
  },
  methods: {
    schedulingEnable(e) {
      if (!e.value) {
        this.$refs.confirmBox.open({ title: "It will disbale the auto fetch" });
        return;
      }
      this.scheduling = e.value;
    },
    confirm() {
      this.scheduling = false;
      this.$refs.confirmBox.hideWelcomePage();
    },
    notConfirm() {
      this.scheduling = true;
      this.$refs.confirmBox.hideWelcomePage();
    },
    createDataStream() {
      this.buttonLoader = "loader";
      var data = {
        name: this.dataStreamName,
        auth: this.selectedConnection.id,
        orgNumber: sessionStorage.getItem("subId"),
        createdBy: sessionStorage.getItem("clientId"),
        keywords: [this.selectedAccounts.value],
        enableScheduling: this.scheduling,
      };
      this.$emit("createdataStream", { details: data, typeId: 185 });
    },
    hideWelcomePage() {
      this.$emit("hideWelcomePage");
    },
    selectAccounts(e) {
      this.selectedAccounts = e;
    },
    getAccounts() {
      dataConnection.getMccs(185, this.selectedConnection.id).then((res) => {
        for (var i = 0; i < res.length; i++) {
          this.accountList.push({ name: res[i].name, value: res[i].id });
        }
      });
    },
  },
};
</script>

<style scoped>
::placeholder {
  color: #9aafd4;
  opacity: 1;
}
.cancel.button.update {
  background-color: transparent;
  color: #050505;
}
.cancel.button.update:hover {
  color: #fff !important;
}
:-ms-input-placeholder {
  color: #9aafd4;
}
::-ms-input-placeholder {
  color: #9aafd4;
}
input:focus {
  box-shadow: none;
  border: 1px solid #80bdff;
}
input {
  width: 378px;
  height: 60px;
  margin: 53px 1px 40px 30px;
  object-fit: contain;
  color: #9aafd4;
  background-color: #f2f5fa;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  box-shadow: none;
  border: 0;
}
</style>
