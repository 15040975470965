<template>
  <div>
    <div class="col-12 row searcDropdown m-0 p-0">
      <div
        class="col-md-4 col-lg-4 p-0 d-flex multidropdownLabel"
        v-if="showLabel"
        style="align-items: center;font-size: medium;"
      >
        <span class="pl-3 w-100">{{ labelText }}</span>
        <div class="line"></div>
      </div>
      <div
        class="  col-sm-12 p-0"
        :class="[
          showLabel ? 'col-md-8' : 'col-md-12',
          showLabel ? 'col-lg-8' : 'col-lg-12',
        ]"
      >
        <Multiselect
          v-model="value"
          :options="optionList"
          :placeholder="placeHolder"
          label="name"
          track-by="name"
          :selectLabel="''"
          :deselectLabel="''"
          :selectedLabel="''"
          @select="selectOption"
          class="searchSingle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  props: {
    placeHolder: {
      type: String,
      default: "Select",
    },
    optionList: {
      type: Array,
      default: () => [],
    },
    labelText: {
      type: String,
      default: "Default",
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      value: null,
    };
  },
  methods: {
    selectOption(e) {
      this.value = e;
      this.$emit("getOption", e);
    },
  },
};
</script>
<style scoped>
.searcDropdown .multidropdownLabel {
  background: #eff5ff;
  color: #313846;
}
.line {
  height: 50%;
  border-right: 2px solid #c6ccd6;
}
</style>
<style>
.searcDropdown .multiselect__tags {
  background: #eff5ff;
  border: 0px solid !important;
  padding: 0px;
  height: 48px;
  display: flex;
  align-items: center;
}
.searcDropdown .multiselect__input,
.searcDropdown .multiselect__single {
  background: #eff5ff;
}
.searcDropdown .multiselect__option.multiselect__option--selected {
  background-color: #eff5ff !important;
  color: #222a37 !important;
  font-family: ProximaNovaBold;
}
.searcDropdown .multiselect__content-wrapper {
  border: 1px solid #222a37 !important;
  border-top: 0px solid !important;
}
.searcDropdown .multiselect--above .multiselect__content-wrapper {
  border: 1px solid #222a37 !important;
  border-bottom: 0px solid !important;
}
.searcDropdown {
  height: 48px;
}
.searcDropdown .multiselect__option--highlight {
  background-color: #eff5ff !important;
  color: #222a37 !important;
  font-family: ProximaNovaBold;
}
.searcDropdown .multiselect__placeholder {
  margin-bottom: 0px !important;
}
</style>
