<template>
  <div class="Together-let-us-hel ProximaNovaRegular mt-0" style="height: auto">
    <b-form-input
      type="email"
      class="form-control input-control c-form-input m-0 mb-5 w-100"
      aria-describedby="emailHelp"
      placeholder="Data stream name"
      name="example-input-1"
      v-model="dataStreamName"
    ></b-form-input>
    <w-dropdown
      :options="channels"
      :labelText="'Channels'"
      class="mb-5 w-100"
      :selectedOption="selectedChannel"
    ></w-dropdown>
    <w-dropdown
      :options="connectionDropdown"
      :labelText="'Connection'"
      class="mb-5 w-100"
      :selectedOption="selectedConnection"
    ></w-dropdown>
    <!--<b-form-input
      type="text"
      class="form-control input-control c-form-input m-0 w-100 mb-1"
      aria-describedby="emailHelp"
      placeholder="Patners Ids (Please enter correct Ids)"
      name="example-input-1"
      v-model="partnersId"
    ></b-form-input>
    <div class="help-text mb-5">
      Comma separated list of partner ids you wish to filter for. Please make
      sure you have access to all advertisers of a partner. Use Advertiser
      filter otherwise.
    </div>
    <b-form-input
      type="text"
      class="form-control input-control c-form-input m-0 w-100 mb-1"
      aria-describedby="emailHelp"
      placeholder="Advertisers Ids (Please enter correct Ids)"
      name="example-input-1"
      v-model="advertisersIds"
    ></b-form-input>
    <div class="help-text mb-5">
      Comma separated list of advertiser ids you wish to filter for.
    </div>-->
    <w-dropdown
      :options="partnerList"
      :labelText="'Partner Id'"
      class="mb-5 w-100"
      :selectedOption="selectedPartnerId"
      @input="selectPartners($event)"
    ></w-dropdown>
    <w-dropdown
      :options="advertiseList"
      :labelText="'Avertise Id'"
      class="mb-5 w-100"
      :dropdownAbove="true"
      :selectedOption="selectedAdvertisId"
      @input="selectAdvertiseId($event)"
    ></w-dropdown>
    <div class="w-100">
      <div class="row w-100 m-0 p-0">
        <div class="d-flex" style="justify-content: space-between;">
          <div class="ProximaNovaBold">Scheduling</div>
          <ToggleButton
            :value="scheduling"
            :sync="true"
            :color="{ checked: 'green', unchecked: 'darkred' }"
            @change="schedulingEnable($event)"
            :width="61"
            :height="33"
            :margin="2.5"
          />
        </div>
      </div>
    </div>
    <div class="my-5 w-100 content-center">
      <div>
        <w-button
          class="cancel mr-4 px-5"
          :buttonText="'Cancel'"
          :isDisable="false"
          :buttonLoader="'none'"
          style="font-size: medium;"
          @buttonClicked="hideWelcomePage"
        >
        </w-button>
      </div>
      <div>
        <w-button
          :buttonLoader="buttonLoader"
          :buttonText="'Add Fetching Details'"
          :isDisable="disableConnectors"
          style="font-size: medium;"
          :class="{ resetPasswordLink: disableConnectors }"
          @buttonClicked="createDataStream"
        >
        </w-button>
      </div>
    </div>
    <ConfirmationModal
      ref="confirmBox"
      @continue="confirm"
      @notConfirm="notConfirm"
    />
  </div>
</template>

<script>
import Dropdown from "@/widgets/Dropdown.vue";
import Button from "@/components/Profile/Button.vue";
import { DataConnection } from "@/services/DataConnectionService.js";
import ConfirmationModal from "@/components/Modal/ConfirmSchedulingModal.vue";
const dataConnection = new DataConnection();
export default {
  props: {
    channelData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      partnerList: [],
      advertiseList: [],
      selectedAdvertisId: {},
      selectedPartnerId: {},
      dataStreamName: "",
      buttonLoader: "normal",
      connectionDropdown: [],
      selectedConnection: {},
      selectedChannel: {},
      channels: [],
      partnersId: "",
      advertisersIds: "",
      scheduling: true,
      allData: [],
    };
  },
  created() {
    this.dataStreamName = "";
    this.buttonLoader = "normal";
    this.connectionDropdown = [
      {
        text: this.channelData.ConnectionName,
        id: this.channelData.connectionId,
      },
    ];
    this.selectedConnection = this.connectionDropdown[0];
    this.channels = [
      { text: this.channelData.channel, id: this.channelData.channel },
    ];
    this.selectedChannel = this.channels[0];
    this.getPartnerId();
  },
  computed: {
    disableConnectors() {
      if (
        this.dataStreamName !== "" &&
        this.buttonLoader === "normal" &&
        Object.keys(this.selectedAdvertisId).length !== 0 &&
        Object.keys(this.selectedPartnerId).length !== 0
      ) {
        return false;
      }
      return true;
    },
  },
  components: {
    "w-dropdown": Dropdown,
    "w-button": Button,
    ConfirmationModal,
  },
  methods: {
    createDataStream() {
      this.buttonLoader = "loader";
      var data = {
        name: this.dataStreamName,
        auth: this.selectedConnection.id,
        orgNumber: sessionStorage.getItem("subId"),
        createdBy: sessionStorage.getItem("clientId"),
        enableScheduling: this.scheduling,
        keywords: [this.selectedPartnerId.id, this.selectedAdvertisId.id],
      };
      this.$emit("createdataStream", { details: data, typeId: 256 });
    },
    schedulingEnable(e) {
      if (!e.value) {
        this.$refs.confirmBox.open({ title: "It will disbale the auto fetch" });
        return;
      }
      this.scheduling = e.value;
    },
    confirm() {
      this.scheduling = false;
      this.$refs.confirmBox.hideWelcomePage();
    },
    notConfirm() {
      this.scheduling = true;
      this.$refs.confirmBox.hideWelcomePage();
    },
    selectPartners(e) {
      if (e.id === this.selectedPartnerId.id) {
        return;
      }
      this.selectedPartnerId = e;
      var data = this.allData.find(
        (obj) => obj.id === this.selectedPartnerId.id
      ).advertisers;
      this.advertiseList = [];
      this.selectedAdvertisId = {};
      for (var i = 0; i < data.length; i++) {
        this.advertiseList.push({ text: data[i].name, id: data[i].id });
      }
    },
    selectAdvertiseId(e) {
      if (e.id === this.selectedAdvertisId.id) {
        return;
      }
      this.selectedAdvertisId = e;
    },
    getPartnerId() {
      this.partnerList = [];
      dataConnection.getMccs(256, this.selectedConnection.id).then((res) => {
        this.allData = res;
        for (var i = 0; i < res.length; i++) {
          this.partnerList.push({ text: res[i].name, id: res[i].id });
        }
      });
    },
    hideWelcomePage() {
      this.$emit("hideWelcomePage");
    },
  },
};
</script>

<style scoped>
::placeholder {
  color: #9aafd4;
  opacity: 1;
}
.cancel.button.update {
  background-color: transparent;
  color: #050505;
}
.cancel.button.update:hover {
  color: #fff !important;
}
:-ms-input-placeholder {
  color: #9aafd4;
}
::-ms-input-placeholder {
  color: #9aafd4;
}
input:focus {
  box-shadow: none;
  border: 1px solid #80bdff;
}
input {
  width: 378px;
  height: 60px;
  margin: 53px 1px 40px 30px;
  object-fit: contain;
  color: #9aafd4;
  background-color: #f2f5fa;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  box-shadow: none;
  border: 0;
}
</style>
