<template>
  <div
    class="Together-let-us-hel ProximaNovaRegular mt-0 "
    style="height: auto"
  >
    <b-form-input
      type="email"
      class="form-control input-control c-form-input m-0 mb-5 w-100"
      aria-describedby="emailHelp"
      placeholder="Data stream name"
      name="example-input-1"
      v-model="dataStreamName"
    ></b-form-input>
    <w-dropdown
      :options="channels"
      :labelText="'Channels'"
      class="mb-5 w-100"
      :selectedOption="selectedChannel"
    ></w-dropdown>
    <w-dropdown
      :options="connectionDropdown"
      :labelText="'Connection'"
      class="mb-5 w-100"
      :selectedOption="selectedConnection"
    ></w-dropdown>
    <!--<w-tree-dropdown
      :placeHolder="'Select Keyword'"
      :rootOptions="keywordList"
      :selectedValue="selectedKeyword"
      @selectedField="getSelectedKeywords($event)"
      @searchQuery="getAccounts($event)"
    ></w-tree-dropdown>-->

    <div class="w-100">
      <b-form-input
        type="text"
        class="form-control input-control c-form-input m-0 mb-2 w-100"
        aria-describedby="emailHelp"
        placeholder="Enter Keyword"
        name="example-input-1"
        v-model="keyWordName"
        @keydown.enter.prevent="newKeyWord"
      ></b-form-input>

      <div class="w-100">
        <app-email-grid
          :emails="keywordList"
          @emailDeleted="deleteEmail"
        ></app-email-grid>
      </div>
    </div>
    <div class="help-text" :class="keywordList.length !== 3 ? 'mb-1' : 'mb-5'">
      Type keyword and press Enter to select*
    </div>
    <div class="help-text mb-5" v-if="keywordList.length !== 3">
      Please select only 3 options*
    </div>
    <!--<w-search-dropdown
      :labelText="'Country'"
      :optionList="countryDropdown"
      :selectedOption="selectedCountry"
      @getOption="getSelectedCountry"
    >
    </w-search-dropdown>-->
    <div class="my-5 w-100 content-center">
      <div>
        <w-button
          class="cancel mr-4 px-5"
          :buttonText="'Cancel'"
          :isDisable="false"
          :buttonLoader="'none'"
          style="font-size: medium;"
          @buttonClicked="hideWelcomePage"
        >
        </w-button>
      </div>
      <div>
        <w-button
          :buttonLoader="buttonLoader"
          :buttonText="'Add Fetching Details'"
          :isDisable="disableConnectors"
          style="font-size: medium;"
          :class="{ resetPasswordLink: disableConnectors }"
          @buttonClicked="createDataStream"
        >
        </w-button>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "@/widgets/Dropdown.vue";
// import SearchDropdown from "@/widgets/SearchDropdown.vue";
// import Treeselect from "@/widgets/MultiSelectDropdown.vue";
// import { DataConnection } from "../../services/DataConnectionService.js";
import Button from "@/components/Profile/Button.vue";
import EmailGrid from "@/widgets/EmailGrid.vue";
// const dataConnection = new DataConnection();

export default {
  props: {
    channelData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataStreamName: "",
      keyWordName: "",
      buttonLoader: "normal",
      connectionDropdown: [],
      countryDropdown: [],
      selectedConnection: {},
      selectedChannel: {},
      channels: [],
      keywordList: [],
      selectedKeyword: [],
      selectedCountry: {},
    };
  },
  created() {
    this.dataStreamName = "";
    this.buttonLoader = "normal";
    this.connectionDropdown = [
      {
        text: this.channelData.ConnectionName,
        id: this.channelData.connectionId,
      },
    ];
    this.selectedConnection = this.connectionDropdown[0];
    this.channels = [
      { text: this.channelData.channel, id: this.channelData.channel },
    ];
    this.selectedChannel = this.channels[0];
    // this.getCountryList();
  },
  computed: {
    checkObj() {
      return (data) => {
        return Object.keys(data).length;
      };
    },
    disableConnectors() {
      if (
        this.dataStreamName !== "" &&
        this.buttonLoader === "normal" &&
        this.keywordList.length === 3 
        // this.checkObj(this.selectedCountry) !== 0
      ) {
        return false;
      }
      return true;
    },
  },
  components: {
    // "w-search-dropdown": SearchDropdown,
    appEmailGrid: EmailGrid,
    "w-dropdown": Dropdown,
    "w-button": Button,
    // "w-tree-dropdown": Treeselect,
  },
  methods: {
    newKeyWord() {
      if (this.keyWordName != "") {
        this.keywordList.push(this.keyWordName);
      }
      this.keyWordName = "";
    },
    deleteEmail(index) {
      console.log("delete");
      this.keywordList.splice(index, 1);
    },

    createDataStream() {
      this.buttonLoader = "loader";
      // var keywordId = this.keywordList.filter((obj) =>
      //   this.selectedKeyword.some((obj1) => obj.id === obj1)
      // );
      // var actualId = [];
      // for (var i = 0; i < keywordId.length; i++) {
      //   actualId.push(keywordId[i].actualId);
      // }
      var keywordlistIds = [];
      for (var i = 0; i < this.keywordList.length; i++) {
        var id = {
          mid:  this.keywordList[i], 
          title:  this.keywordList[i], 
          type:  "CUSTOM", 
        };
        var stringObject = JSON.stringify(id)
        var addSpace = stringObject.split(',').join(', ')
        var addmoreSpace = addSpace.split(':').join(': ')
        keywordlistIds.push(addmoreSpace)
      }
      var data = {
        name: this.dataStreamName,
        auth: this.selectedConnection.id,
        orgNumber: sessionStorage.getItem("subId"),
        createdBy: sessionStorage.getItem("clientId"),
        // keywords: actualId,
        keywords: this.keywordList,
        channelName:"Google Trends"
        // geo_filter_country: this.selectedCountry.value,
      };
      this.$emit("createdataStream", { details: data, typeId: 278 });
    },
    hideWelcomePage() {
      this.$emit("hideWelcomePage");
    },
    getSelectedKeywords(e) {
      this.selectedKeyword = e;
    },
    getSelectedCountry(e) {
      this.selectedCountry = e;
    },
    // getCountryList() {
    //   dataConnection
    //     .getYoutubeChannels(this.selectedConnection.id, "", "", 278)
    //     .then((res) => {
    //       this.countryDropdown = [];
    //       for (var i = 0; i < res.results.length; i++) {
    //         this.countryDropdown.push({
    //           value: res.results[i].id,
    //           name: res.results[i].text,
    //         });
    //       }
    //     });
    // },
    // getAccounts(keyword) {
    //   dataConnection
    //     .googleTrendsKeyword(keyword, this.selectedConnection.id, 278)
    //     .then((res) => {
    //       for (var i = 0; i < res.results.length; i++) {
    //         var id = JSON.parse(res.results[i].id);
    //         console.log(JSON.stringify(id));
    //         this.keywordList.push({
    //           id: keyword + " - " + res.results[i].text,
    //           label: res.results[i].text,
    //           actualId: JSON.stringify(id),
    //         });
    //       }
    //     });
    // },
  },
};
</script>

<style scoped>
::placeholder {
  color: #9aafd4;
  opacity: 1;
}
.cancel.button.update {
  background-color: transparent;
  color: #050505;
}
.cancel.button.update:hover {
  color: #fff !important;
}
:-ms-input-placeholder {
  color: #9aafd4;
}
::-ms-input-placeholder {
  color: #9aafd4;
}
input:focus {
  box-shadow: none;
  border: 1px solid #80bdff;
}
input {
  width: 378px;
  height: 60px;
  margin: 53px 1px 40px 30px;
  object-fit: contain;
  color: #9aafd4;
  background-color: #f2f5fa;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  box-shadow: none;
  border: 0;
}
</style>
