<template>
  <modal
    name="dataFetchModal"
    class="fetch--modal"
    @closed="hideWelcomePage"
    :clickToClose="false"
    :scrollable="true"
    :height="'auto'"
  >
    <div class="">
      <div class="w-100">
        <div class="row modal-padding">
          <div class="col p-0 welcome mt-4 align-start">
            <img src="../../assets/group-21.png" alt class="welcome--image" />
          </div>
          <div class="col p-0 intro__content">
            <p class="Welcome-to-Data-Poem ProximaNovaRegular">
              {{ this.modalTitle }}
            </p>

            <p
              class="Together-let-us-hel ProximaNovaRegular mt-0"
              style="height: auto"
            >
              <date-picker
                v-model="customDate"
                class="date-picker"
                ref="datePicker"
                format="MMM YYYY"
                range
                confirm
                confirm-text="Done"
              ></date-picker>
              <w-white-dropdown
                :options="options"
                :placeHolder="'Date'"
                :selectedOption="selectedFilter"
                :labelText="'Date'"
                @input="selectFilter($event)"
                width="178px"
              >
              </w-white-dropdown>
            </p>
            <div
              class="display-daterange"
              v-if="startDate !== '' && endDate !== ''"
            >
              Selected date :
              {{ startDate }} -
              {{ endDate }}
            </div>
            <p for="" class="mt-3 select-date-label">Select date to</p>
            <div class="w-100 year-plans">
              <div
                class="years-card card"
                :class="{ active: historicalDate === 'oneYear' }"
                @click="selectedPlan('oneYear')"
              >
                One Year
              </div>
              <div
                class="years-card card"
                :class="{ active: historicalDate === 'twoYear' }"
                @click="selectedPlan('twoYear')"
              >
                Two Year
              </div>
              <div
                class="years-card card"
                :class="{ active: historicalDate === 'threeYear' }"
                @click="selectedPlan('threeYear')"
              >
                Three Year
              </div>
            </div>
            <div class="content-center mt-5 w-100">
              <div>
                <w-button
                  class="cancel mr-4 px-5"
                  :buttonText="'Cancel'"
                  :isDisable="false"
                  :buttonLoader="'none'"
                  style="font-size: medium;"
                  @buttonClicked="hideWelcomePage"
                >
                </w-button>
              </div>
              <w-button
                :buttonLoader="buttonLoader"
                :buttonText="'Add Fetching Details'"
                :isDisable="disableConnectors"
                :class="{ resetPasswordLink: disableConnectors }"
                @buttonClicked="doneProcess"
              >
              </w-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import Button from "@/components/Profile/Button.vue";
import WhiteDropdown from "@/widgets/WhiteDropdown.vue";
import DatePicker from "vue2-datepicker";
import "@/assets/style/datepicker/index.css";
import { DataConnection } from "../../services/DataConnectionService.js";
const dataConnection = new DataConnection();
import moment from "moment";
export default {
  name: "dataFetchModal",
  props: {},
  data() {
    return {
      oneYear: false,
      twoYear: false,
      threeYear: false,
      isActive: 0,
      modalTitle: "",
      modalShow: false,
      selectedFilter: "",
      options: [
        { text: "Yesterday", id: "yesterday" },
        { text: "Today", id: "today" },
        { text: "Last 7 Days", id: "last7days" },
        { text: "Last 30 Days", id: "30Days" },
        { text: "This Month", id: "thisMonth" },
        { text: "Last Month", id: "lastMonth" },
        // { text: "Last Year", id: "lastYear" },
        // { text: "Custom Select", id: "customSelect" },
      ],
      startDate: "",
      endDate: "",
      customDate: [],
      historicalDate: "",
      historicalStartdate: "",
      historicalEndDate: "",
      dataStreamId: "",
      buttonLoader: "normal",
      fileID: "",
    };
  },
  components: {
    "w-button": Button,
    "w-white-dropdown": WhiteDropdown,
    DatePicker,
  },
  watch: {
    filterBy(newVal) {
      this.filterOn = [];
      this.filterOn.push(newVal.id);
    },
    customDate(newVal) {
      if (newVal && newVal.length) {
        this.startDate = moment(newVal[0]).format("YYYY-MM-DD");
        this.endDate = moment(newVal[1]).format("YYYY-MM-DD");
      }
    },
  },
  computed: {
    disableConnectors() {
      if (
        this.startDate !== "" &&
        this.endDate !== "" &&
        this.buttonLoader === "normal"
      ) {
        return false;
      } else if (this.historicalDate !== "" && this.buttonLoader === "normal") {
        return false;
      }
      return true;
    },
  },
  methods: {
    open(data) {
      this.$modal.show("dataFetchModal");
      this.startDate = "";
      this.endDate = "";
      this.historicalDate = "";
      this.historicalStartdate = "";
      this.historicalEndDate = "";
      this.modalTitle = data.title;
      this.dataStreamId = data.id;
      console.log(data)
      if (Object.keys(data).includes("file_id")) {
        this.fileID = data.file_id;
      } else {
        this.fileID = "";
      }
      this.buttonLoader = "normal";
    },
    hideWelcomePage() {
      this.$modal.hide("dataFetchModal");
    },
    doneProcess() {
      this.buttonLoader = "loader";
      var data = {
        dataStreamId: this.dataStreamId,
        duration: 0,
        clientId: sessionStorage.getItem("clientId"),
        // fetchType: this.selectedPattern,
        historicStartDate: this.historicalStartdate,
        historicEndDate: this.historicalEndDate,
        start: this.startDate || "",
        end: this.endDate || "",
      };
      if (this.fileID !== "") {
        data.file_id = this.fileID;
      }
      dataConnection.fetchData(data).then((res) => {
        console.log(res);
        this.buttonLoader = "normal";
        this.hideWelcomePage();
        this.$emit("fetchStatus");
      });
    },
    selectFilter(e) {
      this.customDate = [];
      this.selectedFilter = e;
      if (e && e.id === "customSelect") {
        this.$refs.datePicker.openPopup();
      } else if (e && e.id === "today") {
        this.startDate = moment(new Date()).format("YYYY-MM-DD");
        this.endDate = moment(new Date()).format("YYYY-MM-DD");
      } else if (e && e.id === "last7days") {
        this.startDate = moment(
          new Date().setDate(new Date().getDate() - 7)
        ).format("YYYY-MM-DD");
        this.endDate = moment(
          new Date().setDate(new Date().getDate() - 1)
        ).format("YYYY-MM-DD");
      } else if (e && e.id === "30Days") {
        this.endDate = moment(
          new Date().setDate(new Date().getDate() - 1)
        ).format("YYYY-MM-DD");
        this.startDate = moment(
          new Date().setDate(new Date().getDate() - 31)
        ).format("YYYY-MM-DD");
      } else if (e && e.id === "lastMonth") {
        let dateObj = new Date();
        this.startDate = moment(new Date(
          dateObj.getFullYear(),
          dateObj.getMonth() - 1,
          1
        )).format("YYYY-MM-DD");
        this.endDate = moment(new Date(dateObj.getFullYear(), dateObj.getMonth(), 0)).format("YYYY-MM-DD");
      } else if (e && e.id === "yesterday") {
        let dateObj = new Date();
        dateObj.setDate(dateObj.getDate() - 1);
        this.startDate = moment(dateObj).format("YYYY-MM-DD");
        this.endDate = moment(dateObj).format("YYYY-MM-DD");
      } else if (e && e.id === "lastWeek") {
        const date = new Date();
        const today = date.getDate();
        const dayOfTheWeek = date.getDay();
        date.setDate(today + 1 - dayOfTheWeek - 7);
        this.startDate = moment(date).format("YYYY-MM-DD");
        date.setDate(today - dayOfTheWeek);
        this.endDate = moment(date).format("YYYY-MM-DD");
      } else if (e && e.id === "thisMonth") {
        const date = new Date();
        let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
        this.startDate = moment(firstDate).format("YYYY-MM-DD");
        this.endDate = moment(date).format("YYYY-MM-DD");
      } else if (e && e.id === "lastYear") {
        const date = new Date();
        let firstDate = new Date(date.getFullYear() - 1, 0, 1);
        let lastDate = new Date(date.getFullYear() - 1, 11, 31);
        this.startDate = moment(firstDate).format("YYYY-MM-DD");
        this.endDate = moment(lastDate).format("YYYY-MM-DD");
      }
    },
    selectedPlan(plan) {
      var today = new Date();
      if (plan === this.historicalDate) {
        this.historicalDate = "";
        this.historicalStartdate = "";
        this.historicalEndDate = "";
        return;
      }
      this.historicalDate = plan;
      if (plan === "oneYear") {
        this.historicalStartdate = moment(
          new Date(new Date().setFullYear(new Date().getFullYear() - 1))
        ).format("YYYY-MM-DD");
        this.historicalEndDate = moment(today).format("YYYY-MM-DD");
      } else if (plan === "twoYear") {
        this.historicalStartdate = moment(
          new Date(new Date().setFullYear(new Date().getFullYear() - 2))
        ).format("YYYY-MM-DD");
        this.historicalEndDate = moment(today).format("YYYY-MM-DD");
      } else if (plan === "threeYear") {
        this.historicalStartdate = moment(
          new Date(new Date().setFullYear(new Date().getFullYear() - 3))
        ).format("YYYY-MM-DD");
        this.historicalEndDate = moment(today).format("YYYY-MM-DD");
      }
    },
  },
  created() {},
};
</script>
<style>
.fetch--modal .vm--modal {
  width: 75% !important;
  box-shadow: rgb(201 206 213) 0px 0px 8px 0px;
  margin: auto;
  display: block;
  top: 0 !important;
  left: auto !important;
}
.fetch--modal .vm--overlay {
  background: rgba(0, 0, 0, 0.9) !important;
}
</style>
<style lang="css" scoped>
.cancel.button.update {
  background-color: transparent;
  color: #050505;
}
.cancel.button.update:hover {
  color: #fff !important;
}
span.start-from-fresh.mt-4 {
  width: 100%;
  height: 22px;
  font-size: 18px;
  font-weight: 600;
  float: left;
  cursor: pointer;
}
::placeholder {
  color: #9aafd4;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #9aafd4;
}
::-ms-input-placeholder {
  color: #9aafd4;
}
input#exampleInputEmail1 {
  box-shadow: none;
  border: 0;
}
.btn-effect-intro {
  width: 78% !important;
  margin-top: 0 !important;
}
.start-from-fresh {
  width: 22% !important;
}
.add-fetching-detail-btn {
  width: 100%;
}
/* .date-picker {
  width: 0px;
  position: absolute;
  right: -12px;
} */
.date-picker {
  width: 150px;
  position: absolute;
  right: 12px;
}
.display-daterange {
  font-size: 16px;
  font-family: "ProximaNovaRegular";
}
>>> .dropdownWrapper {
  width: 100% !important;
}
.dropdownWrapper {
  background: #eff5ff;
}
>>> .selected {
  background-color: #eff5ff !important;
}
.select-date-label {
  color: #9aafd4;
  font-size: 20px;
  font-family: ProximaNovaRegular;
}
.years-card {
  color: #050505;
  width: 29%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 15px 10px;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-right: 10px;
  float: left;
  cursor: pointer;
  background-color: #e9f0ff;
  border-color: #e9f0ff;
}
>>> .custom-select {
  background: #eff5ff !important;
}
.years-card.card.active {
  background-color: #c8f0d1;
  border-color: #c8f0d1;
}
.add-fetching-detail-btn {
  margin-top: 75px !important;
  margin-left: 15px;
}
>>> .items {
  height: 166px !important;
  overflow-x: scroll;
}
.date-picker.mx-datepicker.mx-datepicker-range {
  visibility: hidden;
}

@media screen and (max-width: 640px) {
  .select-date-label {
    margin-left: 36px;
  }
  .year-plans {
    margin-left: 2rem;
    padding: 0 2rem;
  }
}
@media screen and (max-width: 768px) and (min-width: 640px) {
  .select-date-label {
    margin-left: 36px;
  }
  .year-plans {
    margin-left: 2rem;
    padding: 0 2rem;
  }
}
</style>
