<template>
  <div>
    <div class="page-wrapper chiller-theme" style="margin-top: 70px">
      <main class="page-content">
        <Toast
          v-if="toastData.show"
          :type="toastData.type"
          :message="toastData.message"
          :show="toastData.show"
          :time="10000"
          @clearToast="clearToast"
        />
        <div class="container-fluid">
          <ul class="breadcrumb ProximaNovaRegular" style="margin-top: 12px">
            <li>
              <span>Digital</span>
            </li>
            <li
              :class="activeFrom === 1 ? 'ProximaNovaBold' : ''"
              v-if="activeFrom === 1"
            >
              Platform
            </li>
            <li v-else>
              <span @click="activeFrom = 1">Platform</span>
            </li>
            <li
              :class="activeFrom === 2 ? 'ProximaNovaBold' : ''"
              v-if="activeFrom === 2"
            >
              Summary
            </li>
            <li v-else-if="activeFrom === 3">
              <span @click="activeFrom = 2">Summary</span>
            </li>
            <li
              :class="activeFrom === 3 ? 'ProximaNovaBold' : ''"
              v-if="activeFrom === 3"
            >
              DataStream Summary
            </li>
          </ul>
        </div>
        <div class="container mb-5" style="margin-top: 28px">
          <div
            class="
              col-sm-12 col-xs-12 col-md-12 col-lg-12
              d-flex
              justify-center
            "
          >
            <div class="col-sm-12 col-md-7 col-xs-12 col-lg-7">
              <w-multi-step-bar
                :stepName="stepName"
                :activeState="activeFrom"
                @changeStep="activeFrom = $event"
              ></w-multi-step-bar>
            </div>
          </div>
        </div>
        <div
          class="w-100 pt-5 d-flex pr-3"
          style="justify-content: end;"
          v-if="
            activeFrom === 2 &&
              totalConnectionLength !== 0 &&
              !showConnectionSummary
          "
        >
          <h6 class="Datastream-title">
            {{ totalConnectionLength }}
            <span
              style="text-decoration: underline;font-style: italic; cursor: pointer;"
              :style="showConnectionTable ? 'color: blue' : 'color: #313846'"
              @click="[(showConnectionTable = !showConnectionTable)]"
            >
              Connection</span
            >
            without DataStream
          </h6>
        </div>
        <div
          class="w-100 mt-4"
          style="font-size: small;text-align: end;color: #ff5a5a;"
          v-if="activeFrom === 2"
        >
          <span>{{ checkConnector }}</span>
        </div>
        <div
          class="w-100 pt-5 d-flex pr-3"
          style="justify-content: end;"
          v-if="activeFrom === 2"
        >
          <div class="w-100 d-flex" style="justify-content: end;">
            <w-button
              :buttonText="'Create new Connection'"
              class="mr-3"
              :buttonLoader="'none'"
              @buttonClicked="$refs.AllOverViewModal.open()"
            >
            </w-button>
            <!--<CreateCampaign class="mr-3" /> -->

            <w-button
              v-if="!showConnectionTable"
              :buttonText="'Create new Datastream'"
              class="mr-4"
              :buttonLoader="'none'"
              @buttonClicked="
                [(showConnectionSummary = !showConnectionSummary)]
              "
            >
            </w-button>
          </div>
        </div>
        <div
          class="container-fluid"
          style="margin-top: 80px; padding-left: 52px"
          v-if="activeFrom === 1"
        >
          <w-tab
            :tabName="activeTab"
            :tabs="tabs"
            @activeTab="changeTab($event)"
            class="mb-3"
            :connectorList="activeConnection"
            :extraTab="additionalTab"
          >
          </w-tab>
        </div>
        <div class="w-100" v-if="activeFrom === 1">
          <div
            class="container-fluid tab-panel"
            v-for="(tab, topIndex) in tabs"
            :key="topIndex"
            :class="{ showConnectors: activeTab === tab.category }"
          >
            <div
              class="card w-100"
              v-for="(tabContent, index) in tab.tabContent"
              :key="index"
            >
              <div
                class="card-header d-flex font-large ProximaNovaBold"
                @click="tabContent.expand = !tabContent.expand"
                style="
                  padding: 0.75rem 1.5rem;
                  background: transparent;
                  border: 0px !important;
                  cursor:pointer;
                  justify-content: space-between;
                "
              >
                {{ tabContent.title }}
                <!--<span
                  class="float-right view-all-link"
                  v-if="tabContent.connectors.length > 8"
                  @click="viewAll(tabContent.connectors, tabContent.title)"
                  >View All</span
                >-->
                <div>
                  <span style="font-size: 30px; cursor:pointer;"
                    ><i class="far fa-plus-square" v-if="!tabContent.expand"></i
                    ><i class="far fa-minus-square" v-else></i
                  ></span>
                </div>
              </div>
              <div class="card-body" :class="{ 'd-none': !tabContent.expand }">
                <div>
                  <w-connector
                    :addActiveClass="false"
                    :connectors="tabContent.connectors"
                    :title="tabContent.title"
                    :selectedConnectorsList="selectedConnectors"
                    @addPlatform="addPlatform($event)"
                    :sliceLength="false"
                  >
                  </w-connector>
                </div>
                <div
                  class="d-flex w-100 "
                  style="justify-content: end;align-items: center;"
                >
                  <span
                    class="view-all-link float-right mx-3"
                    v-if="tabContent.connectors.length > 8"
                    @click="viewAll(tabContent.connectors, tabContent.title)"
                    >View All</span
                  >
                  <div class="d-flex" v-if="index === 0">
                    <w-button
                      style="float: none"
                      :buttonText="'Next'"
                      @buttonClicked="Nexthandler(topIndex)"
                    >
                    </w-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <w-scenarioplanning
            v-if="activeTab === 'Additional Details'"
          ></w-scenarioplanning>
          <div
            class="row pt-5 pb-5 mr-2"
            style="float: right"
            v-if="activeTab === 'Additional Details'"
          >
            <w-button
              :buttonLoader="'normal'"
              :buttonText="'Continue'"
              @buttonClicked="activeFrom = 2"
            >
            </w-button>
          </div>
        </div>
        <div class="w-100" v-if="activeFrom === 2 && showConnectionTable">
          <div class="container-fluid tab-panel showConnectors">
            <OmniChannel
              :items="healthData"
              @createDataStreamWithout="createDataStream($event)"
              @totalConnection="totalConnection($event)"
            />
          </div>
        </div>
        <div class="w-100" v-if="activeFrom === 2 && showConnectionSummary">
          <div class="container-fluid tab-panel showConnectors">
            <OmniChannel
              :items="healthData"
              :showConnectionSummary="showConnectionSummary"
              @createDataStreamWithout="createDataStream($event)"
            />
          </div>
          <div class="row pt-5 pb-5 mr-2" style="float: right">
            <w-button
              :buttonLoader="'normal'"
              :buttonText="'Continue'"
              @buttonClicked="showConnectionSummary = false"
            >
            </w-button>
          </div>
        </div>
        <div
          class="w-100 py-4"
          v-if="
            activeFrom === 2 && !showConnectionTable && !showConnectionSummary
          "
        >
          <div class="container-fluid">
            <DataStreamSummary :items="DataStreamData" />
          </div>
        </div>

        <div
          class="container-fluid w-100"
          v-if="activeFrom < 3 && showConnectionTable"
        >
          <div
            class="w-100"
            style="font-size: small;text-align: end;color: #ff5a5a;"
          >
            <span>{{ checkConnector }}</span>
          </div>
          <div class="row pt-5 pb-5 mr-2" style="float: right">
            <w-button
              :buttonLoader="'normal'"
              :buttonText="'Continue'"
              @buttonClicked="showConnectionTable = false"
            >
            </w-button>
          </div>
        </div>
        <connection-modal
          ref="connectionModal"
          @createConnection="createConnection($event)"
        />
        <authorized-modal
          ref="authorizedModal"
          @authorizedDone="authoriseConnection"
        />
        <overview-modal
          :tabs="tabs"
          :selectedConnectorsForViewModal="selectedConnectorsForViewModal"
          :title="selectedTitleForViewModal"
          :activeTab="activeTab"
          :selectedConnectors="selectedConnectors"
          ref="overviewModal"
          @overviewDone="overViewCards"
        />
        <w-create-new-connection
          :selectedConnectors="selectedConnectors"
          :selectedConnectorsForViewModal="allConnectorList"
          :title="'All Connectors'"
          ref="AllOverViewModal"
          @createNewConnction="createNewConnction"
        />
        <dataStream-modal
          ref="dataStreamModal"
          @createStreamDetails="createStreamDetails($event)"
        />
        <ConfirmModal
          ref="confirmationmodal"
          @continue="[(activeFrom = 2), getConnectionWithoutDataStream()]"
        />
        <PageLoader v-if="count < 3" />
        <dataFetch-modal ref="dataFetchModalRef" @fetchStatus="processDone" />
      </main>
    </div>
  </div>
</template>
<script>
import Connector from "@/components/AIMO-AI/Connector.vue";
import MultiStepProgressBar from "@/components/Solutions/MultiStepProgressBar.vue";
import Button from "@/components/Profile/Button.vue";
import Tab from "@/components/Solutions/NewTab.vue";
// import Dropdown from "@/widgets/Dropdown.vue";
import ConnectionModal from "@/components/Modal/ConnectionModal.vue";
import AuthorizedModal from "@/components/Modal/AuthorizedModal.vue";
import OverviewModal from "@/components/Modal/OverviewModal.vue";
import OmniChannel from "@/pages/DataConnectionDS/OmniChannel.vue";
import DataStreamSummary from "@/pages/DataConnection/DataStreamSummary.vue";
import { DataConnection } from "../../services/DataConnectionService.js";
import { ApiHelper } from "@/services/helper/ApiHelper.js";
import Toast from "@/components/Toast/Toast.vue";
import AdditionalDetails from "@/pages/Scenario/AdditionalDetails.vue";
import DataStreamModal from "@/components/Modal/DataStreamModal.vue";
import DataFetchModal from "@/components/Modal/DataFetchModal.vue";
import ConfirmModal from "@/components/Modal/ConfirmationModal.vue";
import CreateNewConnection from "@/components/Modal/AllOverViewModal.vue";
import PageLoader from "@/widgets/PageLoader.vue";
// import CreateCampaign from "@/widgets/CreateCampaign.vue";
let apiHelper = new ApiHelper();
import moment from "moment";
const dataConnection = new DataConnection();
export default {
  props: [],
  components: {
    "w-connector": Connector,
    "w-create-new-connection": CreateNewConnection,
    PageLoader,
    "w-multi-step-bar": MultiStepProgressBar,
    "w-scenarioplanning": AdditionalDetails,
    "w-button": Button,
    "w-tab": Tab,
    ConfirmModal,
    Toast,
    // "w-dropdown": Dropdown,
    "authorized-modal": AuthorizedModal,
    "connection-modal": ConnectionModal,
    "overview-modal": OverviewModal,
    "dataStream-modal": DataStreamModal,
    "dataFetch-modal": DataFetchModal,
    // CreateCampaign,
    OmniChannel,
    DataStreamSummary,
  },
  data() {
    return {
      count: 0,
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      showConnectionTable: false,
      showConnectionSummary: false,
      additionalTab: [{ category: "Additional Details" }],
      connectionSummaryData: {},
      healthData: [],
      DataStreamData: [],
      modalTitle: "",
      isWelcomeModal: true,
      selectedMarket: "",
      selectedKPI: "",
      activeTab: "Digital Media*",
      activeFrom: 1,
      orderSubCategory: [],
      selectedConnectors: [],
      categorizedSelectedConnectors: [],
      stepName: [
        "Platforms",
        //   "Connection Summary",
        "Summary",
      ],
      selectedConnectorsForViewModal: [],
      selectedTitleForViewModal: "",
      allConnectorList: [
        {
          image: "google",
          name: "Google Ads",
          connectionId: 250,
          role: "DM Google Ads",
        },
        {
          name: "Facebook Ads",
          image: "facebook",
          role: "DM Facebook",
          connectionId: 208,
        },
        {
          name: "LinkedIn Ads",
          image: "linkedin",
          role: "DM LinkedIn",
          connectionId: 315,
        },
        {
          name: "Twitter Ads",
          image: "twitter",
          role: "DM Twitter",
          connectionId: 462,
        },
        {
          name: "Display and Video 360",
          image: "dv360",
          role: "DM DV360",
          connectionId: 255,
        },
        {
          name: "Microsoft Ads",
          image: "microsoft",
          role: "DM Microsoft Ads",
          connectionId: 349,
        },
        {
          name: "Pinterest",
          image: "Pinterest",
          role: "DM Pinterest",
          connectionId: 380,
        },
        {
          name: "Snapchat",
          image: "Snapchat",
          role: "DM Snapchat",
          connectionId: 424,
        },
        {
          name: "Google Sheets",
          image: "Google_Sheets",
          role: "DM google sheets",
          connectionId: 267,
        },

        {
          name: "360.sn",
          image: "360.sn",
          role: "Search Advertising 360.sn",
          connectionId: null,
        },
        {
          name: "Amazon Advertising",
          image: "Amazon",
          role: "Search Advertising Amazon Advertising",
          connectionId: null,
        },
        {
          name: "Apple Search Ads",
          image: "Apple Search Ads",
          role: "Search Advertising Apple Search Ads",
          connectionId: null,
        },
        {
          name: "Googel Adwords",
          image: "google-ad",
          role: "Search Advertising Googel Adwords",
          connectionId: null,
        },
        {
          name: "Google Ads New",
          image: "google-ad",
          role: "Search Advertising Google Ads New",
          connectionId: null,
        },
        {
          name: "Google Keyword Planner",
          image: "google-ad",
          role: "Search Advertising Google Keyword Planner",
          connectionId: null,
        },
        {
          name: "Google Search ads 360",
          image: "Google Search ads 360",
          role: "Search Advertising Google Search ads 360",
          connectionId: null,
        },
        {
          name: "indeed sponsored jobs",
          image: "indeed sponsored jobs",
          role: "Search Advertising indeed sponsored jobs",
          connectionId: null,
        },
        {
          name: "Microsoft Ads",
          image: "MicrosoftAds",
          role: "Search Advertising Microsoft Ads",
          connectionId: null,
        },
        {
          name: "The Search Monitor",
          image: "The Search Monitor",
          role: "Search Advertising The Search Monitor",
          connectionId: null,
        },
        {
          name: "Yabdex",
          image: "Yabdex",
          role: "Search Advertising Yabdex",
          connectionId: null,
        },
        {
          name: "Yahoo Japan Ads",
          image: "Yahoo Japan Ads",
          role: "Search Advertising Yahoo Japan Ads",
          connectionId: null,
        },
        {
          name: "Yello Pages",
          image: "Yello Pages",
          role: "Search Advertising Yello Pages",
          connectionId: null,
        },
        {
          name: "Yelp Ads",
          image: "Yelp Ads",
          role: "Search Advertising Yelp Ads",
          connectionId: null,
        },

        {
          name: "Google Analytics",
          image: "google-anal",
          role: "DM google-anal",
          connectionId: 270,
        },

        {
          name: "Google Sheets",
          image: "Google_Sheets",
          role: "WA google sheets",
          connectionId: 267,
        },
        {
          name: "Adobe Analytics (Service)",
          image: "adobe-anal",
          role: "WEB ANAlytics Adobe Analytics (Service)",
          connectionId: null,
        },
        {
          name: "Adobe Analytics",
          image: "adobe-anal",
          role: "WEB ANAlytics Adobe Analytics",
          connectionId: null,
        },
        {
          name: "Adobe Analytics(WSSE)",
          image: "adobe-anal",
          role: "WEB ANAlytics Adobe Analytics(WSSE)",
          connectionId: null,
        },
        {
          name: "Convert",
          image: "Convert",
          role: "WEB ANAlytics Convert",
          connectionId: null,
        },
        {
          name: "Etracker",
          image: "Etracker",
          role: "WEB ANAlytics Etracker",
          connectionId: null,
        },
        {
          name: "Google Analytics(Oauth)",
          image: "Google Analytics(Oauth)",
          role: "WEB ANAlytics Google Analytics(Oauth)",
          connectionId: null,
        },
        {
          name: "Google Analytics(Service)",
          image: "Google Analytics(Oauth)",
          role: "WEB ANAlytics Google Analytics(Service)",
          connectionId: null,
        },
        {
          name: "IBM Analytics",
          image: "IBM Analytics",
          role: "WEB ANAlytics IBM Analytics",
          connectionId: null,
        },
        {
          name: "Magnite Performance",
          image: "Magnite Performance",
          role: "WEB ANAlytics Magnite Performance",
          connectionId: null,
        },
        {
          name: "Mixpanel",
          image: "Mixpanel",
          role: "WEB ANAlytics Mixpanel",
          connectionId: null,
        },
        {
          name: "Optimizely",
          image: "Optimizely",
          role: "WEB ANAlytics Optimizely",
          connectionId: null,
        },
        {
          name: "Piwik Pro",
          image: "Piwik Pro",
          role: "WEB ANAlytics Piwik Pro",
          connectionId: null,
        },
        {
          name: "Similar Web",
          image: "Similar Web",
          role: "WEB ANAlytics Similar Web",
          connectionId: null,
        },
        {
          name: "Site Impact",
          image: "Site Impact",
          role: "WEB ANAlytics Site Impact",
          connectionId: null,
        },
        {
          name: "Webtrekk",
          image: "Webtrekk",
          role: "WEB ANAlytics Webtrekk",
          connectionId: null,
        },
        {
          name: "Webtrends",
          image: "Webtrends",
          role: "WEB ANAlytics Webtrends",
          connectionId: null,
        },

        {
          name: "Facebook Insights",
          image: "facebook",
          role: "DM Facebook Page Insights",
          connectionId: 690,
        },
        {
          name: "Instagram Insights",
          image: "instagram",
          role: "DM Instagram Insights",
          connectionId: 214,
        },
        {
          name: "YouTube Insights",
          image: "youtube",
          role: "DM Youtube Insights",
          connectionId: 284,
        },
        {
          name: "LinkedIn Insights",
          image: "linkedin",
          role: "DM linkedin insights",
          connectionId: 319,
        },
        {
          name: "Twitter Insights",
          image: "twitter",
          role: "DM Twitter Insights",
          connectionId: 462,
        },
        {
          name: "Google Ads Keyword Planner",
          image: "google",
          role: "DM Google Ads Keyword Planner",
          connectionId: 741,
        },
        {
          name: "Google Sheets",
          image: "Google_Sheets",
          role: "DM google sheets",
          connectionId: 267,
        },

        {
          name: "Call Gear",
          image: "Call Gear",
          role: "Call Analytics Call Gear",
          connectionId: null,
        },
        {
          name: "Call Rail",
          image: "Call Rail",
          role: "Call Analytics Call Rail",
          connectionId: null,
        },
        {
          name: "Marchex",
          image: "Marchex",
          role: "Call Analytics Marchex",
          connectionId: null,
        },
        {
          name: "Response Tab",
          image: "Response Tab",
          role: "Call Analytics Response Tab",
          connectionId: null,
        },

        {
          name: "Adalyser",
          image: "Adalyser",
          role: "Social Analytics Adalyser",
          connectionId: null,
        },
        {
          name: "Awario",
          image: "Awario",
          role: "Social Analytics Awario",
          connectionId: null,
        },
        {
          name: "Brandwatch",
          image: "brandWatch",
          role: "Social Analytics Brandwatch",
          connectionId: null,
        },
        {
          name: "Facebook Page Insights",
          image: "Facebook",
          role: "Social Analytics Facebook Page Insights",
          connectionId: null,
        },
        {
          name: "Google My Business",
          image: "Google My Business",
          role: "Social Analytics Google My Business",
          connectionId: null,
        },
        {
          name: "Instagram Basic Dislplay",
          image: "Instagram",
          role: "Social Analytics Instagram Basic Dislplay",
          connectionId: null,
        },
        {
          name: "Instagram Business",
          image: "Instagram Business",
          role: "Social Analytics Instagram Business",
          connectionId: null,
        },
        {
          name: "Keyhole",
          image: "keyHole",
          role: "Social Analytics Keyhole",
          connectionId: null,
        },
        {
          name: "Linkedin organisations and brands",
          image: "LinkedIn",
          role: "Social Analytics Linkedin organisations and brands",
          connectionId: null,
        },
        {
          name: "Linkfluence Radarly",
          image: "Linkfluence Radarly",
          role: "Social Analytics Linkfluence Radarly",
          connectionId: null,
        },
        {
          name: "netbase",
          image: "netbase",
          role: "Social Analytics netbase",
          connectionId: null,
        },
        {
          name: "Socialbakers",
          image: "Socialbakers",
          role: "Social Analytics Socialbakers",
          connectionId: null,
        },
        {
          name: "Sprinklr",
          image: "Sprinklr",
          role: "Social Analytics Sprinklr",
          connectionId: null,
        },
        {
          name: "Sprinklr Prod2",
          image: "Sprinklr",
          role: "Social Analytics Sprinklr Prod2",
          connectionId: null,
        },
        {
          name: "Synthesio",
          image: "Synthesio",
          role: "Social Analytics Synthesio",
          connectionId: null,
        },
        {
          name: "Talkwalker",
          image: "Talkwalker",
          role: "Social Analytics Talkwalker",
          connectionId: null,
        },
        {
          name: "Twitch",
          image: "Twitch",
          role: "Social Analytics Twitch",
          connectionId: null,
        },
        {
          name: "Unmetric",
          image: "Unmetric",
          role: "Social Analytics Unmetric",
          connectionId: null,
        },
        {
          name: "Youtube Analytics",
          image: "youtube",
          role: "Social Analytics Youtube Analytics",
          connectionId: null,
        },
        {
          name: "Youtube public communications",
          image: "youtube",
          role: "Social Analytics Youtube public communications",
          connectionId: null,
        },
        {
          name: "Youtube Public",
          image: "youtube",
          role: "Social Analytics Youtube Public",
          connectionId: null,
        },

        {
          name: "Adjust",
          image: "Adjust",
          role: "Mobile Adjust",
          connectionId: null,
        },
        {
          name: "APP Annie",
          image: "APP Annie",
          role: "Mobile APP Annie",
          connectionId: null,
        },
        {
          name: "App Follow",
          image: "App Follow",
          role: "Mobile App Follow",
          connectionId: null,
        },
        {
          name: "App Figures",
          image: "appfigures",
          role: "Mobile appfigures",
          connectionId: null,
        },
        {
          name: "AppLovin",
          image: "AppLovin",
          role: "Mobile AppLovin",
          connectionId: null,
        },
        {
          name: "Apps Flyer",
          image: "appsFlyer",
          role: "Mobile Apps Flyer",
          connectionId: null,
        },
        {
          name: "apptweak",
          image: "apptweak",
          role: "Mobile apptweak",
          connectionId: null,
        },
        {
          name: "Branch Querry API",
          image: "Branch Querry API",
          role: "Mobile Branch Querry API",
          connectionId: null,
        },
        {
          name: "Branch",
          image: "Branch Querry API",
          role: "Mobile Branch",
          connectionId: null,
        },
        {
          name: "Fyber",
          image: "Fyber",
          role: "Mobile Fyber",
          connectionId: null,
        },
        {
          name: "Iron Source",
          image: "Iron Source",
          role: "Mobile Iron Source",
          connectionId: null,
        },
        {
          name: "Kochava",
          image: "Kochava",
          role: "Mobile Kochava",
          connectionId: null,
        },
        {
          name: "NativeX",
          image: "NativeX",
          role: "Mobile NativeX",
          connectionId: null,
        },
        {
          name: "Priori data",
          image: "Priori data",
          role: "Mobile Priori data",
          connectionId: null,
        },
        {
          name: "SPlicky",
          image: "SPlicky",
          role: "Mobile SPlicky",
          connectionId: null,
        },
        {
          name: "tune-branch",
          image: "tune-branch",
          role: "Mobile tune-branch",
          connectionId: null,
        },
        {
          name: "Uberall",
          image: "Uberall",
          role: "Mobile Uberall",
          connectionId: null,
        },

        {
          name: "Active Campaign",
          image: "Active Campaign",
          role: "CRM Active Campaign",
          connectionId: null,
        },
        {
          name: "Braze",
          image: "Braze",
          role: "CRM Braze",
          connectionId: null,
        },
        {
          name: "Campaign Monitor",
          image: "Campaign Monitor",
          role: "CRM Campaign Monitor",
          connectionId: null,
        },
        {
          name: "Cordial",
          image: "Cordial",
          role: "CRM Cordial",
          connectionId: null,
        },
        {
          name: "Emarsys",
          image: "Emarsys",
          role: "CRM Emarsys",
          connectionId: null,
        },
        {
          name: "Evalanche",
          image: "Evalanche",
          role: "CRM Evalanche",
          connectionId: null,
        },
        {
          name: "Hubspot Destination",
          image: "Hubspot Destination",
          role: "CRM Hubspot Destination",
          connectionId: null,
        },
        {
          name: "Hubspot",
          image: "Hubspot Destination",
          role: "CRM Hubspot",
          connectionId: null,
        },
        {
          name: "Iterable",
          image: "Iterable",
          role: "CRM Iterable",
          connectionId: null,
        },
        {
          name: "Klaviyo",
          image: "Klaviyo",
          role: "CRM Klaviyo",
          connectionId: null,
        },
        {
          name: "Mailchimp",
          image: "Mailchimp",
          role: "CRM Mailchimp",
          connectionId: null,
        },
        {
          name: "Mailing Work",
          image: "Mailing Work",
          role: "CRM Mailing Work",
          connectionId: null,
        },
        {
          name: "Mailworx",
          image: "Mailworx",
          role: "CRM Mailworx",
          connectionId: null,
        },
        {
          name: "Marketo",
          image: "Marketo",
          role: "CRM Marketo",
          connectionId: null,
        },
        {
          name: "Microsoft Dynamics",
          image: "Microsoft Dynamics",
          role: "CRM Microsoft Dynamics",
          connectionId: null,
        },
        {
          name: "Piano",
          image: "Piano",
          role: "CRM Piano",
          connectionId: null,
        },
        {
          name: "Salesforce Marketing Cloud",
          image: "salesforce",
          role: "CRM Salesforce Marketing Cloud",
          connectionId: null,
        },
        {
          name: "Salesforce",
          image: "salesforce",
          role: "CRM Salesforce",
          connectionId: null,
        },
        {
          name: "Sendinblue",
          image: "Sendinblue",
          role: "CRM Sendinblue",
          connectionId: null,
        },
        {
          name: "Sharpspring",
          image: "Sharpspring",
          role: "CRM Sharpspring",
          connectionId: null,
        },
        {
          name: "Teamleader",
          image: "Teamleader",
          role: "CRM Teamleader",
          connectionId: null,
        },
        {
          name: "Zendesk Sell",
          image: "Zendesk Sell",
          role: "CRM Zendesk Sell",
          connectionId: null,
        },
        {
          name: "Google Trends",
          image: "Google Trends",
          role: "DM Google Trends",
          connectionId: 791,
        },

        {
          name: "Amazon Marketplace",
          image: "Amazon",
          role: "Commerce Amazon Marketplace",
          connectionId: null,
        },
        {
          name: "Amazon Selling Partner",
          image: "Amazon",
          role: "Commerce Amazon Selling Partner",
          connectionId: null,
        },
        {
          name: "Channel Pilot",
          image: "Channel Pilot",
          role: "Commerce Channel Pilot",
          connectionId: null,
        },
        {
          name: "Dataweave",
          image: "dataweave",
          role: "Commerce dataweave",
          connectionId: null,
        },
        {
          name: "Digistore24",
          image: "Digistore24",
          role: "Commerce Digistore24",
          connectionId: null,
        },
        {
          name: "Ebay",
          image: "Ebay",
          role: "Commerce Ebay",
          connectionId: null,
        },
        {
          name: "Feed Dynamix",
          image: "Feed Dynamix",
          role: "Commerce Feed Dynamix",
          connectionId: null,
        },
        {
          name: "Google Content for Shopping",
          image: "Google Content for Shopping",
          role: "Commerce Google Content for Shopping",
          connectionId: null,
        },
        {
          name: "Magneto",
          image: "Magneto",
          role: "Commerce Magneto",
          connectionId: null,
        },
        {
          name: "Shopalyst",
          image: "Shopalyst",
          role: "Commerce Shopalyst",
          connectionId: null,
        },
        {
          name: "Shopee",
          image: "Shopee",
          role: "Commerce Shopee",
          connectionId: null,
        },
        {
          name: "Shopify",
          image: "Shopify",
          role: "Commerce Shopify",
          connectionId: null,
        },
        {
          name: "Tweakers",
          image: "Tweakers",
          role: "Commerce Tweakers",
          connectionId: null,
        },
        {
          name: "Woo commerce",
          image: "Woo commerce",
          role: "Commerce Woo commerce",
          connectionId: null,
        },

        {
          name: "Azure Synapse",
          image: "Azure Synapse",
          role: "Data Base Azure Synapse",
          connectionId: null,
        },
        {
          name: "Microsoft SQL Server",
          image: "Microsoft SQL Server",
          role: "Data Base Microsoft SQL Server",
          connectionId: null,
        },
        {
          name: "Mongo DB",
          image: "Mongo DB",
          role: "Data Base Mongo DB",
          connectionId: null,
        },
        {
          name: "My SQL",
          image: "My SQL",
          role: "Data Base My SQL",
          connectionId: null,
        },
        {
          name: "Oracle",
          image: "Oracle",
          role: "Data Base Oracle",
          connectionId: null,
        },
        {
          name: "Postgre SQl",
          image: "Postgre SQl",
          role: "Data Base Postgre SQl",
          connectionId: null,
        },
        {
          name: "Snowflake",
          image: "Snowflake",
          role: "Data Base Snowflake",
          connectionId: null,
        },

        {
          name: "Amazon Athena",
          image: "Amazon Athena",
          role: "Data Lake Amazon Athena",
          connectionId: null,
        },
        {
          name: "Box",
          image: "Box",
          role: "Data Lake Box",
          connectionId: null,
        },
        {
          name: "Databricks",
          image: "Databricks",
          role: "Data Lake Databricks",
          connectionId: null,
        },
        {
          name: "Dropbox",
          image: "Dropbox",
          role: "Data Lake Dropbox",
          connectionId: null,
        },
        {
          name: "Egnyte EU",
          image: "Egnyte EU",
          role: "Data Lake Egnyte EU",
          connectionId: null,
        },
        {
          name: "Egnyte US",
          image: "Egnyte EU",
          role: "Data Lake Egnyte US",
          connectionId: null,
        },
        {
          name: "File Azure SAS",
          image: "File Azure SAS",
          role: "Data Lake File Azure SAS",
          connectionId: null,
        },
        {
          name: "File Azure",
          image: "File Azure",
          role: "Data Lake File Azure",
          connectionId: null,
        },
        {
          name: "File one Drive",
          image: "File one Drive",
          role: "Data Lake File one Drive",
          connectionId: null,
        },
        {
          name: "File",
          image: "File",
          role: "Data Lake File",
          connectionId: null,
        },
        {
          name: "Google Drive",
          image: "google-drive",
          role: "Data Lake Google Drive",
          connectionId: null,
        },
        {
          name: "Google Sheets",
          image: "Google_Sheets",
          role: "Data Lake Google Sheets",
          connectionId: null,
        },
        {
          name: "Hadoop",
          image: "Hadoop",
          role: "Data Lake Hadoop",
          connectionId: null,
        },
        {
          name: "S3 Connection",
          image: "S3 Connection",
          role: "Data Lake S3 Connection",
          connectionId: null,
        },
        {
          name: "SFTP Public Key",
          image: "File",
          role: "Data Lake SFTP Public Key",
          connectionId: null,
        },
        {
          name: "Sharepoint Online",
          image: "Sharepoint Online",
          role: "Data Lake Sharepoint Online",
          connectionId: null,
        },
        {
          name: "WebDAV",
          image: "File",
          role: "Data Lake WebDAV",
          connectionId: null,
        },

        {
          name: "Adform DMP OAuth2",
          image: "Adform DMP OAuth2",
          role: "DMP Adform DMP OAuth2",
          connectionId: null,
        },
        {
          name: "Adobe Experience PLatform",
          image: "Adobe Experience PLatform",
          role: "DMP Adobe Experience PLatform",
          connectionId: null,
        },
        {
          name: "Audience Studio",
          image: "Audience Studio",
          role: "DMP Audience Studio",
          connectionId: null,
        },

        {
          name: "Google Big Query (Oauth)",
          image: "genericGoogle",
          role: "Generic Google Big Query (Oauth)",
          connectionId: null,
        },
        {
          name: "Google BigQuery (Service)",
          image: "genericGoogle",
          role: "Generic Google BigQuery (Service)",
          connectionId: null,
        },
        {
          name: "Google Cloud Storage",
          image: "Google Cloud Storage",
          role: "Generic Google Cloud Storage",
          connectionId: null,
        },
        {
          name: "Microsoft SQL Server",
          image: "Microsoft SQL Server",
          role: "Generic Microsoft SQL Server",
          connectionId: null,
        },

        {
          name: "Google Analytics",
          image: "google-anal",
          role: "DM google-anal",
          connectionId: 270,
        },

        {
          name: "Google Sheets",
          image: "Google_Sheets",
          role: "WA google sheets",
          connectionId: 267,
        },
        {
          name: "Adobe Analytics (Service)",
          image: "adobe-anal",
          role: "WEB ANAlytics Adobe Analytics (Service)",
          connectionId: null,
        },
        {
          name: "Adobe Analytics",
          image: "adobe-anal",
          role: "WEB ANAlytics Adobe Analytics",
          connectionId: null,
        },
        {
          name: "Adobe Analytics(WSSE)",
          image: "adobe-anal",
          role: "WEB ANAlytics Adobe Analytics(WSSE)",
          connectionId: null,
        },
        {
          name: "Convert",
          image: "Convert",
          role: "WEB ANAlytics Convert",
          connectionId: null,
        },
        {
          name: "Etracker",
          image: "Etracker",
          role: "WEB ANAlytics Etracker",
          connectionId: null,
        },
        {
          name: "Google Analytics(Oauth)",
          image: "Google Analytics(Oauth)",
          role: "WEB ANAlytics Google Analytics(Oauth)",
          connectionId: null,
        },
        {
          name: "Google Analytics(Service)",
          image: "Google Analytics(Oauth)",
          role: "WEB ANAlytics Google Analytics(Service)",
          connectionId: null,
        },
        {
          name: "IBM Analytics",
          image: "IBM Analytics",
          role: "WEB ANAlytics IBM Analytics",
          connectionId: null,
        },
        {
          name: "Magnite Performance",
          image: "Magnite Performance",
          role: "WEB ANAlytics Magnite Performance",
          connectionId: null,
        },
        {
          name: "Mixpanel",
          image: "Mixpanel",
          role: "WEB ANAlytics Mixpanel",
          connectionId: null,
        },
        {
          name: "Optimizely",
          image: "Optimizely",
          role: "WEB ANAlytics Optimizely",
          connectionId: null,
        },
        {
          name: "Piwik Pro",
          image: "Piwik Pro",
          role: "WEB ANAlytics Piwik Pro",
          connectionId: null,
        },
        {
          name: "Similar Web",
          image: "Similar Web",
          role: "WEB ANAlytics Similar Web",
          connectionId: null,
        },
        {
          name: "Site Impact",
          image: "Site Impact",
          role: "WEB ANAlytics Site Impact",
          connectionId: null,
        },
        {
          name: "Webtrekk",
          image: "Webtrekk",
          role: "WEB ANAlytics Webtrekk",
          connectionId: null,
        },
        {
          name: "Webtrends",
          image: "Webtrends",
          role: "WEB ANAlytics Webtrends",
          connectionId: null,
        },

        {
          name: "Active Campaign",
          image: "Active Campaign",
          role: "CRM Active Campaign",
          connectionId: null,
        },
        {
          name: "Braze",
          image: "Braze",
          role: "CRM Braze",
          connectionId: null,
        },
        {
          name: "Campaign Monitor",
          image: "Campaign Monitor",
          role: "CRM Campaign Monitor",
          connectionId: null,
        },
        {
          name: "Cordial",
          image: "Cordial",
          role: "CRM Cordial",
          connectionId: null,
        },
        {
          name: "Emarsys",
          image: "Emarsys",
          role: "CRM Emarsys",
          connectionId: null,
        },
        {
          name: "Evalanche",
          image: "Evalanche",
          role: "CRM Evalanche",
          connectionId: null,
        },
        {
          name: "Hubspot Destination",
          image: "Hubspot Destination",
          role: "CRM Hubspot Destination",
          connectionId: null,
        },
        {
          name: "Hubspot",
          image: "Hubspot Destination",
          role: "CRM Hubspot",
          connectionId: null,
        },
        {
          name: "Iterable",
          image: "Iterable",
          role: "CRM Iterable",
          connectionId: null,
        },
        {
          name: "Klaviyo",
          image: "Klaviyo",
          role: "CRM Klaviyo",
          connectionId: null,
        },
        {
          name: "Mailchimp",
          image: "Mailchimp",
          role: "CRM Mailchimp",
          connectionId: null,
        },
        {
          name: "Mailing Work",
          image: "Mailing Work",
          role: "CRM Mailing Work",
          connectionId: null,
        },
        {
          name: "Mailworx",
          image: "Mailworx",
          role: "CRM Mailworx",
          connectionId: null,
        },
        {
          name: "Marketo",
          image: "Marketo",
          role: "CRM Marketo",
          connectionId: null,
        },
        {
          name: "Microsoft Dynamics",
          image: "Microsoft Dynamics",
          role: "CRM Microsoft Dynamics",
          connectionId: null,
        },
        {
          name: "Piano",
          image: "Piano",
          role: "CRM Piano",
          connectionId: null,
        },
        {
          name: "Salesforce Marketing Cloud",
          image: "salesforce",
          role: "CRM Salesforce Marketing Cloud",
          connectionId: null,
        },
        {
          name: "Salesforce",
          image: "salesforce",
          role: "CRM Salesforce",
          connectionId: null,
        },
        {
          name: "Sendinblue",
          image: "Sendinblue",
          role: "CRM Sendinblue",
          connectionId: null,
        },
        {
          name: "Sharpspring",
          image: "Sharpspring",
          role: "CRM Sharpspring",
          connectionId: null,
        },
        {
          name: "Teamleader",
          image: "Teamleader",
          role: "CRM Teamleader",
          connectionId: null,
        },
        {
          name: "Zendesk Sell",
          image: "Zendesk Sell",
          role: "CRM Zendesk Sell",
          connectionId: null,
        },

        {
          name: "Moat",
          image: "Moat",
          role: "Brand Safety Moat",
          connectionId: null,
        },

        {
          name: "Clockify",
          image: "Clockify",
          role: "Time Traker Clockify",
          connectionId: null,
        },
        {
          name: "Harvest",
          image: "Harvest",
          role: "Time Traker Harvest",
          connectionId: null,
        },
        {
          name: "Yanomo",
          image: "Yanomo",
          role: "Time Traker Yanomo",
          connectionId: null,
        },

        {
          name: "Audience Report",
          image: "Audience Report",
          role: "Audience Research Audience Report",
          connectionId: null,
        },
        {
          name: "Cxense",
          image: "Cxense",
          role: "Audience Research Cxense",
          connectionId: null,
        },
        {
          name: "Nielsen Ad intel",
          image: "nielsen",
          role: "Audience Research Nielsen Ad intel",
          connectionId: null,
        },
        {
          name: "Nielsen DAR",
          image: "nielsen",
          role: "Audience Research Nielsen DAR",
          connectionId: null,
        },
        {
          name: "Reppublika",
          image: "Reppublika",
          role: "Audience Research Reppublika",
          connectionId: null,
        },
        {
          name: "YouGov Brandindex",
          image: "YouGov Brandindex",
          role: "Audience Research YouGov Brandindex",
          connectionId: null,
        },

        {
          name: "Open Weather Map",
          image: "Open Weather Map",
          role: "Weather Open Weather Map",
          connectionId: null,
        },
        {
          name: "Weather Stack",
          image: "Weather Stack",
          role: "Weather Weather Stack",
          connectionId: null,
        },

        {
          name: "Adbook+",
          image: "Adbook+",
          role: "Others Adbook+",
          connectionId: null,
        },
        {
          name: "Bright Talk",
          image: "Bright Talk",
          role: "Others Bright Talk",
          connectionId: null,
        },
        {
          name: "Bynder",
          image: "Bynder",
          role: "Others Bynder",
          connectionId: null,
        },
        {
          name: "Drift",
          image: "Drift",
          role: "Others Drift",
          connectionId: null,
        },
        {
          name: "Google Calender",
          image: "Google Calender",
          role: "Others Google Calender",
          connectionId: null,
        },
        {
          name: "GoTo Webinar",
          image: "GoTo Webinar",
          role: "Others GoTo Webinar",
          connectionId: null,
        },
        {
          name: "Innovid",
          image: "Innovid",
          role: "Others Innovid",
          connectionId: null,
        },
        {
          name: "Invoca",
          image: "Invoca",
          role: "Others Invoca",
          connectionId: null,
        },
        {
          name: "Kenshoo",
          image: "Kenshoo",
          role: "Others Kenshoo",
          connectionId: null,
        },
        {
          name: "Mailgun",
          image: "Mailgun",
          role: "Others Mailgun",
          connectionId: null,
        },
        {
          name: "Media Wizard",
          image: "Media Wizard",
          role: "Others Media Wizard",
          connectionId: null,
        },
        {
          name: "Norstat",
          image: "Norstat",
          role: "Others Norstat",
          connectionId: null,
        },
        {
          name: "Podigee",
          image: "Podigee",
          role: "Others Podigee",
          connectionId: null,
        },
        {
          name: "Spotwatch",
          image: "Spotwatch",
          role: "Others Spotwatch",
          connectionId: null,
        },
        {
          name: "Strpe",
          image: "Strpe",
          role: "Others Strpe",
          connectionId: null,
        },
        {
          name: "TXGB",
          image: "TXGB",
          role: "Others TXGB",
          connectionId: null,
        },
        {
          name: "Unbounce",
          image: "Unbounce",
          role: "Others Unbounce",
          connectionId: null,
        },
        {
          name: "Usabilla",
          image: "Usabilla",
          role: "Others Usabilla",
          connectionId: null,
        },
        {
          name: "Web Connect (Bearer)",
          image: "Web Connect",
          role: "Others Web Connect (Bearer)",
          connectionId: null,
        },
        {
          name: "Web Connect (Oauth)",
          image: "Web Connect",
          role: "Others Web Connect (Oauth)",
          connectionId: null,
        },
        {
          name: "Web Connect (Oauth2)",
          image: "Web Connect",
          role: "Others Web Connect (Oauth2)",
          connectionId: null,
        },
        {
          name: "Web Connect",
          image: "Web Connect",
          role: "Others Web Connect",
          connectionId: null,
        },
        {
          name: "Zendesk",
          image: "Zendesk",
          role: "Others Zendesk",
          connectionId: null,
        },
      ],
      tabs: [
        {
          category: "Digital Media*",
          required: true,
          tabContent: [
            {
              title: "Quick Access",
              expand: true,
              connectors: [
                {
                  image: "google",
                  name: "Google Ads",
                  connectionId: 250,
                  role: "DM Google Ads",
                },
                {
                  name: "Facebook Ads",
                  image: "facebook",
                  role: "DM Facebook",
                  connectionId: 208,
                },
                {
                  name: "LinkedIn Ads",
                  image: "linkedin",
                  role: "DM LinkedIn",
                  connectionId: 315,
                },
                {
                  name: "Twitter Ads",
                  image: "twitter",
                  role: "DM Twitter",
                  connectionId: 462,
                },
                {
                  name: "Display and Video 360",
                  image: "dv360",
                  role: "DM DV360",
                  connectionId: 255,
                },
                {
                  name: "Microsoft Ads",
                  image: "microsoft",
                  role: "DM Microsoft Ads",
                  connectionId: 349,
                },
                {
                  name: "Pinterest",
                  image: "Pinterest",
                  role: "DM Pinterest",
                  connectionId: 380,
                },
                {
                  name: "Snapchat",
                  image: "Snapchat",
                  role: "DM Snapchat",
                  connectionId: 424,
                },
                {
                  name: "Google Sheets",
                  image: "Google_Sheets",
                  role: "DM google sheets",
                  connectionId: 267,
                },
              ],
            },
            {
              title: "Search Advertising",
              expand: false,
              connectors: [
                {
                  name: "360.sn",
                  image: "360.sn",
                  role: "Search Advertising 360.sn",
                  connectionId: null,
                },
                {
                  name: "Amazon Advertising",
                  image: "Amazon",
                  role: "Search Advertising Amazon Advertising",
                  connectionId: null,
                },
                {
                  name: "Apple Search Ads",
                  image: "Apple Search Ads",
                  role: "Search Advertising Apple Search Ads",
                  connectionId: null,
                },
                {
                  name: "Googel Adwords",
                  image: "google-ad",
                  role: "Search Advertising Googel Adwords",
                  connectionId: null,
                },
                {
                  name: "Google Ads New",
                  image: "google-ad",
                  role: "Search Advertising Google Ads New",
                  connectionId: null,
                },
                {
                  name: "Google Keyword Planner",
                  image: "google-ad",
                  role: "Search Advertising Google Keyword Planner",
                  connectionId: null,
                },
                {
                  name: "Google Search ads 360",
                  image: "Google Search ads 360",
                  role: "Search Advertising Google Search ads 360",
                  connectionId: null,
                },
                {
                  name: "indeed sponsored jobs",
                  image: "indeed sponsored jobs",
                  role: "Search Advertising indeed sponsored jobs",
                  connectionId: null,
                },
                {
                  name: "Microsoft Ads",
                  image: "MicrosoftAds",
                  role: "Search Advertising Microsoft Ads",
                  connectionId: null,
                },
                {
                  name: "The Search Monitor",
                  image: "The Search Monitor",
                  role: "Search Advertising The Search Monitor",
                  connectionId: null,
                },
                {
                  name: "Yabdex",
                  image: "Yabdex",
                  role: "Search Advertising Yabdex",
                  connectionId: null,
                },
                {
                  name: "Yahoo Japan Ads",
                  image: "Yahoo Japan Ads",
                  role: "Search Advertising Yahoo Japan Ads",
                  connectionId: null,
                },
                {
                  name: "Yello Pages",
                  image: "Yello Pages",
                  role: "Search Advertising Yello Pages",
                  connectionId: null,
                },
                {
                  name: "Yelp Ads",
                  image: "Yelp Ads",
                  role: "Search Advertising Yelp Ads",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Native Advertising",
              expand: false,
              connectors: [
                {
                  name: "Affilinet",
                  image: "Affilinet",
                  role: "NativeAdvertising Affilinet",
                  connectionId: null,
                },
                {
                  name: "Ligatus",
                  image: "Ligatus",
                  role: "NativeAdvertising Ligatus",
                  connectionId: null,
                },
                {
                  name: "Outbrain",
                  image: "Outbrain",
                  role: "NativeAdvertising Outbrain",
                  connectionId: null,
                },
                {
                  name: "Plista (GraphQL)",
                  image: "Plista (GraphQL)",
                  role: "NativeAdvertising Plista (GraphQL)",
                  connectionId: null,
                },
                {
                  name: "Plista",
                  image: "Plista",
                  role: "NativeAdvertising Plista",
                  connectionId: null,
                },
                {
                  name: "Taboola",
                  image: "Taboola",
                  role: "NativeAdvertising Taboola",
                  connectionId: null,
                },
                {
                  name: "Verizon Media",
                  image: "Verizon Media",
                  role: "NativeAdvertising Verizon Media",
                  connectionId: null,
                },
                {
                  name: "Zemanta",
                  image: "Zemanta",
                  role: "NativeAdvertising Zemanta",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Social Advertising",
              expand: false,
              connectors: [
                {
                  name: "Facebook Ads Connect",
                  image: "Facebook",
                  role: "Social Advertising Facebook Ads Connect",
                  connectionId: null,
                },
                {
                  name: "Facebook Public Connection",
                  image: "Facebook",
                  role: "Social Advertising Facebook Public Connection",
                  connectionId: null,
                },
                {
                  name: "Linkedin Ads",
                  image: "LinkedIn",
                  role: "Social Advertising Linkedin Ads",
                  connectionId: null,
                },
                {
                  name: "MakeMeReach",
                  image: "MakeMeReach",
                  role: "Social Advertising MakeMeReach",
                  connectionId: null,
                },
                {
                  name: "Nexta",
                  image: "Nexta",
                  role: "Social Advertising Nexta",
                  connectionId: null,
                },
                {
                  name: "Pinterest",
                  image: "Pinterest",
                  role: "Social Advertising Pinterest",
                  connectionId: null,
                },
                {
                  name: "Reddit Ads",
                  image: "Reddit Ads",
                  role: "Social Advertising Reddit Ads",
                  connectionId: null,
                },
                {
                  name: "Snapchat",
                  image: "Snapchat",
                  role: "Social Advertising Snapchat",
                  connectionId: null,
                },
                {
                  name: "Tiktok Ads",
                  image: "Tiktok Ads",
                  role: "Social Advertising Tiktok Ads",
                  connectionId: null,
                },
                {
                  name: "Twitter Ads",
                  image: "twitter",
                  role: "Social Advertising twitter Ads",
                  connectionId: null,
                },
                {
                  name: "VK ads",
                  image: "VK ads",
                  role: "Social Advertising VK ads",
                  connectionId: null,
                },
              ],
            },
            {
              title: "SEO",
              expand: false,
              connectors: [
                {
                  name: "AccuRanker",
                  image: "AccuRanker",
                  role: "SEO AccuRanker",
                  connectionId: null,
                },
                {
                  name: "Advanced Web Ranking",
                  image: "Advanced Web Ranking",
                  role: "SEO Advanced Web Ranking",
                  connectionId: null,
                },
                {
                  name: "Bing Webmaster",
                  image: "Bing Webmaster",
                  role: "SEO Bing Webmaster",
                  connectionId: null,
                },
                {
                  name: "Brighedge",
                  image: "Brighedge",
                  role: "SEO Brighedge",
                  connectionId: null,
                },
                {
                  name: "Calibre",
                  image: "Calibre",
                  role: "SEO Calibre",
                  connectionId: null,
                },
                {
                  name: "Datametrics",
                  image: "Datametrics",
                  role: "SEO Datametrics",
                  connectionId: null,
                },
                {
                  name: "Google Search Console",
                  image: "Google Search Console",
                  role: "SEO Google Search Console",
                  connectionId: null,
                },
                {
                  name: "Keyword Tool",
                  image: "Keyword Tool",
                  role: "SEO Keyword Tool",
                  connectionId: null,
                },
                {
                  name: "Link",
                  image: "Link",
                  role: "SEO Link",
                  connectionId: null,
                },
                {
                  name: "MoZ",
                  image: "MoZ",
                  role: "SEO MoZ",
                  connectionId: null,
                },
                {
                  name: "Powerlinks",
                  image: "Powerlinks",
                  role: "SEO Powerlinks",
                  connectionId: null,
                },
                {
                  name: "rank Ranger",
                  image: "rank Ranger",
                  role: "SEO rank Ranger",
                  connectionId: null,
                },
                {
                  name: "Search metrics",
                  image: "Search metrics",
                  role: "SEO Search metrics",
                  connectionId: null,
                },
                {
                  name: "Semrush",
                  image: "Semrush",
                  role: "SEO Semrush",
                  connectionId: null,
                },
                {
                  name: "Sistrix",
                  image: "Sistrix",
                  role: "SEO Sistrix",
                  connectionId: null,
                },
                {
                  name: "STAT",
                  image: "STAT",
                  role: "SEO STAT",
                  connectionId: null,
                },
                {
                  name: "Web CEO",
                  image: "Web CEO",
                  role: "SEO Web CEO",
                  connectionId: null,
                },
                {
                  name: "Yext",
                  image: "Yext",
                  role: "SEO Yext",
                  connectionId: null,
                },
              ],
            },
            {
              title: "SSP",
              expand: false,
              connectors: [
                {
                  name: "Adform Seller",
                  image: "Adform Seller",
                  role: "SSP Adform Seller",
                  connectionId: null,
                },
                {
                  name: "Googlee Ad manager",
                  image: "Googlee Ad manager",
                  role: "SSP Googlee Ad manager",
                  connectionId: null,
                },
                {
                  name: "Media.et Neo tech API",
                  image: "Media.et Neo tech API",
                  role: "SSP Media.et Neo tech API",
                  connectionId: null,
                },
                {
                  name: "Media.et pub console",
                  image: "Media.et Neo tech API",
                  role: "SSP Media.et pub console",
                  connectionId: null,
                },
                {
                  name: "Pubmatic",
                  image: "Pubmatic",
                  role: "SSP Pubmatic",
                  connectionId: null,
                },
                {
                  name: "Sovrn",
                  image: "Sovrn",
                  role: "SSP Sovrn",
                  connectionId: null,
                },
                {
                  name: "Verizon Media",
                  image: "Verizon Media",
                  role: "SSP Verizon Media",
                  connectionId: null,
                },
                {
                  name: "xandr",
                  image: "xandr",
                  role: "SSP xandr",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Ad Server",
              expand: false,
              connectors: [
                {
                  name: "Adform Buyer",
                  image: "Adform Buyer",
                  role: "ADS Adform Buyer",
                  connectionId: null,
                },
                {
                  name: "Adform Master",
                  image: "Adform Buyer",
                  role: "ADS Adform Master",
                  connectionId: null,
                },
                {
                  name: "Flash Talking",
                  image: "flashtalking",
                  role: "ADS flashtalking",
                  connectionId: null,
                },
                {
                  name: "Gemius Adreal",
                  image: "Gemius Adreal",
                  role: "ADS Gemius Adreal",
                  connectionId: null,
                },
                {
                  name: "Gemius Direct Effect",
                  image: "Gemius Direct Effect",
                  role: "ADS Gemius Direct Effect",
                  connectionId: null,
                },
                {
                  name: "Google Campaign Manager",
                  image: "GoogleCampaignManager",
                  role: "ADS GoogleCampaignManager",
                  connectionId: null,
                },
                {
                  name: "Sizmek",
                  image: "Sizmek",
                  role: "ADS Sizmek",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Affilliate Advertising",
              expand: false,
              connectors: [
                {
                  name: "Ad Service",
                  image: "adservice",
                  role: "Affilliate Advertising adservice",
                  connectionId: null,
                },

                {
                  name: "Affilinet",
                  image: "Affilinet",
                  role: "Affilliate Advertising Affilinet",
                  connectionId: null,
                },
                {
                  name: "Awin Advertiser",
                  image: "Awin Advertiser",
                  role: "Affilliate Advertising Awin Advertiser",
                  connectionId: null,
                },
                {
                  name: "Awin Publisher",
                  image: "Awin Advertiser",
                  role: "Affilliate Advertising Awin Publisher",
                  connectionId: null,
                },
                {
                  name: "Belboon",
                  image: "Belboon",
                  role: "Affilliate Advertising Belboon",
                  connectionId: null,
                },
                {
                  name: "CJ Affiliate",
                  image: "CJ Affiliate",
                  role: "Affilliate Advertising CJ Affiliate",
                  connectionId: null,
                },
                {
                  name: "Daisycon",
                  image: "Daisycon",
                  role: "Affilliate Advertising Daisycon",
                  connectionId: null,
                },
                {
                  name: "Idealo",
                  image: "Idealo",
                  role: "Affilliate Advertising Idealo",
                  connectionId: null,
                },
                {
                  name: "Ladenzeile",
                  image: "Ladenzeile",
                  role: "Affilliate Advertising Ladenzeile",
                  connectionId: null,
                },
                {
                  name: "Markplatas",
                  image: "Markplatas",
                  role: "Affilliate Advertising Markplatas",
                  connectionId: null,
                },
                {
                  name: "Partnerize",
                  image: "Partnerize",
                  role: "Affilliate Advertising Partnerize",
                  connectionId: null,
                },
                {
                  name: "Promote IQ",
                  image: "Promote IQ",
                  role: "Affilliate Advertising Promote IQ",
                  connectionId: null,
                },
                {
                  name: "Rakuten Adverter",
                  image: "Rakuten Adverter",
                  role: "Affilliate Advertising Rakuten Adverter",
                  connectionId: null,
                },
                {
                  name: "Rakuten Publisher",
                  image: "Rakuten Adverter",
                  role: "Affilliate Advertising Rakuten Publisher",
                  connectionId: null,
                },
                {
                  name: "real.de",
                  image: "real.de",
                  role: "Affilliate Advertising real.de",
                  connectionId: null,
                },
                {
                  name: "Trackonomics",
                  image: "Trackonomics",
                  role: "Affilliate Advertising Trackonomics",
                  connectionId: null,
                },
                {
                  name: "Trade Tracker",
                  image: "Trade Tracker",
                  role: "Affilliate Advertising Trade Tracker",
                  connectionId: null,
                },
                {
                  name: "Webgains",
                  image: "Webgains",
                  role: "Affilliate Advertising Webgains",
                  connectionId: null,
                },
                {
                  name: "Zanox Advertiser",
                  image: "Zanox Advertiser",
                  role: "Affilliate Advertising Zanox Advertiser",
                  connectionId: null,
                },
                {
                  name: "Zanox Publisher",
                  image: "Zanox Advertiser",
                  role: "Affilliate Advertising Zanox Publisher",
                  connectionId: null,
                },
              ],
            },
            {
              title: "DSP",
              expand: false,
              connectors: [
                {
                  name: "Active Agent",
                  image: "Active Agent",
                  role: "DSP Active Agent",
                  connectionId: null,
                },
                {
                  name: "Adman",
                  image: "Adman",
                  role: "DSP Adman",
                  connectionId: null,
                },
                {
                  name: "Adobe Advertising Cloud",
                  image: "Adobe Advertising Cloud",
                  role: "DSP Adobe Advertising Cloud",
                  connectionId: null,
                },
                {
                  name: "AdRiver",
                  image: "AdRiver",
                  role: "DSP AdRiver",
                  connectionId: null,
                },
                {
                  name: "AdRoll",
                  image: "AdRoll",
                  role: "DSP AdRoll",
                  connectionId: null,
                },
                {
                  name: "Amazon DSP",
                  image: "Amazon",
                  role: "DSP Amazon DSP",
                  connectionId: null,
                },
                {
                  name: "Avocet",
                  image: "Avocet",
                  role: "DSP Avocet",
                  connectionId: null,
                },
                {
                  name: "Beeswax",
                  image: "Beeswax",
                  role: "DSP Beeswax",
                  connectionId: null,
                },
                {
                  name: "Celtra",
                  image: "Celtra",
                  role: "DSP Celtra",
                  connectionId: null,
                },
                {
                  name: "Cision",
                  image: "Cision",
                  role: "DSP Cision",
                  connectionId: null,
                },
                {
                  name: "Criteo Marketing Solutions",
                  image: "Criteo Marketing Solutions",
                  role: "DSP Criteo Marketing Solutions",
                  connectionId: null,
                },
                {
                  name: "Criteo Marketing",
                  image: "Criteo Marketing Solutions",
                  role: "DSP Criteo Marketing",
                  connectionId: null,
                },
                {
                  name: "Google Display & Video 360",
                  image: "DV360",
                  role: "DSP Google Display & Video 360",
                  connectionId: null,
                },
                {
                  name: "Hudson MX",
                  image: "Hudson MX",
                  role: "DSP Hudson MX",
                  connectionId: null,
                },
                {
                  name: "Jivox",
                  image: "Jivox",
                  role: "DSP Jivox",
                  connectionId: null,
                },
                {
                  name: "Lotame",
                  image: "Lotame",
                  role: "DSP Lotame",
                  connectionId: null,
                },
                {
                  name: "Mediamath",
                  image: "Mediamath",
                  role: "DSP Mediamath",
                  connectionId: null,
                },
                {
                  name: "My Taerget",
                  image: "My Taerget",
                  role: "DSP My Taerget",
                  connectionId: null,
                },
                {
                  name: "Outmoove",
                  image: "Outmoove",
                  role: "DSP Outmoove",
                  connectionId: null,
                },
                {
                  name: "Red Pineapple Media",
                  image: "Red Pineapple Media",
                  role: "DSP Red Pineapple Media",
                  connectionId: null,
                },
                {
                  name: "RTB House",
                  image: "RTB House",
                  role: "DSP RTB House",
                  connectionId: null,
                },
                {
                  name: "Stack Adapt",
                  image: "Stack Adapt",
                  role: "DSP Stack Adapt",
                  connectionId: null,
                },
                {
                  name: "Teads Ad Manager",
                  image: "Teads Ad Manager",
                  role: "DSP Teads Ad Manager",
                  connectionId: null,
                },
                {
                  name: "Teads Analytics",
                  image: "Teads Ad Manager",
                  role: "DSP Teads Analytics",
                  connectionId: null,
                },
                {
                  name: "The Trade Desk",
                  image: "The Trade Desk",
                  role: "DSP The Trade Desk",
                  connectionId: null,
                },
                {
                  name: "Verizon Media DSP",
                  image: "Verizon Media DSP",
                  role: "DSP Verizon Media DSP",
                  connectionId: null,
                },
                {
                  name: "Vistar Media",
                  image: "Vistar Media",
                  role: "DSP Vistar Media",
                  connectionId: null,
                },
              ],
            },
          ],
        },
        {
          category: "Analytics*",
          required: true,
          tabContent: [
            {
              title: "Quick Access",
              expand: true,
              connectors: [
                {
                  name: "Google Analytics",
                  image: "google-anal",
                  role: "DM google-anal",
                  connectionId: 270,
                },

                {
                  name: "Google Sheets",
                  image: "Google_Sheets",
                  role: "WA google sheets",
                  connectionId: 267,
                },
                {
                  name: "Adobe Analytics (Service)",
                  image: "adobe-anal",
                  role: "WEB ANAlytics Adobe Analytics (Service)",
                  connectionId: null,
                },
                {
                  name: "Adobe Analytics",
                  image: "adobe-anal",
                  role: "WEB ANAlytics Adobe Analytics",
                  connectionId: null,
                },
                {
                  name: "Adobe Analytics(WSSE)",
                  image: "adobe-anal",
                  role: "WEB ANAlytics Adobe Analytics(WSSE)",
                  connectionId: null,
                },
                {
                  name: "Convert",
                  image: "Convert",
                  role: "WEB ANAlytics Convert",
                  connectionId: null,
                },
                {
                  name: "Etracker",
                  image: "Etracker",
                  role: "WEB ANAlytics Etracker",
                  connectionId: null,
                },
                {
                  name: "Google Analytics(Oauth)",
                  image: "Google Analytics(Oauth)",
                  role: "WEB ANAlytics Google Analytics(Oauth)",
                  connectionId: null,
                },
                {
                  name: "Google Analytics(Service)",
                  image: "Google Analytics(Oauth)",
                  role: "WEB ANAlytics Google Analytics(Service)",
                  connectionId: null,
                },
                {
                  name: "IBM Analytics",
                  image: "IBM Analytics",
                  role: "WEB ANAlytics IBM Analytics",
                  connectionId: null,
                },
                {
                  name: "Magnite Performance",
                  image: "Magnite Performance",
                  role: "WEB ANAlytics Magnite Performance",
                  connectionId: null,
                },
                {
                  name: "Mixpanel",
                  image: "Mixpanel",
                  role: "WEB ANAlytics Mixpanel",
                  connectionId: null,
                },
                {
                  name: "Optimizely",
                  image: "Optimizely",
                  role: "WEB ANAlytics Optimizely",
                  connectionId: null,
                },
                {
                  name: "Piwik Pro",
                  image: "Piwik Pro",
                  role: "WEB ANAlytics Piwik Pro",
                  connectionId: null,
                },
                {
                  name: "Similar Web",
                  image: "Similar Web",
                  role: "WEB ANAlytics Similar Web",
                  connectionId: null,
                },
                {
                  name: "Site Impact",
                  image: "Site Impact",
                  role: "WEB ANAlytics Site Impact",
                  connectionId: null,
                },
                {
                  name: "Webtrekk",
                  image: "Webtrekk",
                  role: "WEB ANAlytics Webtrekk",
                  connectionId: null,
                },
                {
                  name: "Webtrends",
                  image: "Webtrends",
                  role: "WEB ANAlytics Webtrends",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Social Insights",
              expand: false,
              connectors: [
                {
                  name: "Facebook Insights",
                  image: "facebook",
                  role: "DM Facebook Page Insights",
                  connectionId: 690,
                },
                {
                  name: "Instagram Insights",
                  image: "instagram",
                  role: "DM Instagram Insights",
                  connectionId: 214,
                },
                {
                  name: "YouTube Insights",
                  image: "youtube",
                  role: "DM Youtube Insights",
                  connectionId: 284,
                },
                {
                  name: "LinkedIn Insights",
                  image: "linkedin",
                  role: "DM linkedin insights",
                  connectionId: 319,
                },
                {
                  name: "Twitter Insights",
                  image: "twitter",
                  role: "DM Twitter Insights",
                  connectionId: 462,
                },
                {
                  name: "Google Ads Keyword Planner",
                  image: "google",
                  role: "DM Google Ads Keyword Planner",
                  connectionId: 741,
                },
                {
                  name: "Google Sheets",
                  image: "Google_Sheets",
                  role: "DM google sheets",
                  connectionId: 267,
                },
              ],
            },
            {
              title: "Call Analytics",
              expand: false,
              connectors: [
                {
                  name: "Call Gear",
                  image: "Call Gear",
                  role: "Call Analytics Call Gear",
                  connectionId: null,
                },
                {
                  name: "Call Rail",
                  image: "Call Rail",
                  role: "Call Analytics Call Rail",
                  connectionId: null,
                },
                {
                  name: "Marchex",
                  image: "Marchex",
                  role: "Call Analytics Marchex",
                  connectionId: null,
                },
                {
                  name: "Response Tab",
                  image: "Response Tab",
                  role: "Call Analytics Response Tab",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Social Analytics",
              expand: false,
              connectors: [
                {
                  name: "Adalyser",
                  image: "Adalyser",
                  role: "Social Analytics Adalyser",
                  connectionId: null,
                },
                {
                  name: "Awario",
                  image: "Awario",
                  role: "Social Analytics Awario",
                  connectionId: null,
                },
                {
                  name: "Brandwatch",
                  image: "brandWatch",
                  role: "Social Analytics Brandwatch",
                  connectionId: null,
                },
                {
                  name: "Facebook Page Insights",
                  image: "Facebook",
                  role: "Social Analytics Facebook Page Insights",
                  connectionId: null,
                },
                {
                  name: "Google My Business",
                  image: "Google My Business",
                  role: "Social Analytics Google My Business",
                  connectionId: null,
                },
                {
                  name: "Instagram Basic Dislplay",
                  image: "Instagram",
                  role: "Social Analytics Instagram Basic Dislplay",
                  connectionId: null,
                },
                {
                  name: "Instagram Business",
                  image: "Instagram Business",
                  role: "Social Analytics Instagram Business",
                  connectionId: null,
                },
                {
                  name: "Keyhole",
                  image: "keyHole",
                  role: "Social Analytics Keyhole",
                  connectionId: null,
                },
                {
                  name: "Linkedin organisations and brands",
                  image: "LinkedIn",
                  role: "Social Analytics Linkedin organisations and brands",
                  connectionId: null,
                },
                {
                  name: "Linkfluence Radarly",
                  image: "Linkfluence Radarly",
                  role: "Social Analytics Linkfluence Radarly",
                  connectionId: null,
                },
                {
                  name: "netbase",
                  image: "netbase",
                  role: "Social Analytics netbase",
                  connectionId: null,
                },
                {
                  name: "Socialbakers",
                  image: "Socialbakers",
                  role: "Social Analytics Socialbakers",
                  connectionId: null,
                },
                {
                  name: "Sprinklr",
                  image: "Sprinklr",
                  role: "Social Analytics Sprinklr",
                  connectionId: null,
                },
                {
                  name: "Sprinklr Prod2",
                  image: "Sprinklr",
                  role: "Social Analytics Sprinklr Prod2",
                  connectionId: null,
                },
                {
                  name: "Synthesio",
                  image: "Synthesio",
                  role: "Social Analytics Synthesio",
                  connectionId: null,
                },
                {
                  name: "Talkwalker",
                  image: "Talkwalker",
                  role: "Social Analytics Talkwalker",
                  connectionId: null,
                },
                {
                  name: "Twitch",
                  image: "Twitch",
                  role: "Social Analytics Twitch",
                  connectionId: null,
                },
                {
                  name: "Unmetric",
                  image: "Unmetric",
                  role: "Social Analytics Unmetric",
                  connectionId: null,
                },
                {
                  name: "Youtube Analytics",
                  image: "youtube",
                  role: "Social Analytics Youtube Analytics",
                  connectionId: null,
                },
                {
                  name: "Youtube public communications",
                  image: "youtube",
                  role: "Social Analytics Youtube public communications",
                  connectionId: null,
                },
                {
                  name: "Youtube Public",
                  image: "youtube",
                  role: "Social Analytics Youtube Public",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Mobile",
              expand: false,
              connectors: [
                {
                  name: "Adjust",
                  image: "Adjust",
                  role: "Mobile Adjust",
                  connectionId: null,
                },
                {
                  name: "APP Annie",
                  image: "APP Annie",
                  role: "Mobile APP Annie",
                  connectionId: null,
                },
                {
                  name: "App Follow",
                  image: "App Follow",
                  role: "Mobile App Follow",
                  connectionId: null,
                },
                {
                  name: "App Figures",
                  image: "appfigures",
                  role: "Mobile appfigures",
                  connectionId: null,
                },
                {
                  name: "AppLovin",
                  image: "AppLovin",
                  role: "Mobile AppLovin",
                  connectionId: null,
                },
                {
                  name: "Apps Flyer",
                  image: "appsFlyer",
                  role: "Mobile Apps Flyer",
                  connectionId: null,
                },
                {
                  name: "apptweak",
                  image: "apptweak",
                  role: "Mobile apptweak",
                  connectionId: null,
                },
                {
                  name: "Branch Querry API",
                  image: "Branch Querry API",
                  role: "Mobile Branch Querry API",
                  connectionId: null,
                },
                {
                  name: "Branch",
                  image: "Branch Querry API",
                  role: "Mobile Branch",
                  connectionId: null,
                },
                {
                  name: "Fyber",
                  image: "Fyber",
                  role: "Mobile Fyber",
                  connectionId: null,
                },
                {
                  name: "Iron Source",
                  image: "Iron Source",
                  role: "Mobile Iron Source",
                  connectionId: null,
                },
                {
                  name: "Kochava",
                  image: "Kochava",
                  role: "Mobile Kochava",
                  connectionId: null,
                },
                {
                  name: "NativeX",
                  image: "NativeX",
                  role: "Mobile NativeX",
                  connectionId: null,
                },
                {
                  name: "Priori data",
                  image: "Priori data",
                  role: "Mobile Priori data",
                  connectionId: null,
                },
                {
                  name: "SPlicky",
                  image: "SPlicky",
                  role: "Mobile SPlicky",
                  connectionId: null,
                },
                {
                  name: "tune-branch",
                  image: "tune-branch",
                  role: "Mobile tune-branch",
                  connectionId: null,
                },
                {
                  name: "Uberall",
                  image: "Uberall",
                  role: "Mobile Uberall",
                  connectionId: null,
                },
              ],
            },
            {
              title: "CRM",
              expand: false,
              connectors: [
                {
                  name: "Active Campaign",
                  image: "Active Campaign",
                  role: "CRM Active Campaign",
                  connectionId: null,
                },
                {
                  name: "Braze",
                  image: "Braze",
                  role: "CRM Braze",
                  connectionId: null,
                },
                {
                  name: "Campaign Monitor",
                  image: "Campaign Monitor",
                  role: "CRM Campaign Monitor",
                  connectionId: null,
                },
                {
                  name: "Cordial",
                  image: "Cordial",
                  role: "CRM Cordial",
                  connectionId: null,
                },
                {
                  name: "Emarsys",
                  image: "Emarsys",
                  role: "CRM Emarsys",
                  connectionId: null,
                },
                {
                  name: "Evalanche",
                  image: "Evalanche",
                  role: "CRM Evalanche",
                  connectionId: null,
                },
                {
                  name: "Hubspot Destination",
                  image: "Hubspot Destination",
                  role: "CRM Hubspot Destination",
                  connectionId: null,
                },
                {
                  name: "Hubspot",
                  image: "Hubspot Destination",
                  role: "CRM Hubspot",
                  connectionId: null,
                },
                {
                  name: "Iterable",
                  image: "Iterable",
                  role: "CRM Iterable",
                  connectionId: null,
                },
                {
                  name: "Klaviyo",
                  image: "Klaviyo",
                  role: "CRM Klaviyo",
                  connectionId: null,
                },
                {
                  name: "Mailchimp",
                  image: "Mailchimp",
                  role: "CRM Mailchimp",
                  connectionId: null,
                },
                {
                  name: "Mailing Work",
                  image: "Mailing Work",
                  role: "CRM Mailing Work",
                  connectionId: null,
                },
                {
                  name: "Mailworx",
                  image: "Mailworx",
                  role: "CRM Mailworx",
                  connectionId: null,
                },
                {
                  name: "Marketo",
                  image: "Marketo",
                  role: "CRM Marketo",
                  connectionId: null,
                },
                {
                  name: "Microsoft Dynamics",
                  image: "Microsoft Dynamics",
                  role: "CRM Microsoft Dynamics",
                  connectionId: null,
                },
                {
                  name: "Piano",
                  image: "Piano",
                  role: "CRM Piano",
                  connectionId: null,
                },
                {
                  name: "Salesforce Marketing Cloud",
                  image: "salesforce",
                  role: "CRM Salesforce Marketing Cloud",
                  connectionId: null,
                },
                {
                  name: "Salesforce",
                  image: "salesforce",
                  role: "CRM Salesforce",
                  connectionId: null,
                },
                {
                  name: "Sendinblue",
                  image: "Sendinblue",
                  role: "CRM Sendinblue",
                  connectionId: null,
                },
                {
                  name: "Sharpspring",
                  image: "Sharpspring",
                  role: "CRM Sharpspring",
                  connectionId: null,
                },
                {
                  name: "Teamleader",
                  image: "Teamleader",
                  role: "CRM Teamleader",
                  connectionId: null,
                },
                {
                  name: "Zendesk Sell",
                  image: "Zendesk Sell",
                  role: "CRM Zendesk Sell",
                  connectionId: null,
                },
              ],
            },
          ],
        },
        {
          category: "Search Trends*",
          required: true,
          tabContent: [
            {
              title: "Google Trends",
              expand: true,
              connectors: [
                {
                  name: "Google Trends",
                  image: "Google Trends",
                  role: "DM Google Trends",
                  connectionId: 791,
                },
              ],
            },
          ],
        },
        {
          category: "KPI*",
          required: true,
          tabContent: [
            {
              title: "Web Analytics",
              expand: true,
              connectors: [
                {
                  name: "Google Analytics",
                  image: "google-anal",
                  role: "DM google-anal",
                  connectionId: 270,
                },

                {
                  name: "Google Sheets",
                  image: "Google_Sheets",
                  role: "WA google sheets",
                  connectionId: 267,
                },
                {
                  name: "Adobe Analytics (Service)",
                  image: "adobe-anal",
                  role: "WEB ANAlytics Adobe Analytics (Service)",
                  connectionId: null,
                },
                {
                  name: "Adobe Analytics",
                  image: "adobe-anal",
                  role: "WEB ANAlytics Adobe Analytics",
                  connectionId: null,
                },
                {
                  name: "Adobe Analytics(WSSE)",
                  image: "adobe-anal",
                  role: "WEB ANAlytics Adobe Analytics(WSSE)",
                  connectionId: null,
                },
                {
                  name: "Convert",
                  image: "Convert",
                  role: "WEB ANAlytics Convert",
                  connectionId: null,
                },
                {
                  name: "Etracker",
                  image: "Etracker",
                  role: "WEB ANAlytics Etracker",
                  connectionId: null,
                },
                {
                  name: "Google Analytics(Oauth)",
                  image: "Google Analytics(Oauth)",
                  role: "WEB ANAlytics Google Analytics(Oauth)",
                  connectionId: null,
                },
                {
                  name: "Google Analytics(Service)",
                  image: "Google Analytics(Oauth)",
                  role: "WEB ANAlytics Google Analytics(Service)",
                  connectionId: null,
                },
                {
                  name: "IBM Analytics",
                  image: "IBM Analytics",
                  role: "WEB ANAlytics IBM Analytics",
                  connectionId: null,
                },
                {
                  name: "Magnite Performance",
                  image: "Magnite Performance",
                  role: "WEB ANAlytics Magnite Performance",
                  connectionId: null,
                },
                {
                  name: "Mixpanel",
                  image: "Mixpanel",
                  role: "WEB ANAlytics Mixpanel",
                  connectionId: null,
                },
                {
                  name: "Optimizely",
                  image: "Optimizely",
                  role: "WEB ANAlytics Optimizely",
                  connectionId: null,
                },
                {
                  name: "Piwik Pro",
                  image: "Piwik Pro",
                  role: "WEB ANAlytics Piwik Pro",
                  connectionId: null,
                },
                {
                  name: "Similar Web",
                  image: "Similar Web",
                  role: "WEB ANAlytics Similar Web",
                  connectionId: null,
                },
                {
                  name: "Shopify",
                  image: "Shopify",
                  role: "Commerce Shopify",
                  connectionId: null,
                },
                {
                  name: "Site Impact",
                  image: "Site Impact",
                  role: "WEB ANAlytics Site Impact",
                  connectionId: null,
                },
                {
                  name: "Webtrekk",
                  image: "Webtrekk",
                  role: "WEB ANAlytics Webtrekk",
                  connectionId: null,
                },
                {
                  name: "Webtrends",
                  image: "Webtrends",
                  role: "WEB ANAlytics Webtrends",
                  connectionId: null,
                },
              ],
            },
            {
              title: "CRM",
              expand: false,
              connectors: [
                {
                  name: "Active Campaign",
                  image: "Active Campaign",
                  role: "CRM Active Campaign",
                  connectionId: null,
                },
                {
                  name: "Braze",
                  image: "Braze",
                  role: "CRM Braze",
                  connectionId: null,
                },
                {
                  name: "Campaign Monitor",
                  image: "Campaign Monitor",
                  role: "CRM Campaign Monitor",
                  connectionId: null,
                },
                {
                  name: "Cordial",
                  image: "Cordial",
                  role: "CRM Cordial",
                  connectionId: null,
                },
                {
                  name: "Emarsys",
                  image: "Emarsys",
                  role: "CRM Emarsys",
                  connectionId: null,
                },
                {
                  name: "Evalanche",
                  image: "Evalanche",
                  role: "CRM Evalanche",
                  connectionId: null,
                },
                {
                  name: "Hubspot Destination",
                  image: "Hubspot Destination",
                  role: "CRM Hubspot Destination",
                  connectionId: null,
                },
                {
                  name: "Hubspot",
                  image: "Hubspot Destination",
                  role: "CRM Hubspot",
                  connectionId: null,
                },
                {
                  name: "Iterable",
                  image: "Iterable",
                  role: "CRM Iterable",
                  connectionId: null,
                },
                {
                  name: "Klaviyo",
                  image: "Klaviyo",
                  role: "CRM Klaviyo",
                  connectionId: null,
                },
                {
                  name: "Mailchimp",
                  image: "Mailchimp",
                  role: "CRM Mailchimp",
                  connectionId: null,
                },
                {
                  name: "Mailing Work",
                  image: "Mailing Work",
                  role: "CRM Mailing Work",
                  connectionId: null,
                },
                {
                  name: "Mailworx",
                  image: "Mailworx",
                  role: "CRM Mailworx",
                  connectionId: null,
                },
                {
                  name: "Marketo",
                  image: "Marketo",
                  role: "CRM Marketo",
                  connectionId: null,
                },
                {
                  name: "Microsoft Dynamics",
                  image: "Microsoft Dynamics",
                  role: "CRM Microsoft Dynamics",
                  connectionId: null,
                },
                {
                  name: "Piano",
                  image: "Piano",
                  role: "CRM Piano",
                  connectionId: null,
                },
                {
                  name: "Salesforce Marketing Cloud",
                  image: "salesforce",
                  role: "CRM Salesforce Marketing Cloud",
                  connectionId: null,
                },
                {
                  name: "Salesforce",
                  image: "salesforce",
                  role: "CRM Salesforce",
                  connectionId: null,
                },
                {
                  name: "Sendinblue",
                  image: "Sendinblue",
                  role: "CRM Sendinblue",
                  connectionId: null,
                },
                {
                  name: "Sharpspring",
                  image: "Sharpspring",
                  role: "CRM Sharpspring",
                  connectionId: null,
                },
                {
                  name: "Teamleader",
                  image: "Teamleader",
                  role: "CRM Teamleader",
                  connectionId: null,
                },
                {
                  name: "Zendesk Sell",
                  image: "Zendesk Sell",
                  role: "CRM Zendesk Sell",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Commerce",
              expand: false,
              connectors: [
                {
                  name: "Amazon Marketplace",
                  image: "Amazon",
                  role: "Commerce Amazon Marketplace",
                  connectionId: null,
                },
                {
                  name: "Amazon Selling Partner",
                  image: "Amazon",
                  role: "Commerce Amazon Selling Partner",
                  connectionId: null,
                },
                {
                  name: "Channel Pilot",
                  image: "Channel Pilot",
                  role: "Commerce Channel Pilot",
                  connectionId: null,
                },
                {
                  name: "Dataweave",
                  image: "dataweave",
                  role: "Commerce dataweave",
                  connectionId: null,
                },
                {
                  name: "Digistore24",
                  image: "Digistore24",
                  role: "Commerce Digistore24",
                  connectionId: null,
                },
                {
                  name: "Ebay",
                  image: "Ebay",
                  role: "Commerce Ebay",
                  connectionId: null,
                },
                {
                  name: "Feed Dynamix",
                  image: "Feed Dynamix",
                  role: "Commerce Feed Dynamix",
                  connectionId: null,
                },
                {
                  name: "Google Content for Shopping",
                  image: "Google Content for Shopping",
                  role: "Commerce Google Content for Shopping",
                  connectionId: null,
                },
                {
                  name: "Magneto",
                  image: "Magneto",
                  role: "Commerce Magneto",
                  connectionId: null,
                },
                {
                  name: "Shopalyst",
                  image: "Shopalyst",
                  role: "Commerce Shopalyst",
                  connectionId: null,
                },
                {
                  name: "Shopee",
                  image: "Shopee",
                  role: "Commerce Shopee",
                  connectionId: null,
                },
                {
                  name: "Shopify",
                  image: "Shopify",
                  role: "Commerce Shopify",
                  connectionId: null,
                },
                {
                  name: "Tweakers",
                  image: "Tweakers",
                  role: "Commerce Tweakers",
                  connectionId: null,
                },
                {
                  name: "Woo commerce",
                  image: "Woo commerce",
                  role: "Commerce Woo commerce",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Mobile",
              expand: false,
              connectors: [
                {
                  name: "Adjust",
                  image: "Adjust",
                  role: "Mobile Adjust",
                  connectionId: null,
                },
                {
                  name: "APP Annie",
                  image: "APP Annie",
                  role: "Mobile APP Annie",
                  connectionId: null,
                },
                {
                  name: "App Follow",
                  image: "App Follow",
                  role: "Mobile App Follow",
                  connectionId: null,
                },
                {
                  name: "App Figures",
                  image: "appfigures",
                  role: "Mobile appfigures",
                  connectionId: null,
                },
                {
                  name: "AppLovin",
                  image: "AppLovin",
                  role: "Mobile AppLovin",
                  connectionId: null,
                },
                {
                  name: "Apps Flyer",
                  image: "appsFlyer",
                  role: "Mobile Apps Flyer",
                  connectionId: null,
                },
                {
                  name: "apptweak",
                  image: "apptweak",
                  role: "Mobile apptweak",
                  connectionId: null,
                },
                {
                  name: "Branch Querry API",
                  image: "Branch Querry API",
                  role: "Mobile Branch Querry API",
                  connectionId: null,
                },
                {
                  name: "Branch",
                  image: "Branch Querry API",
                  role: "Mobile Branch",
                  connectionId: null,
                },
                {
                  name: "Fyber",
                  image: "Fyber",
                  role: "Mobile Fyber",
                  connectionId: null,
                },
                {
                  name: "Iron Source",
                  image: "Iron Source",
                  role: "Mobile Iron Source",
                  connectionId: null,
                },
                {
                  name: "Kochava",
                  image: "Kochava",
                  role: "Mobile Kochava",
                  connectionId: null,
                },
                {
                  name: "NativeX",
                  image: "NativeX",
                  role: "Mobile NativeX",
                  connectionId: null,
                },
                {
                  name: "Priori data",
                  image: "Priori data",
                  role: "Mobile Priori data",
                  connectionId: null,
                },
                {
                  name: "SPlicky",
                  image: "SPlicky",
                  role: "Mobile SPlicky",
                  connectionId: null,
                },
                {
                  name: "tune-branch",
                  image: "tune-branch",
                  role: "Mobile tune-branch",
                  connectionId: null,
                },
                {
                  name: "Uberall",
                  image: "Uberall",
                  role: "Mobile Uberall",
                  connectionId: null,
                },
              ],
            },
          ],
        },
        {
          category: "E-Commerce",
          required: false,
          tabContent: [
            {
              title: "Commerce",
              expand: true,
              connectors: [
                {
                  name: "Amazon Marketplace",
                  image: "Amazon",
                  role: "Commerce Amazon Marketplace",
                  connectionId: null,
                },
                {
                  name: "Amazon Selling Partner",
                  image: "Amazon",
                  role: "Commerce Amazon Selling Partner",
                  connectionId: null,
                },
                {
                  name: "Channel Pilot",
                  image: "Channel Pilot",
                  role: "Commerce Channel Pilot",
                  connectionId: null,
                },
                {
                  name: "Dataweave",
                  image: "dataweave",
                  role: "Commerce dataweave",
                  connectionId: null,
                },
                {
                  name: "Digistore24",
                  image: "Digistore24",
                  role: "Commerce Digistore24",
                  connectionId: null,
                },
                {
                  name: "Ebay",
                  image: "Ebay",
                  role: "Commerce Ebay",
                  connectionId: null,
                },
                {
                  name: "Feed Dynamix",
                  image: "Feed Dynamix",
                  role: "Commerce Feed Dynamix",
                  connectionId: null,
                },
                {
                  name: "Google Content for Shopping",
                  image: "Google Content for Shopping",
                  role: "Commerce Google Content for Shopping",
                  connectionId: null,
                },
                {
                  name: "Magneto",
                  image: "Magneto",
                  role: "Commerce Magneto",
                  connectionId: null,
                },
                {
                  name: "Shopalyst",
                  image: "Shopalyst",
                  role: "Commerce Shopalyst",
                  connectionId: null,
                },
                {
                  name: "Shopee",
                  image: "Shopee",
                  role: "Commerce Shopee",
                  connectionId: null,
                },
                {
                  name: "Shopify",
                  image: "Shopify",
                  role: "Commerce Shopify",
                  connectionId: null,
                },
                {
                  name: "Tweakers",
                  image: "Tweakers",
                  role: "Commerce Tweakers",
                  connectionId: null,
                },
                {
                  name: "Woo commerce",
                  image: "Woo commerce",
                  role: "Commerce Woo commerce",
                  connectionId: null,
                },
              ],
            },
          ],
        },
        {
          category: "Database",
          required: false,
          tabContent: [
            {
              title: "Database",
              expand: true,
              connectors: [
                {
                  name: "Azure Synapse",
                  image: "Azure Synapse",
                  role: "Data Base Azure Synapse",
                  connectionId: null,
                },
                {
                  name: "Microsoft SQL Server",
                  image: "Microsoft SQL Server",
                  role: "Data Base Microsoft SQL Server",
                  connectionId: null,
                },
                {
                  name: "Mongo DB",
                  image: "Mongo DB",
                  role: "Data Base Mongo DB",
                  connectionId: null,
                },
                {
                  name: "My SQL",
                  image: "My SQL",
                  role: "Data Base My SQL",
                  connectionId: null,
                },
                {
                  name: "Oracle",
                  image: "Oracle",
                  role: "Data Base Oracle",
                  connectionId: null,
                },
                {
                  name: "Postgre SQl",
                  image: "Postgre SQl",
                  role: "Data Base Postgre SQl",
                  connectionId: null,
                },
                {
                  name: "Snowflake",
                  image: "Snowflake",
                  role: "Data Base Snowflake",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Datalake",
              expand: false,
              connectors: [
                {
                  name: "Amazon Athena",
                  image: "Amazon Athena",
                  role: "Data Lake Amazon Athena",
                  connectionId: null,
                },
                {
                  name: "Box",
                  image: "Box",
                  role: "Data Lake Box",
                  connectionId: null,
                },
                {
                  name: "Databricks",
                  image: "Databricks",
                  role: "Data Lake Databricks",
                  connectionId: null,
                },
                {
                  name: "Dropbox",
                  image: "Dropbox",
                  role: "Data Lake Dropbox",
                  connectionId: null,
                },
                {
                  name: "Egnyte EU",
                  image: "Egnyte EU",
                  role: "Data Lake Egnyte EU",
                  connectionId: null,
                },
                {
                  name: "Egnyte US",
                  image: "Egnyte EU",
                  role: "Data Lake Egnyte US",
                  connectionId: null,
                },
                {
                  name: "File Azure SAS",
                  image: "File Azure SAS",
                  role: "Data Lake File Azure SAS",
                  connectionId: null,
                },
                {
                  name: "File Azure",
                  image: "File Azure",
                  role: "Data Lake File Azure",
                  connectionId: null,
                },
                {
                  name: "File one Drive",
                  image: "File one Drive",
                  role: "Data Lake File one Drive",
                  connectionId: null,
                },
                {
                  name: "File",
                  image: "File",
                  role: "Data Lake File",
                  connectionId: null,
                },
                {
                  name: "Google Drive",
                  image: "google-drive",
                  role: "Data Lake Google Drive",
                  connectionId: null,
                },
                {
                  name: "Google Sheets",
                  image: "Google_Sheets",
                  role: "Data Lake Google Sheets",
                  connectionId: null,
                },
                {
                  name: "Hadoop",
                  image: "Hadoop",
                  role: "Data Lake Hadoop",
                  connectionId: null,
                },
                {
                  name: "S3 Connection",
                  image: "S3 Connection",
                  role: "Data Lake S3 Connection",
                  connectionId: null,
                },
                {
                  name: "SFTP Public Key",
                  image: "File",
                  role: "Data Lake SFTP Public Key",
                  connectionId: null,
                },
                {
                  name: "Sharepoint Online",
                  image: "Sharepoint Online",
                  role: "Data Lake Sharepoint Online",
                  connectionId: null,
                },
                {
                  name: "WebDAV",
                  image: "File",
                  role: "Data Lake WebDAV",
                  connectionId: null,
                },
              ],
            },
            {
              title: "DMP",
              expand: false,
              connectors: [
                {
                  name: "Adform DMP OAuth2",
                  image: "Adform DMP OAuth2",
                  role: "DMP Adform DMP OAuth2",
                  connectionId: null,
                },
                {
                  name: "Adobe Experience PLatform",
                  image: "Adobe Experience PLatform",
                  role: "DMP Adobe Experience PLatform",
                  connectionId: null,
                },
                {
                  name: "Audience Studio",
                  image: "Audience Studio",
                  role: "DMP Audience Studio",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Generic",
              expand: false,
              connectors: [
                {
                  name: "Google Big Query (Oauth)",
                  image: "genericGoogle",
                  role: "Generic Google Big Query (Oauth)",
                  connectionId: null,
                },
                {
                  name: "Google BigQuery (Service)",
                  image: "genericGoogle",
                  role: "Generic Google BigQuery (Service)",
                  connectionId: null,
                },
                {
                  name: "Google Cloud Storage",
                  image: "Google Cloud Storage",
                  role: "Generic Google Cloud Storage",
                  connectionId: null,
                },
                {
                  name: "Microsoft SQL Server",
                  image: "Microsoft SQL Server",
                  role: "Generic Microsoft SQL Server",
                  connectionId: null,
                },
              ],
            },
          ],
        },

        {
          category: "Others",
          required: false,
          tabContent: [
            {
              title: "Brand Safety",
              expand: true,
              connectors: [
                {
                  name: "Moat",
                  image: "Moat",
                  role: "Brand Safety Moat",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Time Traker",
              expand: false,
              connectors: [
                {
                  name: "Clockify",
                  image: "Clockify",
                  role: "Time Traker Clockify",
                  connectionId: null,
                },
                {
                  name: "Harvest",
                  image: "Harvest",
                  role: "Time Traker Harvest",
                  connectionId: null,
                },
                {
                  name: "Yanomo",
                  image: "Yanomo",
                  role: "Time Traker Yanomo",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Audience Research",
              expand: false,
              connectors: [
                {
                  name: "Audience Report",
                  image: "Audience Report",
                  role: "Audience Research Audience Report",
                  connectionId: null,
                },
                {
                  name: "Cxense",
                  image: "Cxense",
                  role: "Audience Research Cxense",
                  connectionId: null,
                },
                {
                  name: "Nielsen Ad intel",
                  image: "nielsen",
                  role: "Audience Research Nielsen Ad intel",
                  connectionId: null,
                },
                {
                  name: "Nielsen DAR",
                  image: "nielsen",
                  role: "Audience Research Nielsen DAR",
                  connectionId: null,
                },
                {
                  name: "Reppublika",
                  image: "Reppublika",
                  role: "Audience Research Reppublika",
                  connectionId: null,
                },
                {
                  name: "YouGov Brandindex",
                  image: "YouGov Brandindex",
                  role: "Audience Research YouGov Brandindex",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Weather",
              expand: false,
              connectors: [
                {
                  name: "Open Weather Map",
                  image: "Open Weather Map",
                  role: "Weather Open Weather Map",
                  connectionId: null,
                },
                {
                  name: "Weather Stack",
                  image: "Weather Stack",
                  role: "Weather Weather Stack",
                  connectionId: null,
                },
              ],
            },
            {
              title: "Others",
              expand: false,
              connectors: [
                {
                  name: "Adbook+",
                  image: "Adbook+",
                  role: "Others Adbook+",
                  connectionId: null,
                },
                {
                  name: "Bright Talk",
                  image: "Bright Talk",
                  role: "Others Bright Talk",
                  connectionId: null,
                },
                {
                  name: "Bynder",
                  image: "Bynder",
                  role: "Others Bynder",
                  connectionId: null,
                },
                {
                  name: "Drift",
                  image: "Drift",
                  role: "Others Drift",
                  connectionId: null,
                },
                {
                  name: "Google Calender",
                  image: "Google Calender",
                  role: "Others Google Calender",
                  connectionId: null,
                },
                {
                  name: "GoTo Webinar",
                  image: "GoTo Webinar",
                  role: "Others GoTo Webinar",
                  connectionId: null,
                },
                {
                  name: "Innovid",
                  image: "Innovid",
                  role: "Others Innovid",
                  connectionId: null,
                },
                {
                  name: "Invoca",
                  image: "Invoca",
                  role: "Others Invoca",
                  connectionId: null,
                },
                {
                  name: "Kenshoo",
                  image: "Kenshoo",
                  role: "Others Kenshoo",
                  connectionId: null,
                },
                {
                  name: "Mailgun",
                  image: "Mailgun",
                  role: "Others Mailgun",
                  connectionId: null,
                },
                {
                  name: "Media Wizard",
                  image: "Media Wizard",
                  role: "Others Media Wizard",
                  connectionId: null,
                },
                {
                  name: "Norstat",
                  image: "Norstat",
                  role: "Others Norstat",
                  connectionId: null,
                },
                {
                  name: "Podigee",
                  image: "Podigee",
                  role: "Others Podigee",
                  connectionId: null,
                },
                {
                  name: "Spotwatch",
                  image: "Spotwatch",
                  role: "Others Spotwatch",
                  connectionId: null,
                },
                {
                  name: "Strpe",
                  image: "Strpe",
                  role: "Others Strpe",
                  connectionId: null,
                },
                {
                  name: "TXGB",
                  image: "TXGB",
                  role: "Others TXGB",
                  connectionId: null,
                },
                {
                  name: "Unbounce",
                  image: "Unbounce",
                  role: "Others Unbounce",
                  connectionId: null,
                },
                {
                  name: "Usabilla",
                  image: "Usabilla",
                  role: "Others Usabilla",
                  connectionId: null,
                },
                {
                  name: "Web Connect (Bearer)",
                  image: "Web Connect",
                  role: "Others Web Connect (Bearer)",
                  connectionId: null,
                },
                {
                  name: "Web Connect (Oauth)",
                  image: "Web Connect",
                  role: "Others Web Connect (Oauth)",
                  connectionId: null,
                },
                {
                  name: "Web Connect (Oauth2)",
                  image: "Web Connect",
                  role: "Others Web Connect (Oauth2)",
                  connectionId: null,
                },
                {
                  name: "Web Connect",
                  image: "Web Connect",
                  role: "Others Web Connect",
                  connectionId: null,
                },
                {
                  name: "Zendesk",
                  image: "Zendesk",
                  role: "Others Zendesk",
                  connectionId: null,
                },
              ],
            },
          ],
        },
      ],
      itemName: [],
      allConnectors: [],
      activeConnection: [],
      allBrandsList: [],
      authorize: { url: "", id: "" },
      currentConnection: {},
      totalConnectionLength: 0,
      allConnectionList: [],
    };
  },
  computed: {
    checkConnector() {
      var list = [];
      for (var i = 0; i < this.selectedConnectors.length; i++) {
        list.push(this.selectedConnectors[i].category);
      }
      if (
        list.includes("Search Trends*") &&
        list.includes("Digital Media*") &&
        list.includes("Analytics*")
      ) {
        return "";
      } else {
        var data = [];
        if (!list.includes("Search Trends*")) {
          data.push("Search Trends");
        }
        if (!list.includes("Digital Media*")) {
          data.push("Digital Media");
        }
        if (!list.includes("Analytics*")) {
          data.push("Analytics");
        }
        if (data.length > 1) {
          var message = "";
          for (i = 0; i < data.length; i++) {
            if (i === data.length - 2) {
              message += data[i] + " and ";
            } else if (i === data.length - 1) {
              message += data[i] + " ";
            } else {
              message += data[i] + ",";
            }
          }
          return message + " connection are missing";
        } else {
          if (data[0] !== undefined) {
            return data[0] + " connection is missing";
          } else {
            return "connection is missing";
          }
        }
      }
    },
    isDisable() {
      if (
        this.selectedMarket !== "Select" &&
        this.selectedKPI !== "Select" &&
        this.selectedMarket !== "" &&
        this.selectedKPI !== ""
      ) {
        return false;
      }
      return true;
    },
    disableConnectors() {
      if (this.activeFrom === 1) {
        if (this.selectedConnectors.length > 0) {
          return false;
        }
      } else if (this.activeFrom === 2) {
        return false;
      }
      return true;
    },
  },
  methods: {
    createNewConnction(e) {
      this.$refs.AllOverViewModal.hideOverviewPage();
      this.$refs.connectionModal.open(
        e,
        this.allBrandsList,
        this.allConnectionList
      );
    },
    totalConnection(e) {
      this.totalConnectionLength = e;
    },
    Nexthandler(data) {
      if (this.tabs.length === data + 1) {
        this.changeTab("Additional Details");
        return;
      }
      this.changeTab(this.tabs[data + 1].category);
    },
    fetchGoogleTrendsData(detail, id) {
      var data;
      if (id !== "") {
        data = {
          dataStreamId: detail,
          duration: 0,
          clientId: sessionStorage.getItem("clientId"),
          // fetchType: this.selectedPattern,
          historicStartDate: "",
          historicEndDate: "",
          start: moment(new Date()).format("YYYY-MM-DD"),
          end: moment(new Date()).format("YYYY-MM-DD"),
          file_id: id,
        };
      } else {
        data = {
          dataStreamId: detail,
          duration: 0,
          clientId: sessionStorage.getItem("clientId"),
          // fetchType: this.selectedPattern,
          historicStartDate: "",
          historicEndDate: "",
          start: moment(new Date()).format("YYYY-MM-DD"),
          end: moment(new Date()).format("YYYY-MM-DD"),
        };
      }
      dataConnection.fetchData(data).then((res) => {
        console.log(res);
        this.processDone();
      });
    },
    fetchThreeYearData(detail) {
      var data = {
        dataStreamId: detail,
        duration: 0,
        clientId: sessionStorage.getItem("clientId"),
        // fetchType: this.selectedPattern,
        historicStartDate: moment(
          new Date(new Date().setFullYear(new Date().getFullYear() - 3))
        ).format("YYYY-MM-DD"),
        historicEndDate: moment(new Date()).format("YYYY-MM-DD"),
        start: "",
        end: "",
      };
      dataConnection.fetchData(data).then((res) => {
        console.log(res);
        this.processDone();
      });
    },
    createStreamDetails(data) {
      dataConnection.createDataStream(data.details, data.typeId).then((res) => {
        this.$refs.dataStreamModal.hideWelcomePage();
        if (res.status === "FAILED") {
          this.toastData = {
            show: true,
            type: "failed",
            message: "Something went wrong! Please try after sometime.",
          };
          return;
        }
        var id = data.details.createdBy.split("@")[1];
        if (data.details.channelName === "Google Trends") {
          this.fetchGoogleTrendsData(res, "");
          return;
        } else if (
          data.details.channelName !== "Google Trends" &&
          id !== "datapoem.com" &&
          !Object.keys(data.details).includes("file_id")
        ) {
          this.fetchThreeYearData(res);
          return;
        }
        if (Object.keys(data.details).includes("file_id")) {
          this.fetchGoogleTrendsData(res, data.details.file_id);
          return;
          // this.$refs.dataFetchModalRef.open({
          //   title: "Initial Data Fetch",
          //   id: res,
          //   file_id: data.details.file_id,
          // });
        } else {
          this.$refs.dataFetchModalRef.open({
            title: "Initial Data Fetch",
            id: res,
          });
        }
      });
    },
    createDataStream(e) {
      this.$refs.dataStreamModal.open({
        title: "Add data stream",
        details: e,
      });
    },
    processDone() {
      this.toastData = {
        show: true,
        type: "success",
        message: "Yay, your datastream has been created successfully",
      };
      this.getConnectedChannelList();
      this.getConnectionWithoutDataStream();
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    createConnection(data) {
      this.currentConnection = data;
      dataConnection.createConnection(data).then((res) => {
        this.$refs.connectionModal.hideWelcomePage();
        if (data.channelName === "Google Trends") {
          this.createDataStream({
            ConnectionName: this.currentConnection.name,
            connectionId: res.response[1],
            channel: this.currentConnection.channelName,
          });
          return;
        }
        this.authorize.url = res.response[0];
        this.authorize.id = res.response[1];
        this.$refs.authorizedModal.open();
      });
    },
    nextToSource() {
      if (this.activeFrom === 2) {
        this.activeFrom = 3;
        return;
      }

      if (this.checkConnector !== "") {
        this.$refs.confirmationmodal.open({ title: this.checkConnector });
      } else {
        this.activeFrom = 2;
        this.getConnectionWithoutDataStream();
      }
    },
    viewAll(connectors, title) {
      this.selectedConnectorsForViewModal = [];
      this.selectedTitleForViewModal = "";
      this.selectedConnectorsForViewModal = connectors;
      this.selectedTitleForViewModal = title;
      this.$refs.overviewModal.open();
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    addPlatform(obj) {
      this.$refs.connectionModal.open(
        obj.obj,
        this.allBrandsList,
        this.allConnectionList
      );
    },
    hideOverviewPage() {
      this.$modal.hide("overviewPage");
    },
    getAllBrands() {
      this.allBrandsList = [];
      dataConnection
        .getAllBrands(
          sessionStorage.getItem("userId"),
          sessionStorage.getItem("subId")
        )
        .then((res) => {
          for (var i = 0; i < res.response.length; i++) {
            this.allBrandsList.push({
              text: res.response[i].brandName,
              id: res.response[i].id,
            });
          }
          this.count++;
        })
        .catch(() => {
          this.errorMessage();
          this.count++;
        });
    },
    overViewCards() {
      this.$modal.hide("overviewPage");
    },
    authoriseConnection() {
      var url = "";
      if (
        this.currentConnection.channelName.toLowerCase() ===
          "Google Ads".toLowerCase() ||
        this.currentConnection.channelName.toLowerCase() ===
          "Google_Ads".toLowerCase() ||
        this.currentConnection.channelName.toLowerCase() ===
          "Google Analytics".toLowerCase() ||
        this.currentConnection.channelName.toLowerCase() ===
          "Google_Analytics".toLowerCase() ||
        this.currentConnection.channelName.toLowerCase() ===
          "Google Sheets".toLowerCase() ||
        this.currentConnection.channelName.toLowerCase() ===
          "Google_Sheets".toLowerCase() ||
        this.currentConnection.channelName.toLowerCase() ===
          "Microsoft Ads".toLowerCase() ||
        this.currentConnection.channelName.toLowerCase() ===
          "Facebook Ads".toLowerCase() ||
          this.currentConnection.channelName.toLowerCase() ===
          "Display and Video 360".toLowerCase() ||
          this.currentConnection.channelName.toLowerCase() ===
          "DV 360".toLowerCase()
      ) {
        url = JSON.parse(this.authorize.url);
      } else {
        url = this.authorize.url;
      }
      var winobj = window.open(
        url,
        "Snopzer",
        "left=500,top=150,width=500,height=500,toolbar=1,resizable=0"
      );
      var self = this;
      var loop = setInterval(
        function() {
          if (winobj.closed) {
            const uri = `health-monitor-module/connection_isauthorized?connectionId=${
              self.authorize.id
            }&connectionType=${
              self.currentConnection.connectionId
            }&orgNumber=${sessionStorage.getItem("subId")}&channelName=${
              self.currentConnection.channelName
            }`;
            apiHelper.get(uri).then((res) => {
              console.log(res);
              if (res.response && res.status === "SUCCESS") {
                self.$refs.authorizedModal.hideAuthorizedPage();
                self.createDataStream({
                  ConnectionName: self.currentConnection.name,
                  connectionId: self.authorize.id,
                  channel: self.currentConnection.channelName,
                  showMessage: true,
                });
              } else {
                self.$refs.authorizedModal.hideAuthorizedPage();
              }
            });
            self.getConnectedChannelList();
            clearInterval(loop);
          }
        }.bind(this),
        100
      );
    },
    getConnectedChannelList() {
      this.activeConnection = [];
      dataConnection
        .getConnectedChannels(sessionStorage.getItem("subId"))
        .then((res) => {
          // for (var i = 0; i < res.response.length; i++) {}
          this.selectedConnectors = [];
          for (var i = 0; i < res.response.length; i++) {
            if (res.response[i] === "Google_Sheets") {
              var data = this.allConnectors.find(
                (obj) => obj.connectorName === "Google Sheets"
              );
            } else if (res.response[i] === "Google_Analytics") {
              data = this.allConnectors.find(
                (obj) => obj.connectorName === "Google Analytics"
              );
            } else if (res.response[i] === "DV 360") {
              data = this.allConnectors.find(
                (obj) => obj.connectorName === "Display and Video 360"
              );
            } else {
              data = this.allConnectors.find(
                (obj) => obj.connectorName === res.response[i]
              );
            }

            if (data !== undefined) {
              this.activeConnection.push(data);
            }
            if (i === res.response.length - 1) {
              this.selectedConnectors = this.activeConnection;
            }
          }
          this.count++;
        })
        .catch(() => {
          this.count++;
          this.errorMessage();
        });
    },
    errorMessage() {
      this.toastData.show = true;
      this.toastData.type = "failed";
      this.toastData.message = "Something went wrong Please try again";
    },
    getConnectionWithoutDataStream() {
      dataConnection
        .getConnectionWithoutDataStream(sessionStorage.getItem("subId"), 1, 100)
        .then((res) => {
          this.totalConnectionLength = res.totalElements;
        });
    },
    getConnectionName() {
      dataConnection
        .getAllConnectionList()
        .then((res) => {
          for (var i = 0; i < res.response.length; i++) {
            this.allConnectionList.push(res.response[i].name);
          }
          this.count++;
        })
        .catch(() => {
          this.errorMessage();
          this.count++;
        });
    },
    // proceedToNext() {},
  },
  created() {
    this.allConnectorList = this.allConnectorList.filter(
      (v, i, a) => a.findIndex((t) => t.name === v.name) === i
    );
    this.getConnectionWithoutDataStream();
    this.getAllBrands();
    this.getConnectionName();
    for (var i = 0; i < this.tabs.length; i++) {
      for (var j = 0; j < this.tabs[i].tabContent.length; j++) {
        for (var k = 0; k < this.tabs[i].tabContent[j].connectors.length; k++) {
          var obj = this.tabs[i].tabContent[j].connectors[k];
          var obj1 = {
            category: this.tabs[i].category,
            className: obj.image,
            connectorName: obj.name,
            role: obj.role,
            connectionId: obj.connectionId,
            title: this.tabs[i].tabContent[j].title,
          };
          this.allConnectors.push(obj1);
        }
      }
    }
    this.getConnectedChannelList();
  },
};
</script>
<style scoped>
.authorized-cancel-btn {
  font-size: 16px;
  font-weight: normal;
  text-decoration: underline;
}
.cancel-btn {
  font-weight: 600;
  font-stretch: normal;
  font-size: 18px;
  cursor: pointer;
}
.changeColor .disableButton {
  color: #fff !important;
}
.image {
  background-color: #fff;
  height: 48px;
  width: 48px;
  align-items: center;
}
.action {
  color: #000;
}
.container {
  height: 100%;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}
.step-indicator .stepactive::after {
  background: #050505;
}
.tab-panel.showConnectors {
  display: block;
}
.tab-panel {
  display: none;
}
.card {
  display: block;
}
.Datastream-title {
  font-family: ProximaNovaBold;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #313846;
  position: relative;
  padding-left: 20px;
  top: 20px;
}
/deep/ .modal-connectors .connector {
  height: 80px;
  width: 243px;
  margin: 11px 11px 11px 0;
}
span.float-right.view-all-link {
  font-size: 16px !important;
  font-weight: normal !important;
  font-family: "ProximaNovaRegular";
  cursor: pointer;
}
@media screen and (max-width: 640px) {
  >>> p.Welcome-to-Data-Poem {
    text-align: center;
  }
  >>> p.Together-let-us-hel {
    margin-left: 35px;
  }
  >>> span.start-from-fresh {
    text-align: center;
  }
  >>> button.btn-effect-intro {
    margin: 0 20%;
  }
  >>> p.Together-let-us-hel {
    text-align: right;
  }
  p.Welcome-to-Data-Poem {
    text-align: center;
  }
  .kpi-box {
    height: 800px;
  }
  .search-box {
    width: 100%;
    height: 60px;
    font-size: 16px;
    padding-left: 15px;
    margin-left: 35px;
  }
  .modal-body {
    overflow-y: scroll;
    width: 100%;
    height: 550px;
    padding: 0 5rem;
  }
  .w-100.modal-scroll {
    height: 700px;
    overflow-y: scroll;
  }
}
@media screen and (max-width: 750) {
  >>> .search-box {
    width: 337px !important;
    height: 60px;
    font-size: 16px;
    padding-left: 15px;
    margin-left: 35px;
  }
  .modal-body {
    overflow-y: scroll;
    width: 100%;
    height: 550px;
    padding: 0 5rem;
  }
  .w-100.modal-scroll {
    height: 700px;
    overflow-y: scroll;
  }
}
</style>
