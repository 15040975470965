<template>
  <div class="row w-100 p-0 m-0">
    <h6 v-for="(email, index) in emails" :key="index" class="w-auto">
      <slot
        >{{ email }}
        <span @click="emailQuote(index)"> X</span>
      </slot>
    </h6>
  </div>
</template>
<script>
export default {
  props: {
    emails: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    emailQuote(index) {
      this.$emit("emailDeleted", index);
    },
  },
};
</script>
<style scoped>
h6 {
  cursor: pointer;
  padding: 7px 5px;
  background-color: #deffe6;
  border: 1px solid #81f89e;
  width: auto !important;
  margin: 5px;
  font-size: medium;
  font-family: ProximaNovaSemiBold;
}
h6:hover {
  font-family: ProximaNovaBold;
}
span:hover {
  color: red;
  font-family: ProximaNovaBold;
}
span {
  font-weight: bold;
  margin-left: 5px;
}
</style>
