<template>
  <div class="Together-let-us-hel ProximaNovaRegular mt-0" style="height: auto">
    <b-form-input
      type="email"
      class="form-control input-control c-form-input m-0 mb-5 w-100"
      aria-describedby="emailHelp"
      placeholder="Data stream name"
      name="example-input-1"
      v-model="dataStreamName"
    ></b-form-input>
    <w-dropdown
      :options="channels"
      :labelText="'Channels'"
      class="mb-5 w-100"
      :selectedOption="selectedChannel"
    ></w-dropdown>
    <w-dropdown
      :options="connectionDropdown"
      :labelText="'Connection'"
      class="mb-5 w-100"
      :selectedOption="selectedConnection"
    ></w-dropdown>
    <w-tree-dropdown
      :placeHolder="'Select Accounts'"
      :rootOptions="accountList"
      :selectedValue="selectedAccounts"
      class="mb-5 w-100"
      @selectedField="selectAccounts($event)"
    ></w-tree-dropdown>
    <b-form-input
      type="text"
      class="form-control input-control c-form-input m-0 w-100"
      aria-describedby="emailHelp"
      :class="{ 'mb-5': countryList.length === 0 }"
      placeholder="Search Country"
      name="example-input-1"
      v-model="countryName"
      @keyup="getCountry"
    ></b-form-input>
    <div
      id="myInputautocomplete-list"
      class="autocomplete-items"
      v-if="countryList.length !== 0"
    >
      <div
        v-for="(data, index) in countryList"
        :key="index"
        @click="selectCountry(data)"
      >
        {{ data.name }}<input type="hidden" :value="data.value" />
      </div>
    </div>
    <b-form-input
      type="email"
      :class="{ 'mt-5': countryList.length !== 0 }"
      class="form-control input-control c-form-input m-0 mt-5 w-100"
      aria-describedby="emailHelp"
      placeholder="Keywords"
      name="example-input-1"
      v-model="keywords"
    ></b-form-input>
    <div class="help-text mb-5">
      Please enter comma-separated keywords.
    </div>
    <div class="my-5 w-100 contPent-center">
      <div>
        <w-button
          class="cancel mr-4 px-5"
          :buttonText="'Cancel'"
          :isDisable="false"
          :buttonLoader="'none'"
          style="font-size: medium;"
          @buttonClicked="hideWelcomePage"
        >
        </w-button>
      </div>
      <div>
        <w-button
          :buttonLoader="buttonLoader"
          :buttonText="'Add Fetching Details'"
          :isDisable="disableConnectors"
          style="font-size: medium;"
          :class="{ resetPasswordLink: disableConnectors }"
          @buttonClicked="createDataStream"
        >
        </w-button>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "@/widgets/Dropdown.vue";
import Treeselect from "@/widgets/MultiSelectDropdown.vue";
import { DataConnection } from "../../services/DataConnectionService.js";
import Button from "@/components/Profile/Button.vue";
const dataConnection = new DataConnection();
var typingTimer;
var doneTypingInterval = 2000;
export default {
  props: {
    channelData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataStreamName: "",
      keywords: "",
      buttonLoader: "normal",
      countryName: "",
      connectionDropdown: [],
      selectedConnection: {},
      selectedChannel: {},
      channels: [],
      accountList: [],
      selectedAccounts: [],
      selectedCountry: "",
      countryList: [],
    };
  },
  created() {
    this.dataStreamName = "";
    this.buttonLoader = "normal";
    this.connectionDropdown = [
      {
        text: this.channelData.ConnectionName,
        id: this.channelData.connectionId,
      },
    ];
    this.selectedConnection = this.connectionDropdown[0];
    this.channels = [
      { text: this.channelData.channel, id: this.channelData.channel },
    ];
    this.selectedChannel = this.channels[0];
    this.getAccounts();
  },
  computed: {
    disableConnectors() {
      if (
        this.dataStreamName !== "" &&
        this.selectedAccounts.length !== 0 &&
        this.buttonLoader === "normal" &&
        this.keywords.length !== 0 &&
        this.countryName.length !== 0
      ) {
        return false;
      }
      return true;
    },
  },
  components: {
    "w-dropdown": Dropdown,
    "w-button": Button,
    "w-tree-dropdown": Treeselect,
  },
  methods: {
    selectCountry(obj) {
      this.selectedCountry = obj;
      this.countryName = obj.name;
      this.countryList = [];
    },
    getCountry() {
      clearTimeout(typingTimer);
      var self = this;
      typingTimer = setTimeout(function() {
        if (self.countryName.length >= 3) {
          self.doneTyping();
        }
      }, doneTypingInterval);
    },
    doneTyping() {
      dataConnection
        .getCountryList(this.countryName, this.selectedConnection.id, 743)
        .then((res) => {
          this.countryList = [];
          for (var i = 0; i < res.results.length; i++) {
            this.countryList.push({
              name: res.results[i].text,
              value: res.results[i].id,
            });
          }
        });
    },
    createDataStream() {
      this.buttonLoader = "loader";
      var data = {
        name: this.dataStreamName,
        auth: this.selectedConnection.id,
        orgNumber: sessionStorage.getItem("subId"),
        createdBy: sessionStorage.getItem("clientId"),
        client_customer_ids: this.selectedAccounts,
        geo_targets: [this.selectedCountry.value],
        keyword_type_in: this.keywords,
      };
      this.$emit("createdataStream", { details: data, typeId: 743 });
    },
    hideWelcomePage() {
      this.$emit("hideWelcomePage");
    },
    selectAccounts(e) {
      this.selectedAccounts = e;
    },
    getAccounts() {
      dataConnection.getMccs(743, this.selectedConnection.id).then((res) => {
        for (var i = 0; i < res.results.length; i++) {
          if (
            this.accountList.find((obj) => obj.id === res.results[i].id) ===
            undefined
          ) {
            this.accountList.push({
              id: res.results[i].id,
              label: res.results[i].text,
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #d4d4d4 !important;
  border: 0px;
  background: #fff;
  color: #313846;
  font-weight: normal;
  border: 1px solid #313846;
}
.autocomplete-items div:hover {
  background: #eff5ff;
}
.autocomplete-items {
  height: 280px;
  overflow: auto;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 1px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}
::placeholder {
  color: #9aafd4;
  opacity: 1;
}
.cancel.button.update {
  background-color: transparent;
  color: #050505;
}
.cancel.button.update:hover {
  color: #fff !important;
}
:-ms-input-placeholder {
  color: #9aafd4;
}
::-ms-input-placeholder {
  color: #9aafd4;
}
input:focus {
  box-shadow: none;
  border: 1px solid #80bdff;
}
input {
  width: 378px;
  height: 60px;
  margin: 53px 1px 40px 30px;
  object-fit: contain;
  color: #9aafd4;
  background-color: #f2f5fa;
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  box-shadow: none;
  border: 0;
}
</style>
